namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        notifyProperties: any;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            notifyProperties: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/components/notification.html',
        transclude: true
    };

    function controllerCtrl() {
        this.$onChanges = function (changes) {

            if (changes.notifyProperties) {
                let properties = changes.notifyProperties.currentValue;

                if (properties !== undefined) {


                    if (!this.notifies) {
                        this.notifies = [properties];
                    } else {
                        this.notifies.push(properties);
                    }

                    let length = this.notifies.length - 1;

                    let lastNotify = this.notifies[length];

                    lastNotify.timeout(() => {
                        lastNotify.showClass = "show";
                        this.notifies.forEach((notify) => {
                            if (!notify.isHide) {
                                notify.isHide = true;
                                notify.timeout(() => {
                                    delete notify.showClass;
                                    notify.timeout(() => {
                                        this.notifies.splice(0, 1);
                                    }, 1000);
                                }, 2000);
                            }
                        });
                    }, 0);

                }
            }
        };
    }

    angular
        .module('Yelon')
        .component('notification', component);
}
