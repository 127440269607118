/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('NewPasswordController', ['$state', 'PasswordFactory', NewPasswordController]);

    function NewPasswordController($state: angular.ui.IStateService,  PasswordFactory) {

        let vm: any = this;

        vm.newPassword = newPassword;

        function newPassword() {

            var payload = {
                'old_password': vm.current_password,
                'password': vm.new_password,
                'password_confirmation': vm.re_new_password
            };
            console.log(payload);
            PasswordFactory.newPassword(payload);

        }
    }
})();
