namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        binding4: string;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            selectedTags: "=",
            getTags: "<",
            existTags: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/tags.html',
        transclude: true
    };

    function controllerCtrl() {
        let self = this;

        this.$onInit = function () {
            this.getTags((results) => {
                this.tags = results;
            });
        };

        this.$onChanges = function (changes) {
            let existTags = changes.existTags;

            if (existTags !== undefined && !existTags.isFirstChange()) {
                let values = existTags.currentValue;

                window.setTimeout(() => {
                    setExistTags(values);
                }, 10);
            }
        };

        function setExistTags(values) {
            self.selectedTags = [];

                values.forEach((value) => {
                    self.tags.forEach((tag, index) => {
                        if (tag.id === value.id) {
                            self.selectedTags.push(tag);
                            self.tags.splice(index, 1);
                        }
                    });
                });
        };

        this.onSelected = function () {
            if (!this.selectedTags) {
                this.selectedTags = [this.currentTag];
            } else {
                this.selectedTags.push(this.currentTag);
            }

            this.tags.forEach((tag, index) => {
                if (tag === this.currentTag) {
                    this.tags.splice(index, 1);

                    this.currentTag = null;
                }
            });
        };

        this.addBtnClick = function () {
            this.onSelected();
        };

        this.onClickRemoveBtn = function (index, tag) {
            this.selectedTags.splice(index, 1);

            this.tags.push(tag);
        };

    }

    angular
        .module('Yelon')
        .component('tagsEditorInput', component);
}
