/**
 * Created by danielszenasi on 09/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('FAQCategoriesController', ['ApiFactory', 'SidebarService', 'spinnerService', FAQCategoriesController]);

    function FAQCategoriesController(ApiFactory, SidebarService, spinnerService) {
        let vm:any = this;
        vm.categories = [];

        SidebarService.loadPreset('faq');
        SidebarService.enable();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            angular.forEach(data, function(value, key){
                let item = {id:value.id ,text: value.title};
                //console.log(item);
                vm.categories.push(item);
            });
        }).finally(function() {
            spinnerService.decrementLoading();
        });

        vm.categoryAdded = function($tag) {
            ApiFactory.postFunction('faqcategories', {'title': $tag.text}).then(function (data) {
                $tag.id = data.id;
            });
        };

        vm.categoryRemoved = function($tag) {
            ApiFactory.deleteFunction('faqcategories/' + $tag.id).then(function () {});
        };
    }
})();
