declare var Notification:any;

(function () {
    'use strict';

    angular.module('Yelon').controller('ChatController', ['ChatFactory', '$scope', '$confirm', '$uibModal', '$interval', '$rootScope', '$sce', ChatController]);

    function ChatController(ChatFactory, $scope, $confirm, $uibModal, $interval, $rootScope, $sce) {
        let vm:any = this;

        vm.user = JSON.parse(localStorage.getItem('user'));
        vm.messages = [];
        vm.selectedTopics = [];
        vm.rating = {};
        vm.sessions = {};
        vm.activeTab = 0;
        vm.hasActiveGuestSession = false;
        vm.isConnected = false;
        vm.count = {
            active: 0,
            auto: [0, 0, 0],
            pending: 0,
            total: 0
        };
        vm.clients = [];
        let ratingModals = {};
        let prevInput = "";
        let element;

        $scope.$on("$destroy", destroy);

        function notify(msg) {
            let beep: HTMLAudioElement = document.getElementById('beep') as any;
            beep.pause();
            beep.currentTime = 0;
            beep.play();

            if ("Notification" in window && Notification.permission === "granted") {
                new Notification(msg);
            }
        }

        ChatFactory.getClientId().then(id => {
            destroy();
            vm.isConnected = true;
            vm.clientId = id;
            vm.sessions = ChatFactory.getSessions();
            for (let i in vm.sessions) {
                if (vm.sessions.hasOwnProperty(i)) {
                    setUpSession(vm.sessions[i]);
                }
            }
            for (let i in vm.sessions) {
                if (vm.sessions.hasOwnProperty(i) && vm.sessions[i].Type === "channel") {
                    vm.hasActiveGuestSession = true;
                }
            }

            let conversations = ChatFactory.getConversations();
            let clients = ChatFactory.getClients();
            vm.clients = clients.clients;
            vm.count = conversations.count;

            vm.startNewConversation = startNewConversation;
            vm.openConversationWith = openConversationWith;
            vm.joinConversation = joinConversation;

            function startNewConversation() {
                ChatFactory.assignConversation();
            }

            function joinConversation(sessionId: string) {
                ChatFactory.joinConversation(sessionId);
            }

            function openConversationWith(user: any) {
                ChatFactory.openConversationWith(user);
            }

            ChatFactory.on("connect", () => {
                vm.isConnected = true;
            });

            ChatFactory.on("disconnect", (data: any) => {
                vm.sessions = {};
                vm.messages = [];
                vm.isConnected = false;
                vm.count = {
                    active: 0,
                    auto: [0, 0, 0],
                    pending: 0,
                    total: 0
                };
                vm.clients = [];
            });
            ChatFactory.on("clients", (data: any) => {
                vm.clients = data.clients;
            });
            ChatFactory.on("conversations", (data: any) => {
                if (vm.user.role === 'helper' && typeof vm.count !== 'undefined') {
                    if (vm.count.pending === 0 && data.count.pending > 0 && !vm.hasActiveGuestSession) {
                        notify("Új várakozó a chaten");
                    }
                }

                vm.count = data.count;
            });
            ChatFactory.on("announcement", (message: string) => {
                for (let i in vm.sessions) {
                    if (vm.sessions.hasOwnProperty(i)) {
                        vm.sessions[i].announce(message);
                    }
                }
            });

            ChatFactory.on("start", (session: ChatSession) => {
                session["onKeyDown"] = onKeyDown;
                session["keyPressed"] = keyPressed;
                session["textPasted"] = textPasted;
                session["isEnableInput"] = isEnableInput;
                session["onAddEmojiIcon"] = onAddEmojiIcon;

                setUpSession(session);
                vm.sessions[session.Id] = session;
                //setTimeout(() => $anchorScroll(session.Id + "_lastMsg"), 100);
            });
            $interval(() => {
                for (let i in vm.sessions) {
                    if (vm.sessions.hasOwnProperty(i)) {
                        vm.sessions[i].refreshChatTime();
                    }
                }
            }, 1000);
        });

        function setUpSession(session:ChatSession) {
            if (session.Type === "channel") {
                vm.hasActiveGuestSession = true;
            }
            // set up event handlers
            session.on("message", (msg) => {
                /*if (document.hidden && msg.client.id !== vm.clientId) {
                    if (session.Type === "direct" && msg.client.role !== "guest") {
                        notify("Üzenet érkezett tőle: " + msg.client.name);
                    } else if (vm.user.role === 'helper' && session.Type === "channel" && msg.client.role === "guest") {
                        notify("Üzenet érkezett");
                    }
                }*/
            });
            session.on("end", () => {
                if (session.Type === 'channel' && session.Helper.id === vm.clientId) {
                    ratingModals[session.Id] = $uibModal.open({
                        windowClass: 'chat-rating',
                        templateUrl: 'app/layouts/chat-rating-modal.html',
                        controller: 'RatingModalController as rating',
                        backdrop: 'static',
                        keyboard: false
                    });
                    ratingModals[session.Id].result.then((data) => {
                        ChatFactory.rateConversation(session.Id, data);
                    });
                }
                if (session.Type === "channel") {
                    vm.hasActiveGuestSession = false;
                }
            });
            session.on("userTyping", (data) => {
                ChatFactory.setIsTyping(session.Id, data);
            });
            session.on("sendingMessage", (data) => {
                ChatFactory.sendMessage(session.Id, data);
                session["messageText"] = "";
                if (session["textareaInput"]) {
                    session["textareaInput"].innerHTML = "";
                }
                if (session["messageInput"]) {
                    session["messageInput"] = "";
                }
            });
            session.on("closing", () => {
                $confirm({
                    title: 'Biztos, hogy lezárod a beszélgetést?',
                    text: 'Figyelem! A lezárt beszélgetés tovább már nem folytatható.',
                    cancel: 'Vissza',
                    ok: 'Lezárás'
                }).then(function () {
                    ChatFactory.closeConversation(session.Id);
                });
            });
            session.on("takeover", () => ChatFactory.takeOverConversation(session.Id));
            session.on("giveback", () => ChatFactory.giveBackConversation(session.Id));
            session.on("archive", () => {
                if (ratingModals.hasOwnProperty(session.Id)) {
                    ratingModals[session.Id].dismiss();
                }
                delete vm.sessions[session.Id];
                if (!vm.sessions.hasOwnProperty(vm.activeTab)) {
                    vm.activeTab = Object.keys(vm.sessions).length !== 0 ? Object.keys(vm.sessions)[Object.keys(vm.sessions).length - 1] : 0;
                }
            });

        }

        function destroy() {
            console.log("destroy");
            vm.sessions = {};
            let events = [
                "disconnect",
                "clients",
                "conversations",
                "announcement",
                "start"
            ];

            for (let event of events) {
                console.log("removing listener " + event);
                ChatFactory.off(event);
            }
        }

        function onKeyDown($event) {
            if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter" || $event.keyIdentifier === "Enter") && $event.ctrlKey === false && $event.shiftKey === false) {
                $event.preventDefault();

                this.isEnterKey = true;
            }
        }

        function keyPressed($event, session) {
            session.textareaInput = $event.target;

            if (this.isEnterKey) {
                $event.preventDefault();
                ChatFactory.sendMessage(session.id, session.messageInput);
                session.messageText = "";
                session.messageInput = "";
                $event.target.innerHTML = "";
                delete this.isEnterKey;
            } else {
                var replaceText = $event.target.innerHTML.replace(/(<img src=".[\/a-z0-9\."\s=]+)(:[a-z_]+:)(">)/g, '$2');

                replaceText = replaceText.replace(/<br>/g, "\n");
                replaceText = replaceText.replace(/&nbsp;/g, "");
                replaceText = replaceText.replace(/&lt;/g, "<");
                replaceText = replaceText.replace(/&gt;/g, ">");

                session.messageInput = replaceText;
            }

            if (session.prevMsg.length === 0 && session.messageInput.length > 0) {
                session.trigger("userTyping", true);
            } else if (session.prevMsg.length > 0 && session.messageInput.length === 0) {
                session.trigger("userTyping", false);
            }
            session.prevMsg = session.messageInput;
        }

        function textPasted($event) {
            $event.preventDefault();

            document.execCommand("insertHTML", false, $event.clipboardData.getData('text/plain'));
        }

        function onAddEmojiIcon() {
            let text = arguments[0];
            let session = arguments[1];

            let searchName = text.replace(/[:]/g, "");
            let selectedEmojiObj = $rootScope.emojis[searchName];
            let imgHtml = $sce.trustAsHtml(`<img src="./assets/img/emojis/${selectedEmojiObj.unicode}.svg" alt="${text}">`);

            session.messageText = session.messageInput;

            if (session.messageText !== "") {
                session.messageText = session.messageText.replace(/([:][a-z_]+[:])/g, function (...texts) {
                    searchName = texts[0].replace(/[:]/g, "");
                    selectedEmojiObj = $rootScope.emojis[searchName];
                    texts[1] = $sce.trustAsHtml(`<img src="./assets/img/emojis/${selectedEmojiObj.unicode}.svg" alt="${texts[0]}">`);
                    return texts[1];
                });

                session.messageText = session.messageText.replace(/<br>/g, "\n");
            }

            session.messageInput += text + ' ';
            session.messageText += imgHtml;

            session.isShowEmojiPicker = false;

        }

        function isEnableInput() {
            return (!vm.isConnected) ? false : true;
        }
    }
})();

