/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('PagesController', ['ApiFactory', 'SidebarService', PagesController]);

    function PagesController(ApiFactory, SidebarService) {
        let vm:any = this;
        SidebarService.loadPreset('pages');
        SidebarService.enable();
    }
})();
