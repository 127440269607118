namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        typeText: string;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            typeText: '@',
            archiveValue: "=",
            duplicateArticle: "<",
            mainType: "@",
            notify: "=",
            articleType: "@",
            fixTile: "="
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/typeInput.html',
        transclude: true
    };

    function controllerCtrl() {
        this.isDuplicated = false;

        this.fixTiles = [{
            title: "Sima 'Rólunk' oldal",
            value: "0-rolunk"
        }, {
            title: "Sima 'Chat' oldal",
            value: "0-segitoink"
        }, {
            title: "Sima 'Mobilalkalmazás' oldal",
            value: "0-applikacio"
        }, {
            title: "Felnőtt 'Rólunk' oldal",
            value: "1-rolunk"
        }, {
            title: "Felnőtt 'Yelonról' oldal",
            value: "1-segitoink"
        }, {
            title: "Felnőtt 'Mobilalkalmazás' oldal",
            value: "1-applikacio"
        }];

        this.changeArchiveStatus = function() {
            this.archiveValue = (this.archiveValue) ? false : true;
        };

        this.onClickDuplicate = function() {
            this.duplicateArticle(() => {
                this.isDuplicated = true;
            });
        };
    }

    angular
        .module('Yelon')
        .component('typeInput', component);
}

