(function () {
    'use strict';

    angular.module('Yelon').directive("elastic", ['$timeout', elastic]);

    function elastic($timeout) {
        return {
            restrict: "A",
            link: function($scope,element,attrs){
                $scope.initialHeight = $scope.initialHeight || element[0].style.height;
                var resize = function() {
                    element[0].style.height = $scope.initialHeight;
                    element[0].style.height = "" + (element[0].scrollHeight + 3) + "px";
                };
                element.on("input change", resize);
                $timeout(resize, 0);
            }
        };
    }
})();
