/**
 * Created by danielszenasi on 17/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').directive("timepickerpopup", timepickerPopup);

    function timepickerPopup() {
        return {
            scope: {
                timeData: '=timepickerpopup',
                timeUpdateCallback: '&'
            },
            templateUrl: 'app/layouts/timepicker-popup.html',
            controller: ['$scope', function ($scope) {

                $scope.timepickerOptions = {
                    readonlyInput: true,
                    showMeridian: false
                };

                $scope.buttonBar = {
                    show: false
                };

                $scope.checkClosed = () => {
                    $scope.closed = ($scope.timeData.from === "00:00" && $scope.timeData.to === "00:00");
                };
                $scope.checkClosed();

                // Perfectly chosen nails: I literally had no other choice to get it right with the new settings table, without rewriting the whole module (although it should be rewritten...)
                $scope.updateFrom = function () {
                    $scope.checkClosed();

                    if ($scope.from.date !== null) {
                        $scope.timeData.from = ("0" + $scope.from.date.getHours() ).slice(-2) + ':' + ("0" + $scope.from.date.getMinutes()).slice(-2);
                    } else {
                        $scope.timeData.from = "00:00";
                    }
                    $scope.timeUpdateCallback();
                };
                $scope.updateTo = function () {
                    $scope.checkClosed();

                    if ($scope.to.date !== null) {
                        $scope.timeData.to = ("0" + $scope.to.date.getHours() ).slice(-2) + ':' + ("0" + $scope.to.date.getMinutes()).slice(-2);
                    } else {
                        $scope.timeData.to = "00:00";
                    }
                    $scope.timeUpdateCallback();
                };

                $scope.toggleClosed = function () {
                    if ($scope.closed) {
                        $scope.from.date = null;
                        $scope.updateFrom();
                        $scope.to.date = null;
                        $scope.updateTo();
                    }
                };

                $scope.openCalendar = function (e, picker) {
                    if (!$scope[picker]) {
                        $scope.to = {
                            date: new Date(1970, 0, 1, 16, 0, 0),
                        };
                        $scope.from = {
                            date: new Date(1970, 0, 1, 8, 0, 0),
                        };
                        $scope.updateFrom();
                        $scope.updateTo();
                    }
                    $scope[picker].open = true;
                };

            }],
            link: function (scope, element, attrs) {

                scope.$watch('timeData', function (newValue, oldValue) {
                    if (newValue) {
                        if (newValue.from === null && newValue.to === null) {
                            scope.closed = true;
                        } else {
                            var array = [];
                            if (newValue.to) {
                                array = newValue.to.split(":");
                                scope.to = {
                                    date: new Date(1970, 0, 1, array[0], array[1], 0),
                                };
                            }
                            if (newValue.from) {
                                array = newValue.from.split(":");
                                scope.from = {
                                    date: new Date(1970, 0, 1, array[0], array[1], 0),
                                };
                            }
                        }
                    }
                }, true);
            },
        };

    }
})();


