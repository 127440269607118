namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        binding6: string;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            messageText: "=",
            existText: "@",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/popupMessage.html',
        transclude: true
    };

    function controllerCtrl() {
        this.$onInit = function () {
            this.radioValue = false;
            this.disabled = true;
        };

        this.getCheckedClass = function () {
            let className = (this.disabled) ? "" : "checked";

            this.radioValue = (className === "") ? false : true;

            if (!this.radioValue && this.messageText) {
                this.messageText = null;
            }

            return className;
        };

        this.$onChanges = function(changes) {
            let messageText = changes.existText;

            if (messageText !== undefined && !messageText.isFirstChange()) {
                this.messageText = messageText.currentValue;
                this.disabled = false;
            }
        };

    }

    angular
        .module('Yelon')
        .component('popupMessage', component);
}
