/**
 * Created by danielszenasi on 15/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('UsersController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', 'roleNames', UsersController]);

    function UsersController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm, roleNames) {
        let vm: any = this;
        vm.roleNames = roleNames;
        SidebarService.clearSidebarItems();
        let orderBy = $filter('orderBy');
        vm.attributes = {};
        vm.order = order;
        vm.to = null;
        vm.predicate = 'title';
        vm.reverse = true;
        vm.pagedUsers = [];
        vm.numPerPage = 10;
        vm.lastPage = 10;
        vm.maxSize = 5;
        vm.users = [];

        init();

        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('users').then(function (data) {
                vm.users = data;
                vm.currentPage = 1;
                order('name');
                vm.user_role = "helper";
            }).finally(function () {
                    spinnerService.decrementLoading();
                }
            );
        }

        vm.createUser = function () {
            ApiFactory.postFunction('users', {
                'name': vm.user_name,
                'role': vm.user_role,
                'email': vm.user_email
            }).then(function (data) {
                vm.users.push(data);
                vm.users = orderBy(vm.users, vm.predicate, vm.reverse);
                paginate();
                alert('Sikeres hozzáadás!');
            }).catch(function () {
                alert("Hozzáadás sikertelen. Lehet, hogy már regisztrált e-mail?");
            });
        };

        function paginate(emptyAllowed = false) {
            if (!emptyAllowed && vm.users.length === 0) {
                return;
            }
            let begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;
            vm.pagedUsers = vm.users.slice(begin, end);
        }

        $scope.$watch("usersCtrl.currentPage", paginate);

        function order(predicate: string) {
            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.users = orderBy(vm.users, predicate, vm.reverse);
            paginate();
        }

        //delete item
        vm.deleteItem = function (user) {
            $confirm({title: 'Megerősítés', text: 'Biztosan törli a "' + user.name + '" elemet?'})
                .then(function () {
                    var delIndex = vm.users.indexOf(user);
                    ApiFactory.deleteFunction('users/' + user.id).then(function () {
                        vm.users.splice(delIndex, 1);
                        paginate();
                    });
                });
        };
    }
})();
