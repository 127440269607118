/**
 * Created by danielszenasi on 11/08/16.
 */
class FaqItem {
    public id:number;
    public faq_category_id:number;
    public order:number;
    public question:string;
    public answer:string;

    constructor() {
        this.id = 0;
        this.faq_category_id = 0;
        this.order = 0;
        this.question = "";
        this.answer = "";
    }

    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    public compareBy(id:number):boolean {
        return (this.id === id);
    }
}
