(function () {
    'use strict';

    angular.module('Yelon').controller('LoginController', ['$state', 'LoginFactory', 'PasswordModalFactory', LoginController]);

    function LoginController($state:angular.ui.IStateService, LoginFactory, PasswordModalFactory) {

        let vm:any = this;

        vm.login = login;
        vm.resetemail = resetemail;
        vm.loginError = false;
        vm.loginErrorText = '';
        vm.loginErrors = {};

        function login(isValid) {
            if (isValid) {
                var credentials = {
                    email: vm.email,
                    password: vm.password
                };

                LoginFactory.loginFunction(credentials).then(function () {
                    $state.go('home');
                }).catch(function (data) {
                    vm.loginError = true;
                    vm.loginErrorText = data.loginErrorText;
                    vm.loginErrors[data.loginErrorText] = true;
                });
            }
        }
        function resetemail() {
            PasswordModalFactory.openModal({
                title: 'Új jelszó igénylése',
                ok: 'Új jelszó küldése',
                text: 'Kapni fogsz egy levelet a megadott email cimre, amelyben találsz egy linket, amelyen keresztül megadhatod, majd megerősítheted új jelszavadat!',
                cancel: "Mégsem"
            });
        }
    }
})();
