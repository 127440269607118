/**
 * Created by danielszenasi on 16/08/16.
 */
declare var _:any;

(function () {
    'use strict';

    angular.module('Yelon').controller('ArchivedChatConvController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', ArchivedChatConvController]);

    function ArchivedChatConvController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm) {
        let vm: any = this;
        let user = JSON.parse(localStorage.getItem('user'));
        SidebarService.clearSidebarItems();
        let settings: any = JSON.parse(localStorage.getItem('archived_chat')) || {};

        let orderBy = $filter('orderBy');
        let currentPageNumber;
        vm.archived_chats = [];
        vm.selectedTopics = settings.selectedTopics || [];
        vm.chatTopics = [];
        vm.convType = "channel";
        vm.selectedUsers = settings.selectedUsers || [];

        vm.predicate = settings.predicate || 'id';
        vm.reverse = settings.reverse || false;

        vm.searchString = settings.searchString || "";
        vm.starredOnly = settings.starredOnly || false;
        vm.conversations = [];

        vm.from = settings.from ? {open: false, date: new Date(settings.from)} : {open: false, date: null};
        vm.to = settings.to ? {open: false, date: new Date(settings.to)} : {open: false, date: null};

        vm.paginate = paginate;
        vm.order = order;

        vm.selectAllChannel = false;
        vm.selectAllAdmin = false;

        vm.limit = settings.limit || '10';

        vm.buttonBar = {
            show: true,
            now: {
                show: true,
                text: 'Most'
            },
            today: {
                show: true,
                text: 'Ma'
            },
            clear: {
                show: true,
                text: 'Törlés'
            },
            date: {
                show: true,
                text: 'Dátum'
            },
            time: {
                show: true,
                text: 'Idő'
            },
            close: {
                show: true,
                text: 'Bezárás'
            }
        };

        init();

        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('chattopics').then(function (data) {
                vm.chatTopics = data;
            }).finally(function () {
                spinnerService.decrementLoading();
            });

            let currentPageNumber = (settings.currentPage) ? settings.currentPage : 1;

            paginate(null, currentPageNumber);

            ApiFactory.getFunction('users').then(data => {
                vm.users = data;
            });

            $scope.$watch('archivedChatConvCtrl.selectAllChannel', (newValue, oldValue) => {
                if (typeof vm.conversations[0] !== 'undefined') {
                    if (newValue) {
                        vm.conversations[0].data.forEach((conversation) => {
                            conversation.selected = true;
                        });
                    } else {
                        vm.conversations[0].data.forEach((conversation) => {
                            conversation.selected = false;
                        });
                    }
                }
            });

            $scope.$watch('archivedChatConvCtrl.selectAllAdmin', (newValue, oldValue) => {
                if (typeof vm.conversations[1] !== 'undefined') {
                    if (newValue) {
                        vm.conversations[1].data.forEach((conversation) => {
                            conversation.selected = true;
                        });
                    } else {
                        vm.conversations[1].data.forEach((conversation) => {
                            conversation.selected = false;
                        });
                    }
                }
            });

            $scope.$watch('archivedChatConvCtrl.limit', (newValue, oldValue) => {
                if (typeof vm.conversations[0] !== 'undefined' && typeof vm.conversations[1] !== 'undefined') {
                    vm.paginate();
                }
            });
        }

        vm.updateChatConv = function (chatConv, type) {
            if (type === 'starred') {
                chatConv.starred = !chatConv.starred;
            }
            if (type === 'closed') {
                chatConv.closed = !chatConv.closed;
            }
            ApiFactory.patchFunction('chatconversations/' + chatConv.id, chatConv).then(function () {

            });

        };

        vm.onClickFilterRemoveBtn = function() {
            vm.selectedTopics = [];
            vm.selectedUsers = [];
            vm.predicate = "id";
            vm.searchString = "";
            vm.starredOnly = false;
            vm.limit = 15;
            vm.from = {open: false, date: null};
            vm.to = {open: false, date: null};
            vm.paginate();
        };

        function getQuery() {
            let queryParams = "order=" + vm.predicate + "&asc=" + (vm.reverse ? 1 : 0) + "&limit=" + vm.limit;
            vm.selectedTopics.forEach(topic => {
                queryParams += '&topics[]=' + topic.id;
            });
            vm.selectedUsers.forEach(user => {
                queryParams += '&users[]=' + user.id;
            });

            if (vm.searchString.length > 0) {
                queryParams += '&q=' + vm.searchString;
            }

            if (vm.starredOnly === true) {
                queryParams += '&starred=1';
            }

            if (vm.from.date !== null && vm.to.date !== null) {
                let from = $filter('date')(vm.from.date, 'yyyy-MM-dd HH:mm:ss');
                let to = $filter('date')(vm.to.date, 'yyyy-MM-dd HH:mm:ss');
                queryParams += "&from=" + from + "&to=" + to;
            }

            localStorage.setItem('archived_chat', JSON.stringify({
                predicate: vm.predicate,
                reverse: vm.reverse,
                selectedUsers: vm.selectedUsers,
                selectedTopics: vm.selectedTopics,
                searchString: vm.searchString,
                starredOnly: vm.starredOnly,
                from: vm.from.date,
                to: vm.to.date,
                currentPage: currentPageNumber,
                limit: vm.limit
            }));

            return queryParams;
        }

        function paginate(type?: string, page?: number) {
            currentPageNumber = page;

            let queryParams = getQuery();

            if (!type || type === 'channel') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('chatconversations?page=' + (typeof page === "undefined" ? vm.conversations[0].current_page : page) + '&type=channel&' + queryParams).then(function (data) {
                    data.data = data.data.map((conversation) => {
                        conversation.selected = false;
                        return conversation;
                    });

                    vm.conversations[0] = data;
                }).finally(() => spinnerService.decrementLoading());
            }

            if (!type || type === 'direct') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('chatconversations?page=' + (typeof page === "undefined" ? vm.conversations[1].current_page : page) + '&type=direct&' + queryParams).then(function (data) {
                    data.data = data.data.map((conversation) => {
                        conversation.selected = false;
                        return conversation;
                    });

                    vm.conversations[1] = data;
                }).finally(() => spinnerService.decrementLoading());
            }
        }

        function order(predicate: string) {
            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : vm.reverse;

            paginate();
        }

        //delete item
        vm.deleteChatConv = function (id) {
            $confirm({title: 'Megerősítés', text: 'Biztosan törli a beszélgetést?'})
                .then(() =>
                    ApiFactory.deleteFunction('chatconversations/' + id).then(() => vm.paginate())
                );
        };

        //download item
        vm.downloadChatConv = function (id) {
            spinnerService.incrementLoading();

            ApiFactory
                .downloadFunction('chatconversations/' + id + '.pdf')
                .finally(() => spinnerService.decrementLoading());
        };

        //download list
        vm.download = function () {
            spinnerService.incrementLoading();

            let query = getQuery();
            ApiFactory
                .downloadFunction('chatconversations.xlsx' + (query.length > 0 ? '?' + query : ''))
                .finally(() => spinnerService.decrementLoading());
        };

        vm.bulkAction = function (index, method) {
            console.log(method);
            let conversations = vm.conversations[index].data.filter((conversation) => conversation.selected);
            if (conversations.length === 0) {
                return;
            }

            switch (method) {
                case 'close':
                    let notClosed = conversations.filter((conversation) => !conversation.closed);

                    if (notClosed.length > 0) {
                        $confirm({title: 'Megerősítés', text: 'Biztosan zárolja az összes kijelölt beszélgetést?'})
                            .then(() => {
                                notClosed.forEach((conversation) => {
                                    spinnerService.incrementLoading();

                                    ApiFactory
                                        .patchFunction('chatconversations/' + conversation.id, {
                                            closed: true
                                        })
                                        .then(() => vm.paginate())
                                        .finally(() => spinnerService.decrementLoading());
                                });
                            });
                    } else {
                        $confirm({title: 'Megerősítés', text: 'Biztosan feloldja az összes kijelölt beszélgetést?'})
                            .then(() => {
                                conversations.filter((conversation) => conversation.closed).forEach((conversation) => {
                                    spinnerService.incrementLoading();

                                    ApiFactory
                                        .patchFunction('chatconversations/' + conversation.id, {
                                            closed: false
                                        })
                                        .then(() => vm.paginate())
                                        .finally(() => spinnerService.decrementLoading());
                                });
                            });
                    }

                    break;
                case 'star':
                    let notStarred = conversations.filter((conversation) => !conversation.starred);

                    if (notStarred.length > 0) {
                        $confirm({title: 'Megerősítés', text: 'Biztosan csillagozza az összes kijelölt beszélgetést?'})
                            .then(() => {
                                notStarred.forEach((conversation) => {
                                    spinnerService.incrementLoading();

                                    ApiFactory
                                        .patchFunction('chatconversations/' + conversation.id, {
                                            starred: true
                                        })
                                        .then(() => vm.paginate())
                                        .finally(() => spinnerService.decrementLoading());
                                });
                            });
                    } else {
                        $confirm({title: 'Megerősítés', text: 'Biztosan leveszi a csillagozást az összes kijelölt beszélgetésről?'})
                            .then(() => {
                                conversations.filter((conversation) => conversation.starred).forEach((conversation) => {
                                    spinnerService.incrementLoading();

                                    ApiFactory
                                        .patchFunction('chatconversations/' + conversation.id, {
                                            starred: false
                                        })
                                        .then(() => vm.paginate())
                                        .finally(() => spinnerService.decrementLoading());
                                });
                            });
                    }

                    break;
                case 'delete':
                    $confirm({title: 'Megerősítés', text: 'Biztosan törli az összes kijelölt beszélgetést?'})
                        .then(() => {
                            conversations.forEach((conversation) => {
                                spinnerService.incrementLoading();

                                ApiFactory
                                    .deleteFunction('chatconversations/' + conversation.id)
                                    .then(() => vm.paginate())
                                    .finally(() => spinnerService.decrementLoading());
                            });
                        });

                    break;
                case 'download':
                    $confirm({title: 'Megerősítés', text: 'Biztosan letölti az összes kijelölt beszélgetést?'})
                        .then(() => {
                            conversations.forEach((conversation) => {
                                spinnerService.incrementLoading();

                                ApiFactory
                                    .downloadFunction('chatconversations/' + conversation.id + '.pdf')
                                    .finally(() => spinnerService.decrementLoading());
                            });
                        });

                    break;
            }
        };
    }
})();
