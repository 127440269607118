/**
 * Created by danielszenasi on 05/08/16.
 */
/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';

    angular.module('Yelon').run(['$rootScope', '$state', '$auth', 'LoginFactory', 'LogoutFactory', 'ApiFactory', 'ChatFactory','$http', function ($rootScope: angular.IRootScopeService, $state: angular.ui.IStateService, $auth, LoginFactory, LogoutFactory, ApiFactory, ChatFactory, $http) {

        $http.get("./app/emoji_strategy.json").then((result) => {
            $rootScope["emojis"] = result.data;
        }).catch(() => {
            console.warn("Hiányzik az emoji_strategy.json file");
        });

        $rootScope['prevState'] = [];
        $rootScope['lastSave'] = false;
        $rootScope['errorMessage'] = false;
        var user = JSON.parse(localStorage.getItem('user'));
        LoginFactory.init();
        if ($auth.isAuthenticated() && user !== null && (user.role === 'supervisor' || user.role === 'helper')) {
            ChatFactory.init();
        }

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

            $rootScope["pageId"] = toState.data.pageId;

            $rootScope['lastSave'] = false;
            if (!$rootScope['backBtn']) {
                $rootScope['prevState'].push({ 'state': fromState, 'params': fromParams });
            } else {
                $rootScope['backBtn'] = false;
            }

            // Grab the user from local storage and parse it to an object
            if ($auth.isAuthenticated()) {
                var user = JSON.parse(localStorage.getItem('user'));
            } else {
                delCreds();
            }

            if (user) {
                $rootScope['currentUser'] = user;
                $rootScope['authenticated'] = true;

                if (toState.authenticate === 2) {

                    event.preventDefault();

                    $state.go('home');
                }
                if (typeof toState.rolesAllowed !== "undefined" && toState.rolesAllowed.indexOf(user.role) === -1) {
                    event.preventDefault();
                    $state.go('home');
                }
            }
            if (toState.authenticate === 1 && !$rootScope['authenticated']) {
                $rootScope['wantedState'] = toState;
                $rootScope['wantedParams'] = toParams;
                event.preventDefault();

                $state.go('login');
                return;
            }

            if ($rootScope['wantedState'] && toState.authenticate !== 2) {
                event.preventDefault();
                var tempState = $rootScope['wantedState'];
                var tempParams = $rootScope['wantedParams'];
                $rootScope['wantedState'] = false;
                $rootScope['wantedParams'] = false;
                $state.go(tempState.name, tempParams);
            }
        });

        /*ApiFactory.getFunction('info').then(function (data) {
         $rootScope['upload_max_filesize'] = parseInt(data.upload_max_filesize, 10);
         });*/

        $rootScope['logout'] = function () {
            LogoutFactory.logoutFunction();
        };

        function delCreds() {
            localStorage.removeItem('user');
            $rootScope['authenticated'] = false;
            $rootScope['currentUser'] = null;
        }
    }])
        .config(['tagsInputConfigProvider', function (tagsInputConfigProvider) {
            tagsInputConfigProvider.setDefaults('tagsInput', {
                replaceSpacesWithDashes: false
            });
        }]);
})();
