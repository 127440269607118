(function () {
    'use strict';

    angular.module('Yelon').directive("fileread", [fileread]);

    function fileread() {

        return {
            scope: {
                fileread: "=",
                upload: "<",
                fileType: "@",
                fileIndex: "@?"
            },
            link: function (scope, element, attributes) {
                let el = (element[0].tagName === "INPUT") ? element : element.children("input");

                el.bind("change", function (event) {
                    scope.$apply(() => {
                        scope.fileread = event.target.files[0];
                        scope.upload(event.target.files[0], scope.fileType, scope.fileIndex);
                    });
                });
            }
        };
    }
})();
