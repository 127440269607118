/**
 * Created by danielszenasi on 19/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').factory('PasswordFactory', ['$http', '$auth', '$rootScope', '$state', '$q', 'apiGeneralPrefix', PasswordFactory]);

    function PasswordFactory($http: angular.IHttpService, $auth, $rootScope, $state, $q: angular.IQService, apiGeneralPrefix) {
        return {
            newPassword: newPassword,
            resetPassword: resetPassword,
        };

        function newPassword(payload) {
            $http.post(apiGeneralPrefix + "authenticate/change-password", payload).then(success).catch(returnError);

            function success(data) {
                $rootScope.lastSave = new Date();

            }

        }

        function resetPassword(user_id, token, payload) {
            $http.post(apiGeneralPrefix + "authenticate/new-password/" + user_id + "/" + token, payload).then(function () {
                alert("A jelszó sikeresen megváltozott");
                $state.go('login');
            }).catch(returnError);

        }

        function returnError(response) {
            if (handleError(response)) {
                alert('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
            }
            return $q.reject(response);
        }

        function handleError(response) {
            if (response.status === 500) {
                return true;
            }

            //expired token
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    alert('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }

            //other message
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }

            return true;
        }


    }
})();
