namespace Yelon {
    'use strict';

    export interface IComponentBindings {}

    const component: ng.IComponentOptions = {
        bindings: {
            tileColors: "<",
            onUpdate: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            coverImage: "<",
            boundsCover: "=",
            existThemecolor: "@",
            existImages: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/backgroundUpload.html',
        transclude: true
    };

    function controllerCtrl() {
        let fileId;
        this.tileColorRadioChecked = null;

        this.$onInit = function () {

            if (!fileId) {
                this.bgRadioChecked = 0;
                if (this.tileColorRadioChecked === null) {
                    this.tileColorRadioChecked = this.tileColors[0];
                }
            }

            this.bgRadioBoxes = [{
                value: 0,
                text: "Nincs háttérkép"
            }, {
                value: 1,
                text: "Háttérkép"
            }];
        };

        this.$postLink = function () {
            if (!fileId) {
                this.onChangeInput();
            }
        };

        this.getCheckedClass = function (checked, value) {
            return (checked !== null && value.toString() === checked.toString()) ? "checked" : "";
        };

        this.getCheckedClassByColors = function (index) {
            return (this.tileColors[index] === this.tileColorRadioChecked) ? "checked" : "";
        };

        this.saveCroppedImages = function () {
            this.onChangeInput();
        };

        this.onChangeInput = function (isColorRadioBtnClick) {
            this.onUpdate({
                bounds: this.boundsCover,
                tileColorRadioCheckValue: this.tileColorRadioChecked,
                radioChecked: this.bgRadioChecked,
                fileId: fileId,
                callback: () => {
                    fileId = undefined;
                    delete this.existMessage;
                    delete this.sourceImage;
                    delete this.file;
                }
            });
        };

        this.$onChanges = function (changes) {
            let uploadedFile = changes.uploadedFile;
            let existThemecolor = changes.existThemecolor;
            let existImages = changes.existImages;

            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                let fileProp = uploadedFile.currentValue;

                if (fileProp.type === "cover") {
                    fileId = fileProp.data.id;
                    this.tileColorRadioChecked = null;
                    this.existMessage = "Van feltöltve kép";
                    this.onChangeInput();
                }
            }

            if (existThemecolor !== undefined && existThemecolor.isFirstChange()) {
                this.tileColorRadioChecked = existThemecolor.currentValue;
            }

            if (existImages !== undefined && existImages.isFirstChange()) {
                let values = existImages.currentValue;
                if (values !== undefined && values.length > 0) {
                    values.forEach((value) => {
                        if (value.pivot.type === "cover" && !this.sourceImage) {
                            this.setCroppedImage(value);
                        }
                    });
                }
            }
        };

        this.setCroppedImage = function (image) {

            if (image.pivot.disabled === 0) {
                this.bgRadioChecked = 1;
                this.tileColorRadioChecked = null;
            } else {
                this.bgRadioChecked = 0;
            }

            this.existMessage = "Van feltöltve kép";
            this.boundsCover.left = image.pivot.left;
            this.boundsCover.right = image.pivot.right;
            this.boundsCover.top = image.pivot.top;
            this.boundsCover.bottom = image.pivot.bottom;
            fileId = image.id;
            this.file = {
                name: image.full_url.split('/').reverse()[0]
            };
            this.toDataUrl(image.full_url, (img) => {
                this.sourceImage = img;
            }, 'image/jpeg');
        };

        this.onClickRemoveBtn = function (event: Event) {
            event.preventDefault();

            this.deleteFile(fileId, () => {
                fileId = undefined;
                delete this.existMessage;
                delete this.sourceImage;
                delete this.file;
                this.bgRadioChecked = 0;
            });
        };

        this.toDataUrl = function (src, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas: any = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
                img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = src;
            }
        };
    }

    angular
        .module('Yelon')
        .component('backgroundUpload', component);
}
