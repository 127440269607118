/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('FAQEditController', ['$scope', '$stateParams', '$timeout', 'ApiFactory', 'spinnerService', 'SidebarService', FAQEditController]);

    function FAQEditController($scope, $stateParams:angular.ui.IStateParamsService, $timeout, ApiFactory, spinnerService, SidebarService) {
        let vm:any = this;
        SidebarService.loadPreset('faq');
        SidebarService.enable();

        vm.timeout = false;
        vm.editing = false;
        vm.categories = [];
        vm.faqItem = new FaqItem();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            // load categories
            angular.forEach(data, function (value, key) {
                let item = {id: value.id, label: value.title};
                vm.categories.push(item);
            });

            // load item
            if (typeof($stateParams['id']) !== "undefined") {
                vm.editing = true;
                spinnerService.incrementLoading();
                ApiFactory.getFunction('faqitems/' + $stateParams['id'] + '/edit').then(function (data) {
                    vm.faqItem = data;
                }).finally(function () {
                    spinnerService.decrementLoading();
                    vm.watchInputs();
                });
            }

            // select first option by default
            if (!vm.editing && vm.faqItem.faq_category_id === 0) {
                vm.faqItem.faq_category_id = vm.categories[0].id;
            }
        }).finally(function () {
            spinnerService.decrementLoading();
            if (!vm.editing) {
                vm.watchInputs();
            }
        });

        // watch item for changes
        vm.watchInputs = function () {
            $scope.$watch('faqEditCtrl.faqItem', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (vm.timeout) {
                        $timeout.cancel(vm.timeout);
                    }
                    vm.timeout = $timeout(function () {
                        if (vm.faqItem.id) {
                            //update
                            ApiFactory.patchFunction('faqitems/' + newVal.id, newVal).then(function () {
                                console.log('Updated');
                            });
                        } else {
                            if (vm.faqItem.question !== "" || vm.faqItem.answer !== "") {
                                //store
                                ApiFactory.postFunction('faqitems', vm.faqItem).then(function (data) {
                                    vm.faqItem = data;
                                });
                            }
                        }
                    }, 2000);
                }
            }, true);
        };
    }
})();
