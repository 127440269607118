class Paragraph {
    public id:number;
    public type:string;
    public content:string;
    public image_id:number;
    public order:number;

    constructor(type) {
        this.id = 0;
        this.type = "";
        this.content = "";
        this.type = type;
    }

    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    public compareBy(id:number):boolean {
        return (this.id === id);
    }
}
