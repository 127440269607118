/**
 * Created by csoma on 2016. 09. 22..
 */
(function () {
    'use strict';

    angular.module('Yelon').directive("nullable-date", () => {
        return {
            require: 'ngModel',
            link: (scope, elem, attrs, ctrl: any) => {
                ctrl.$validators.nullableDate = (modelValue, viewValue) => {
                    if (typeof modelValue === "undefined") {
                        return false;
                    }
                    else if (modelValue === null) {
                        return true;
                    } else {
                        return !isNaN(Date.parse(modelValue));
                    }
                };
            }
        };
    });
})();
