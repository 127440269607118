(function () {
    'use strict';

    angular.module('Yelon').directive("charLimit", charLimit);

    function charLimit() {
        return {
            restrict: "A",
            scope: {
                charLimit: '='
            },
            link: function(scope,elem,attrs){
                scope.keycodes = [8, 37, 38, 39, 40, 46];

                elem.bind('keyup', function(event) {
                    var element = elem.parent();

                    //element.toggleClass('warning', scope.charLimit - elem.val().length <= 10);
                    element.toggleClass('has-error', elem.val().length >= scope.charLimit);
                });

                elem.bind('keypress', function(event) {
                    // Once the limit has been met or exceeded, prevent all keypresses from working
                    if (elem.val().length >= scope.charLimit) {
                        // Except backspace, delete, and arrow keys.
                        if (scope.keycodes.indexOf(event.keyCode) === -1) {
                            event.preventDefault();
                        }
                    }
                });
            }
        };
    }
})();
