/**
 * Created by danielszenasi on 08/08/16.
*/
(function () {
    'use strict';

    angular.module('Yelon').controller('HomeController', ['$auth', 'SidebarService','$rootScope','ApiFactory', HomeController]);

    function HomeController($auth, SidebarService, $rootScope, ApiFactory) {
        let vm:any = this;
        let user = JSON.parse(localStorage.getItem('user'));
        SidebarService.loadPreset(user.role);

        if (SidebarService.isDisabled && !$rootScope.isSubscribeOnClick) {
            $rootScope.$on("onClickedPopup", function(){
                $rootScope.popupModel = {
                    title: null,
                    message: null,
                    url: null
                };

                $rootScope.showPopupForm = true;
            });
            $rootScope.isSubscribeOnClick = true;
        }

        SidebarService.enable();

        if (!$rootScope.onSubmitPopupForm) {
            $rootScope.onSubmitPopupForm = function(event) {
                ApiFactory.postFunction("notifications", $rootScope.popupModel).then(result => {
                    delete $rootScope.showPopupForm;
                });
            };

            $rootScope.onClosePopupForm = function(event) {
                event.preventDefault();

                delete $rootScope.showPopupForm;
            };
        }
    }
})();
