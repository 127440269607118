/**
 * Created by danielszenasi on 09/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('ChatTopicsController', ['ApiFactory', 'SidebarService', 'spinnerService', ChatTopicsController]);

    function ChatTopicsController( ApiFactory, SidebarService, spinnerService) {
        let vm:any = this;
        vm.topics = [];

        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('chattopics').then(function (data) {
            angular.forEach(data, function(value, key){
                let item = {id:value.id ,text: value.title};
                vm.topics.push(item);
            });
        }).finally(function() {
            spinnerService.decrementLoading();
        });

        vm.topicAdded = function($tag) {
            ApiFactory.postFunction('chattopics', {'title': $tag.text}).then(function (data) {
                $tag.id = data.id;
            });
        };

        vm.topicRemoved = function($tag) {
            ApiFactory.deleteFunction('chattopics/' + $tag.id).then(function () {});
        };
    }
})();
