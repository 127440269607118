(function () {
    'use strict';

    angular.module('Yelon').directive("superTagInput", superTagInput);

    function superTagInput() {
        return {
            restrict: "E",
            scope: {
                label: '@',     //text for the label in front of the select and taginput
                options: '=',   //the select's (unfiltered) options
                tags: '=',      //like an ng-model
                autocompleteOnly: '=?',  //defaults to true,
                displayProperty: '@?', //default: title
                minTags: '=',
                maxTags: '=?'
            },
            templateUrl: 'app/layouts/super-tag-input.html',
            controller: ['$scope', '$filter', function ($scope, $filter) {
                $scope.displayProperty = typeof $scope.displayProperty === "undefined" ? "title" : $scope.displayProperty;
                $scope.filteredOptions = [];
                $scope.selectedValue = null;
                if (typeof $scope.maxTags === "undefined") {
                    $scope.maxTags = 100;
                }

                $scope.findTags = function ($query) {
                    return $scope.filteredOptions.filter(function(val) {
                        return val[$scope.displayProperty].toLowerCase().indexOf($query.toLowerCase()) !== -1;
                    });
                };

                $scope.addTag = function() {
                    if ($scope.selectedValue === null) {
                        return;
                    }
                    $scope.tags.push($scope.selectedValue);
                };

                $scope.filterOptions = function() {
                    if (typeof($scope.options) === "undefined") {
                        $scope.filteredOptions = [];
                        return;
                    }
                    $scope.filteredOptions = $scope.options.filter(function(option) {
                        var found = false;
                        angular.forEach($scope.tags, function(tag, key) {
                            if (found) {
                                return;
                            }
                            if (tag.id === option.id && tag[$scope.displayProperty] === option[$scope.displayProperty]) {
                                found = true;
                            }
                        });
                        return !found;
                    });
                };
            }],
            link: function(scope, element, attrs) {
                scope.filterOptions();
                scope.autocompleteOnly = angular.isDefined(scope.autocompleteOnly) ? scope.autocompleteOnly : true;

                scope.$watch('tags', function(newValue, oldValue) {
                    if (newValue) {
                        scope.filterOptions();
                    }
                }, true);

                scope.$watch('options', function(newValue, oldValue) {
                    scope.filterOptions();
                }, true);
            },
        };
    }
})();
