(function () {
    'use strict';

    angular.module('Yelon').controller('ArticleEditController', ['$scope', '$state', '$stateParams', '$timeout',
        '$confirm', '$filter', 'ApiFactory', 'spinnerService', 'SidebarService', 'Upload', 'apiPrefix', 'sitePrefix',
        'tileColors', 'adultTileColors', '$rootScope',
        ArticleEditController
    ]);

    function ArticleEditController($scope,
        $state: angular.ui.IStateService,
        $stateParams: angular.ui.IStateParamsService,
        $timeout, $confirm, $filter, ApiFactory, spinnerService, SidebarService,
        Upload, apiPrefix, sitePrefix, tileColors, adultTileColors, $rootScope) {

        let self: any = this;
        let articleId;
        let articleTypes = {
            article: "Cikk",
            gallery: "Galéria",
            quiz: "Kvíz",
            motto: "Mottó",
            video: "Videó",
            fix: "Fix"
        };
        let timer;
        let isNewArticle;
        let images;
        let initImages = false;
        let initCoverImage = false;
        let coverImage = {
            id: null,
            full_url: null,
            url: null,
            pivot: {
                article_id: null,
                disabled: false,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                type: "cover"
            }
        };
        let isDelete = false;
        let isLoaded = false;
        let isCreateParagraph;

        this.articleType = $stateParams["type"];
        $scope.breadCrumbText = "Új bejegyzés";

        //noinspection JSAnnotator
        this.currentState = window.localStorage.getItem("currentStateName");

        this.articleTypeText = articleTypes[$stateParams["type"]];
        this.mainType = $stateParams["mainType"];
        this.tileColors = ($stateParams["mainType"] === "adult") ? adultTileColors : tileColors;
        this.paragraphs = [];
        this.boundsCover = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
        this.selectedAllCategories = (this.mainType !== 'adult' && this.articleType !== "fix") ? false : true;
        this.requireTitle = true;
        this.isLoaded = false;
        this.sitePrefix = sitePrefix;

        if ($stateParams["id"] !== undefined) {
            articleId = $stateParams["id"];
            isNewArticle = false;
            $scope.breadCrumbText = "Bejegyzés szerkesztése";
            loadExistArticle();
        } else {
            if ($state !== undefined && $state.current.data.isCreated) {
                articleId = window.localStorage.getItem("article_id");
                isNewArticle = false;
                $scope.breadCrumbText = "Bejegyzés szerkesztése";
                loadExistArticle();
            } else {
                subscribeArticleWatch();
                this.article = new Article($stateParams["mainType"]);
                this.article.type = this.articleType;
                self.isLoaded = true;
            }
        }

        function loadExistArticle() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('articles/' + articleId + '/edit').then((data) => {
                isLoaded = true;

                self.selectedAllCategories = true;
                self.requireTitle = false;

                images = data.images;

                data.images.forEach((image) => {
                    if (image.pivot.type === "cover") {
                        coverImage = image;
                    }
                });

                self.existPopupMessage = data.popup_message;
                self.existDate = data.published_at;
                self.existTags = data.tags;
                self.existParagraphs = data.paragraphs;
                self.existImages = data.images;
                self.existAttributesValues = data.attributes_values;
                self.article = data;
                self.coverImage = coverImage;
            }).finally(() => {
                spinnerService.decrementLoading();
                $timeout(() => {
                    subscribeArticleWatch();
                }, 2000);

                self.isLoaded = true;
            });
        }

        function subscribeArticleWatch() {

            $scope.$watch("articleEditCtrl.article", function (newValue, oldValue) {

                if (newValue === undefined || isLoaded) {
                    isLoaded = false;
                    return;
                }

                if (newValue.duplicate || oldValue.duplicate) {
                    return;
                }

                let hasAttributes = (newValue.attributes !== undefined) ? true : false;

                if (self.mainType === 'adult' || newValue.type === "fix" ) {
                    hasAttributes = true;
                }

                let hasTitle = (newValue.title !== "") ? true : false;

                if (!self.selectedAllCategories || self.requireTitle) {
                    self.disabledAllInput = true;
                    return;
                }

                self.disabledAllInput = false;

                if (newValue.published_at !== undefined) {

                    if (newValue.id === null) {
                        if (hasAttributes && hasTitle) {
                            if (newValue.images === undefined && !initImages) {
                                setImagesForArticle();
                            } else {
                                initImages = false;
                                createArticle(newValue);
                            }
                        }
                    } else {

                        if (isNewArticle) {

                            articleId = newValue.id;
                            isNewArticle = false;

                            if (self.paragraphs.length > 0) {
                                self.paragraphs.forEach((paragraph, index) => {
                                    createParagraph(paragraph, newValue.id, index);
                                });
                            }

                            if (initCoverImage && coverImage.id !== null) {
                                coverImage.pivot.article_id = articleId;
                                self.article.images = [coverImage];
                                initCoverImage = false;
                            }

                            return;
                        }

                        if (isCreateParagraph) {
                            return;
                        }

                        if (angular.toJson(newValue.paragraphs) !== angular.toJson(oldValue.paragraphs)) {
                            return;
                        }

                        if (newValue.published_at === null) {

                            if (timer) {
                                window.clearTimeout(timer);
                                timer = undefined;
                            }

                            timer = window.setTimeout(() => {
                                updateArticle(newValue);
                            }, 2000);
                        } else {
                            updateArticle(newValue);
                        }
                    }
                } else {
                    if (newValue.id === null) {
                        if (hasAttributes && hasTitle) {
                            self.existDate = null;
                            newValue.published_at = null;
                        }
                    }
                }
            }, true);
        }

        $scope.$watch("articleEditCtrl.coverImage", (newCover) => {
            initCoverImage = true;

            coverImage.pivot.top = self.boundsCover.top;
            coverImage.pivot.right = self.boundsCover.right;
            coverImage.pivot.bottom = self.boundsCover.bottom;
            coverImage.pivot.left = self.boundsCover.left;

            if (coverImage.id !== null) {
                setImagesForArticle();
            }
        });

        function setImagesForArticle() {

            initImages = true;

            if (images) {

                images.forEach((image) => {
                    image.pivot.article_id = articleId;
                });

                if (coverImage.id !== null) {
                    images = images.filter((image) => image.pivot.type !== 'cover');
                    images.push(coverImage);
                }

                self.article.images = images;
            } else if (self.article.images === undefined) {

                self.article.images = [];

                if (coverImage.id !== null) {
                    images = [coverImage];
                    self.article.images = images;
                } else {
                    self.article.images = [];
                }
            } else {
                if (coverImage.id !== null && self.article.images.length === 4) {
                    self.article.images = self.article.images.filter((image) => image.pivot.type !== 'cover');
                    self.article.images.push(coverImage);
                } else if (coverImage.id !== null) {
                    self.article.images = [coverImage];
                }
            }

        }

        $scope.$watchCollection("articleEditCtrl.paragraphs", function (newValue, oldValue) {
            let length = newValue.length - 1;

            if (length === -1) {
                return;
            }

            let lastParagraph = newValue[length];

            if (articleId && lastParagraph.id === undefined && (lastParagraph.type !== "image" || lastParagraph.type !== 'gallery')) {
                createParagraph(lastParagraph, articleId, length);
            }
        });

        function createArticle(articleData: any) {
            let prepareNotify: string = (articleData.published_at === null) ? "Piszkozat mentése..." : "Publikálás...";

            let successNotify: string = (articleData.published_at === null) ? "Piszkozat mentve" : "Publikálva";

            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };

            ApiFactory.postFunction("articles", articleData).then(data => {
                $scope.breadCrumbText = "Bejegyzés szerkesztése";

                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };

                window.localStorage.setItem("article_id", data.id);

                $state.go('articles-create.created', {
                    mainType: data.main_type,
                    type: data.type,
                    id: data.id
                }, {
                    notify: false
                });

                isNewArticle = true;

                self.article = data;
            });
        }

        function updateArticle(articleData: any) {

            if (isDelete) {
                isDelete = false;
                return;
            }

            let prepareNotify: string = (articleData.published_at === null) ? "Piszkozat mentése..." : "Publikálás...";

            let successNotify: string = (articleData.published_at === null) ? "Piszkozat mentve" : "Publikálva";

            if (articleData.notify) {
                prepareNotify = "Értesítés küldése...";
                successNotify = "Értesítés elküldve";
            }

            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };

            ApiFactory.patchFunction('articles/' + articleData.id, articleData).then(data => {

                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };

            });
        }

        function createParagraph(paragraph: any, articleId: number, indexNumber: number) {

            if (paragraph.id) {
                self.updateParagraph(paragraph);
                return;
            }

            isCreateParagraph = true;

            $rootScope.notificationProperties = {
                title: "Blokk létrehozása...",
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };

            ApiFactory.postFunction('articles/' + articleId + '/paragraphs', paragraph).then(function (data) {

                self.paragraphs[indexNumber] = data;

                isCreateParagraph = false;

                $rootScope.notificationProperties = {
                    title: "Blokk létrehozva",
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };

            });
        }

        this.updateParagraph = function (paragraph) {

            $rootScope.notificationProperties = {
                title: "Blokk frissítése...",
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };

            if (paragraph.type === "video") {
                let match = paragraph.content.match(/=(.+)$/);

                if (match !== null) {
                    paragraph.content = match[1];
                }
            }

            return ApiFactory.patchFunction('articles/' + self.article.id + '/paragraphs/' + paragraph.id, paragraph).then(function (data) {

                $rootScope.notificationProperties = {
                    title: "Blokk frissítve",
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };

            });
        };

        this.deleteParagraph = function (paragraph, callback) {
            $confirm({
                    title: 'Megerősítés',
                    text: 'Biztosan törli a "' + paragraph.content + '" elemet?'
                })
                .then(function () {
                    let delIndex;

                    if (self.article.paragraphs === undefined) {
                        delIndex = self.paragraphs.indexOf(paragraph);
                        self.paragraphs.splice(delIndex, 1);
                        return;
                    }

                    delIndex = self.paragraphs.indexOf(paragraph);

                    ApiFactory.deleteFunction('articles/' + self.article.id + '/paragraphs/' + paragraph.id).then(function (data) {
                        self.paragraphs.splice(delIndex, 1);

                        callback();
                    });
                });
        };

        this.getAttributes = function (callback) {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('attributes').then(callback).finally(function () {
                spinnerService.decrementLoading();
            });
        };

        this.getTags = function (callback) {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('tags').then(callback).finally(function () {
                spinnerService.decrementLoading();
            });
        };

        this.onChangeTileConfig = function (config) {
            if (self.article === undefined || isLoaded || self.article.id === null) {
                return;
            }

            self.article.color = config.tileColorRadioCheckValue;

            if (config.fileId && !config.tileTypeRadioCheckValue) {
                self.deleteFile(config.fileId, () => {
                    updateArticle(self.article);
                    config.callback();
                });
                return;
            } else if (!config.fileId && !config.tileTypeRadioCheckValue) {
                updateArticle(self.article);
                return;
            }

            if (!articleId) {
                images = config.croppedImages;
            } else {
                initImages = false;
                images = config.croppedImages;
                setImagesForArticle();
            }
        };

        this.onChangeBgConfig = function (config) {
            if (self.article === undefined || isLoaded) {
                return;
            }

            self.article.cover_color = config.tileColorRadioCheckValue;
            if (config.tileColorRadioCheckValue !== null && config.radioChecked === 0) {
                coverImage.pivot.disabled = true;

                if (config.fileId) {
                    self.deleteFile(config.fileId, () => {
                        updateArticle(self.article);
                        config.callback();
                    });
                }

            } else if (coverImage.id !== null) {
                coverImage.pivot.disabled = false;
                coverImage.pivot.left = config.bounds.left;
                coverImage.pivot.top = config.bounds.top;
                coverImage.pivot.right = config.bounds.right;
                coverImage.pivot.bottom = config.bounds.bottom;
                setImagesForArticle();
            }
        };

        this.onChangeBlockPosition = function (blocks) {
            self.article.paragraphs = blocks;
        };

        this.fileUpload = function (file, type, index) {

            let indexNumber = parseInt(index);

            spinnerService.incrementLoading();

            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': file,
                    'directory': ""
                }
            }).then(function (response) {
                self.uploadedFileProperties = {
                    type: type,
                    data: response.data,
                    index: indexNumber
                };

                if (type === "paragraph") {
                    self.paragraphs[indexNumber].image_id = response.data.id;
                    delete self.paragraphs[indexNumber].image;

                    if (articleId) {
                        createParagraph(self.paragraphs[indexNumber], articleId, indexNumber);
                    }
                } else if (type === "cover") {
                    coverImage.full_url = response.data.full_url;
                    coverImage.url = response.data.url;
                    coverImage.id = response.data.id;
                }
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            }).finally(() => spinnerService.decrementLoading());
        };

        this.deleteFile = function (id, callback) {
            isDelete = true;

            self.article.images.forEach((image, index) => {
                if (image.id === id) {
                    let delNumber = (image.pivot.type === "cover") ? 1 : 4;

                    if (image.pivot.type === "cover") {
                        coverImage.id = null;
                    }

                    self.article.images.splice(index, delNumber);
                }
            });

            ApiFactory.deleteFunction('images/' + id).then(() => {
                if (typeof callback === "function") {
                    callback();
                }
            });
        };

        this.updateOrder = function (movedParagraph, index) {
            movedParagraph.order = index + 1;
            spinnerService.incrementLoading();
            self.updateParagraph(movedParagraph).finally(() => spinnerService.decrementLoading());
        };

        this.updateShow = function(value) {
            self.article.show_title = value;
        };

        this.duplicateArticle = function(callbackDuplicate) {
            self.article.duplicate = "mobile";

            let prepareNotify: string = "Duplikálás...";
            let successNotify: string = "Duplikálva";

            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };

            ApiFactory.patchFunction('articles/' + self.article.id, self.article).then(data => {

                delete self.article.duplicate;

                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };

                callbackDuplicate();

            });
        };

    }
})();
