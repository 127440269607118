namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        publishDate: string;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            publishDate: "=",
            existDate: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/publishDate.html',
        transclude: true
    };

    function controllerCtrl() {
        this.buttonBar = {
            show: true,
            now: {
                show: true,
                text: 'Most'
            },
            today: {
                show: true,
                text: 'Ma'
            },
            clear: {
                show: true,
                text: 'Törlés'
            },
            date: {
                show: true,
                text: 'Dátum'
            },
            time: {
                show: true,
                text: 'Idő'
            },
            close: {
                show: true,
                text: 'Bezárás'
            }
        };

        this.$onInit = function () {
            let currentDate = new Date();

            this.dateValue = currentDate;
            this.radioValue = null;
            this.currentValue = currentDate;
            this.isOpen = false;
        };

        this.$onChanges = function (changes) {
            let publishDate = changes.existDate;

            if (publishDate !== undefined && !publishDate.isFirstChange()) {
                if (publishDate.currentValue === null) {
                    this.radioValue = "draft";
                    this.currentValue = null;
                } else {
                    this.dateValue = new Date(publishDate.currentValue);
                    this.currentValue = publishDate.currentValue;
                    this.radioValue = publishDate.currentValue;
                }
            }
        };

        this.getCheckedClass = function (isDraft) {
            let className = "";

            if (isDraft && this.radioValue === "draft") {
                className = "checked";
                this.publishDate = null;
                this.currentValue = null;
            } else if (!isDraft && this.radioValue !== null && this.radioValue !== 'draft') {
                className = "checked";
                this.currentValue = this.radioValue;
            }

            return className;
        };

        this.onClickPublish = function() {
            if (this.radioValue !== "draft") {
                this.publishDate = formatDate(this.dateValue);
            }
        };

        function formatDate(date) {
            let dateValue = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();

            let monthValue = date.getMonth() + 1;
            monthValue = (monthValue < 10) ? "0" + monthValue : monthValue;

            let hourValue = (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();

            let minValue = (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();

            let secondValue = (date.getSeconds() < 10) ? "0" + date.getSeconds() : date.getSeconds();

            return `${date.getFullYear()}-${monthValue}-${dateValue} ${hourValue}:${minValue}:${secondValue}`;
        }

        this.openCalendar = function(event) {
            event.preventDefault();
            event.stopPropagation();

            if (this.radioValue !== "draft") {
                this.isOpen = true;
            }

        };

    }

    angular
        .module('Yelon')
        .component('publishDate', component);
}
