namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        categories: any;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            categories: "=",
            getAttributes: "<",
            existAttributes: "<",
            selectedAllcategories: "=",
            publishDate: "<",
            mainType: "@"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/categories.html',
        transclude: true
    };

    function controllerCtrl() {

        let self = this;
        let prevAttributes = [];
        let isExistAttributes = false;

        function loadAttributes() {
            self.getAttributes(function (results) {
                self.ages = [];
                results.forEach((attribute, index) => {
                    switch (attribute.id) {
                        case 1:
                            self.themes = attribute.attributes_values;
                            break;
                        case 2:
                        case 4:
                            self.ages = self.ages.concat(attribute.attributes_values);

                            break;
                        case 3:
                            self.sexes = attribute.attributes_values;
                            break;
                    }
                });
            });
        }

        this.changeTheme = function () {
            if (!this.selectedThemes) {
                this.selectedThemes = [this.currentTheme];
            } else {
                this.selectedThemes.push(this.currentTheme);
            }

            this.setCategories(this.currentTheme.id);
        };

        this.changeAge = function () {
            if (this.currentAge === null) {
                return;
            }

            if (!this.selectedAges) {
                this.selectedAges = [this.currentAge];
            } else {
                this.selectedAges.push(this.currentAge);
            }

            this.setCategories(this.currentAge.id, "age");
        };

        this.changeSex = function () {
            if (this.currentSex === null) {
                return;
            }

            if (!this.selectedSexes) {
                this.selectedSexes = [this.currentSex];
            } else {
                this.selectedSexes.push(this.currentSex);
            }

            this.setCategories(this.currentSex.id, "sex");
        };

        this.onClickRemoveBtn = function (index, selectedArray, item) {

            if ((item.attribute_id === 2 || item.attribute_id === 3) && selectedArray.length === 1 && this.publishDate !== null) {
                return;
            }

            selectedArray.splice(index, 1);

            if (item.attribute_id === 1) {
                delete this.selectedThemes;
                delete this.currentTheme;
            } else if (item.attribute_id === 2) {
                this.ages.push(item);
            } else {
                this.sexes.push(item);
            }

            if (this.categories.length > 0) {
                this.categories.forEach((categoryItemId, index) => {
                    if (categoryItemId === item.id) {
                        this.categories.splice(index, 1);
                    }
                });

            }
        };

        this.setCategories = function (value, type) {

            if (this.selectedThemes && this.selectedAges && this.selectedSexes) {
                this.selectedAllcategories = true;
            }

            switch (type) {
                case "age":
                    this.ages.forEach((item, index) => {
                        if (item.id === value) {
                            this.ages.splice(index, 1);
                        }
                    });
                    break;
                case "sex":
                    this.sexes.forEach((item, index) => {
                        if (item.id === value) {
                            this.sexes.splice(index, 1);
                        }
                    });
                    break;
            }


            if (!this.categories) {
                this.categories = [value];
            } else {
                this.categories.push(value);
            }

        };

        this.$onChanges = function (changes) {
            let existAttributes = changes.existAttributes;

            if (existAttributes !== undefined && existAttributes.isFirstChange()) {
                loadAttributes();
            } else if (existAttributes !== undefined && !existAttributes.isFirstChange()) {
                let values = existAttributes.currentValue;
                window.setTimeout(() => {
                    setExistCategories(values);
                }, 250);
            }
        };

        function setExistCategories(values) {
            self.selectedSexes = [];
            self.selectedAges = [];

            values.forEach((value) => {
                let attrId = value.attribute_id;

                switch (attrId) {
                    case 1:
                        self.themes.forEach((theme, index) => {
                            if (theme.id === value.id) {
                                self.currentTheme = theme;
                                self.selectedThemes = [self.currentTheme];
                                prevAttributes.push(theme.id);
                            }
                        });
                        break;
                    case 2:
                    case 4:
                        self.ages.forEach((age, index) => {
                            if (age.id === value.id) {
                                self.selectedAges.push(age);
                                prevAttributes.push(age.id);
                                self.ages.splice(index, 1);
                            }
                        });

                        break;
                    case 3:
                        self.sexes.forEach((sex, index) => {
                            if (sex.id === value.id) {
                                self.selectedSexes.push(sex);
                                prevAttributes.push(sex.id);
                                self.sexes.splice(index, 1);
                            }
                        });
                        break;
                }

                if (!self.categories) {
                    self.categories = prevAttributes;
                }

            });
        };
    }

    angular
        .module('Yelon')
        .component('categoriesInput', component);
}
