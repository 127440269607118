/**
 * Created by fures on 2016. 08. 18..
 */
(function () {
    'use strict';

    angular.module('Yelon').
    filter('ceil', function() {
        return function(x) {
            return Math.ceil(x);
        };
    });

})();
