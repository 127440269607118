/**
 * Created by fures on 2016. 08. 16..
 */
class Article {
    public id: number;
    public user_id: number;
    public title: string;
    public teaser: string;
    public main_type: string;
    public type: string;
    public color: string;
    public popup_message: string;
    public archived: boolean;

    constructor(main_type) {
        this.id = null;
        this.user_id = null;
        this.title = "";
        this.teaser = "";
        this.main_type = main_type;
        this.type = "article";
        this.color = "";
        this.popup_message = "";
        this.archived = false;
    }

    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    public compareBy(id: number): boolean {
        return (this.id === id);
    }
}
