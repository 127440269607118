angular.module('Yelon').controller('RatingModalController', ['$uibModalInstance', 'ApiFactory', RatingModalController]);
function RatingModalController($uibModalInstance, ApiFactory) {
    let vm = this;
    ApiFactory.getFunction('chattopics').then(function (data) {
        vm.topics = data;
    });

    vm.sendRating = function () {
        let ratingValues = {
            starred: typeof vm.rating.isStarred === "undefined" ? false : vm.rating.isStarred,
            topics: [],
            comment: vm.rating.comment
        };
        for (let id in vm.rating.selectedTopics) {
            if (vm.rating.selectedTopics.hasOwnProperty(id)) {
                ratingValues.topics.push(id);
            }
        }
        $uibModalInstance.close(ratingValues);
    };
}
