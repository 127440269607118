(function () {
    'use strict';

    angular.module('Yelon').controller('CategoryController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', '$timeout', 'Upload', 'apiPrefix', 'tileColors', CategoryController]);

    function CategoryController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm, $timeout, Upload, apiPrefix, tileColors) {
        let vm: any = this;
        SidebarService.loadPreset('articles-web');
        SidebarService.enable();

        vm.attributes = {};
        vm.creating = false; //is there any creating in progress

        vm.picFile = false;

        vm.tileColors = tileColors;

        init();

        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('attributes').then(function (data) {
                vm.attributes = data;
            }).finally(function () {
                spinnerService.decrementLoading();
            });
        }

        vm.deleteItem = function (parentIndex, index) {
            var attributesValue = vm.attributes[parentIndex].attributes_values[index];
            $confirm({title: 'Megerősítés', text: 'Biztosan törli a "' + attributesValue.title + '" elemet?'})
                .then(function () {
                    ApiFactory.deleteFunction('attributes/' + vm.attributes[parentIndex].id + '/attributesvalues/' + attributesValue.id).then(function (data) {
                        vm.attributes[parentIndex].attributes_values.splice(index, 1);
                    });
                });
        };

        vm.moveItem = function (parentIndex, index, dir) {
            if (vm.creating) {
                return;
            }
            var attributesValues = vm.attributes[parentIndex].attributes_values;
            var attributesValue = attributesValues[index];
            dir === "up" ? attributesValue.order-- : attributesValue.order++;

            ApiFactory.patchFunction('attributes/' + vm.attributes[parentIndex].id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                if (typeof(data.message) !== "undefined" && data.message === "cant_save") {
                    alert('Sikertelen mozgatás, kérlek próbáld újra!');
                    attributesValue.order = index;
                    return;
                }

                //move item phisically in the array
                attributesValues.splice(attributesValue.order, 0, attributesValues.splice(index, 1)[0]);
            });
        };

        vm.updateItem = function (attribute, attributesValue) {
            if (attributesValue.isNew) {
                console.log(vm['attribute_' + attribute.id + '_' + attributesValue.id ? attributesValue.id : 'new' + '_form']);
            }
            else {
                ApiFactory.patchFunction('attributes/' + attribute.id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                    if (typeof(data.message) !== "undefined" && data.message === "cant_save") {
                        alert('Sikertelen mentés, kérlek próbáld újra!');
                    }
                });
            }
        };

        vm.updateItemWithTimeOut = function (attribute, attributesValue) {
            if (typeof(attributesValue.timeOut) !== "undefined" && attributesValue.timeOut) {
                $timeout.cancel(attributesValue.timeOut);
            }
            attributesValue.timeOut = $timeout(function () {

                if (attributesValue.isNew) {
                    console.log(vm['attribute_' + attribute.id + '_' + (attributesValue.id ? attributesValue.id : 'new') + '_form']);
                    if (vm['attribute_' + attribute.id + '_' + (attributesValue.id ? attributesValue.id : 'new') + '_form'].$valid) {
                        ApiFactory.postFunction('attributes/' + attribute.id + '/attributesvalues', attributesValue).then(function (data) {
                            console.log(data);
                            attributesValue.id = data.id;
                            attributesValue.isNew = false;
                            //add the item
                        }).finally(function () {
                            vm.creating = false;
                        });
                    }
                }
                else {
                    ApiFactory.patchFunction('attributes/' + attribute.id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                        if (typeof(data.message) !== "undefined" && data.message === "cant_save") {
                            alert('Sikertelen mentés, kérlek próbáld újra!');
                        }
                    });
                }
            }, 2000);

        };

        vm.createItem = function (parentIndex) {
            vm.creating = true;
            let tmpVal = {
                attribute_id: vm.attributes[parentIndex].id,
                isNew: true,
                order: vm.attributes.length,
                title: ''
            };
            vm.attributes[parentIndex].attributes_values.push(tmpVal);
        };
    }
})();

