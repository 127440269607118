/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('ResetPasswordController', ['$state', '$stateParams', 'PasswordFactory', ResetPasswordController]);

    function ResetPasswordController($state: angular.ui.IStateService, $stateParams, PasswordFactory) {

        let vm: any = this;

        vm.resetPassword = resetPassword;
        vm.resetError = false;
        vm.loginErrorText = '';
        vm.resetErrors = {};

        function resetPassword() {
            var payload = {
                'password': vm.new_password,
                'password_confirmation': vm.re_new_password
            };
            console.log(payload);
            if ($stateParams['user_id'] && $stateParams['token']) {
                PasswordFactory.resetPassword($stateParams['user_id'], $stateParams['token'], payload);
            }
        }

    }
})();
