namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        tileColors: any;
        onUpdate: any;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            tileColors: "<",
            onUpdate: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            existTilecolor: "@",
            existImages: "<",
            disabledAllinput: "<",
            tileTypeRadioBoxes: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/tileUpload.html',
        transclude: true
    };

    function controllerCtrl() {
        let fileId, fullUrl, Url;

        this.tileColorRadioChecked = null;

        this.tileTypeRadioBoxes = [{
            value: 0,
            text: "Látszik",
            class: "little",
            canvasId: "little-canvas",
            bounds: {
                top: 0,
                right: 0,
                bottom: 200,
                left: 200
            },
            cropWidth: 200,
            cropHeight: 200,
            croppedImage: null
        }, {
            value: 1,
            text: "Látszik",
            class: "little",
            canvasId: "little-canvas-1",
            bounds: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            cropWidth: 400,
            cropHeight: 200,
            croppedImage: null
        }, {
            value: 2,
            text: "Látszik",
            class: "half-big",
            canvasId: "half-big-canvas",
            bounds: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            cropWidth: 200,
            cropHeight: 400,
            croppedImage: null
        }];

        this.$onInit = function () {

            this.tileRadioBoxes = [{
                value: 0,
                text: "Nincs csempekép"
            }, {
                value: 1,
                text: "Csempekép"
            }];

            if (!fileId) {
                this.tileRadioChecked = 0;
                this.tileTypeRadioChecked = null;
                if (this.tileColorRadioChecked === null) {
                    this.tileColorRadioChecked = this.tileColors[0];
                }
                this.sourceImage = null;
            }
        };

        this.$postLink = function () {
            if (!fileId) {
                this.onChangeInput();
            }
        };

        this.getCheckedClass = function (checked, value) {
            return (checked !== null && value.toString() === checked.toString()) ? "checked" : "";
        };

        this.getCheckedClassByColors = function (index) {
            return (this.tileColors[index] === this.tileColorRadioChecked) ? "checked" : "";
        };

        this.onChangeInput = function () {
            let croppedImages = [];

            if (this.tileRadioChecked === 0) {
                this.tileTypeRadioChecked = false;
            } else {
                this.tileTypeRadioChecked = true;

                this.tileTypeRadioBoxes.forEach((radios, index) => {
                    let {
                        top,
                        bottom,
                        left,
                        right
                    } = radios.bounds;

                    let pivot = {
                        top: top,
                        bottom: bottom,
                        left: left,
                        right: right,
                        disabled: (this.tileTypeRadioChecked === index) ? true : false,
                        image_id: fileId,
                        article_id: null,
                        type: "tile" + (index + 1)
                    };

                    croppedImages[index] = {
                        url: Url,
                        full_url: fullUrl,
                        id: fileId,
                        pivot: pivot
                    };

                    if (index === 0) {
                        croppedImages[3] = {
                            url: Url,
                            full_url: fullUrl,
                            id: fileId,
                            pivot: {
                                top: top,
                                bottom: bottom,
                                left: left,
                                right: right,
                                disabled: (this.tileTypeRadioChecked === index) ? true : false,
                                image_id: fileId,
                                article_id: null,
                                type: "tile4"
                            }

                        };
                    }
                });
            }

            this.onUpdate({
                tileTypeRadioCheckValue: this.tileTypeRadioChecked,
                tileColorRadioCheckValue: this.tileColorRadioChecked,
                croppedImages: croppedImages,
                fileId: fileId,
                callback: () => {
                    fileId = undefined;
                    delete this.existMessage;
                    delete this.sourceImage;
                    delete this.file;
                }
            });
        };

        this.saveCroppedImages = function () {
            this.onChangeInput();
        };

        this.$onChanges = function (changes) {
            let uploadedFile = changes.uploadedFile;
            let existTilecolor = changes.existTilecolor;
            let existImages = changes.existImages;

            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                let fileProp = uploadedFile.currentValue;

                if (fileProp.type === "tile") {
                    fileId = fileProp.data.id;
                    fullUrl = fileProp.data.full_url;
                    Url = fileProp.data.url;
                    this.existMessage = "Van feltöltve kép";
                    this.tileColorRadioChecked = null;
                }
            }

            if (existTilecolor !== undefined && existTilecolor.isFirstChange()) {
                this.tileColorRadioChecked = existTilecolor.currentValue;
            }

            if (existImages !== undefined && existImages.isFirstChange()) {
                let values = existImages.currentValue;
                if (values !== undefined && values.length > 0) {
                    values.forEach((value, index) => {
                        if (value.pivot.type !== "cover") {
                            if (value.pivot.type !== 'tile4') {
                                this.tileTypeRadioBoxes[index].bounds = {
                                    top: value.pivot.top,
                                    left: value.pivot.left,
                                    right: value.pivot.right,
                                    bottom: value.pivot.bottom
                                };
                            } else if (!fileId) {
                                this.setSourceImage(value);
                            }
                        }
                    });
                }
            }
        };

        this.setSourceImage = function (image) {
            fileId = image.id;
            fullUrl = image.full_url;
            Url = image.url;
            this.tileRadioChecked = 1;
            this.tileColorRadioChecked = null;
            this.existMessage = "Van feltöltve kép";
            this.file = {
                name: fullUrl.split('/').reverse()[0]
            };
            this.toDataUrl(image.full_url, (img) => {
                this.sourceImage = img;
            }, 'image/jpeg');
        };

        this.onClickRemoveBtn = function (event: Event) {
            event.preventDefault();

            this.deleteFile(fileId, () => {
                delete this.sourceImage;
                delete this.file;
                fileId = undefined;
                delete this.existMessage;
                this.tileRadioChecked = 0;
            });
        };

        this.toDataUrl = function (src, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas: any = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
                img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = src;
            }
        };
    }

    angular
        .module('Yelon')
        .component('tileUpload', component);
}
