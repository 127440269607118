/**
 * Created by danielszenasi on 05/09/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').factory('ChatRatingModalFactory', ['$uibModal', ModalFactory]);

    function ModalFactory($uibModal) {
        return {
            openModal: openModal,
        };

        function openModal(data) {
            var defaultLabels = {
                title: 'Kérjük, hogy értékeld a beszélgetést',
                ok: 'Értékelés küldése',
                cancel: false
            };
            data = angular.extend({}, defaultLabels, data || {});

            $uibModal.open({
                windowClass: 'chat-rating',
                templateUrl: 'app/layouts/chat-rating-modal.html',
                controller: 'chatRatingModalController',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
        }
    }

    angular.module('Yelon').controller('chatRatingModalController', ['$scope', '$uibModalInstance', 'data','$http', 'ApiFactory', chatRatingModalController]);

    function chatRatingModalController($scope:angular.IScope, $uibModalInstance, data, $http, ApiFactory) {
        $scope['data'] = angular.copy(data);
        $scope['SelectedTopics'] = [];

        ApiFactory.getFunction('chattopics').then(function (data) {
            $scope['topics'] = data;
        }).finally(function() {

        });
        $scope['selectedTopics'] = {
            ids: {}
        };

        $scope['ok'] = function (closeMessage) {
            ApiFactory.postFunction('chattopics', { }).then(function (data) {

            }).finally(function() {

            });

            $uibModalInstance.close(closeMessage);

        };
        $scope['cancel'] = function (dismissMessage) {
            if (angular.isUndefined(dismissMessage)) {
                dismissMessage = 'cancel';
            }
            $uibModalInstance.dismiss(dismissMessage);
        };
    }
})();
