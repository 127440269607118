/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';

    angular.module('Yelon').service('SidebarService', ['$rootScope', function ($rootScope) {

        var service = this;

        service.sidebarItems = [];
        service.isDisabled = true;
        $rootScope.isSidebarDisabled = service.isDisabled;

        // presets
        service.presets = {
            'main': [
                {
                    title: 'Chat beszélgetések',
                    state: 'chat',
                },
                {
                    title: 'Bejegyzések',
                    state: 'articles',
                },
                {
                    title: 'Archivált beszélgetések',
                    state: 'archivedchatconv',
                },
                {
                    title: 'Fix elemek az oldalon',
                    state: 'pages',
                },
                {
                    title: 'Chat beállítások',
                    state: 'chat-settings',
                },
                {
                    title: "Értesítés küldés",
                    isPopup: true
                },
                {
                    title: 'Felhasználók',
                    state: 'users',
                },
            ],
            'supervisor': [
                {
                    title: 'Chat beszélgetések',
                    state: 'chat',
                },
                {
                    title: 'Archivált beszélgetések',
                    state: 'archivedchatconv',
                },
                {
                    title: 'Chat beállítások',
                    state: 'chat-settings',
                },
                {
                    title: "Értesítés küldés",
                    isPopup: true
                }
            ],
            'superadmin': [
                {
                    title: 'Bejegyzések',
                    state: 'articles',
                },
                {
                    title: 'Archivált beszélgetések',
                    state: 'archivedchatconv',
                },
                {
                    title: 'Fix elemek az oldalon',
                    state: 'pages',
                },
                {
                    title: 'Chat beállítások',
                    state: 'chat-settings',
                },
                {
                    title: "Értesítés küldés",
                    isPopup: true
                },
                {
                    title: 'Felhasználók',
                    state: 'users',
                },
            ],
            'helper': [
                {
                    title: 'Chat beszélgetések',
                    state: 'chat',
                }
            ],
            'blogger' : [
                {
                    title: 'Bejegyzések',
                    state: 'articles',
                }
            ],
            'articles': [
                {
                    title: 'Webes bejegyzések',
                    state: 'articles-web',
                },
                {
                    title: 'Mobil App bejegyzések',
                    state: 'articles-mobile',
                },
                {
                    title: 'Felnőtt bejegyzések',
                    state: 'articles-adult',
                }
            ],
            'articles-web': [
                {
                    title: 'Gyerek oldal bejegyzései',
                    state: 'articles-web',
                },
                {
                    title: 'Mobilapplikáció bejegyzései',
                    state: 'articles-mobile',
                },
                {
                    title: 'Felnőtt oldal bejegyzései',
                    state: 'articles-adult',
                },
                {
                    title: 'Fix bejegyzések',
                    state: 'articles-fix',
                },
                {
                    title: 'Tagek szerkesztése',
                    state: 'articles-web-tags',
                },
                {
                    title: 'Kategóriák szerkesztése',
                    state: 'articles-web-categories',
                },
            ],
            'pages': [
                {
                    title: 'GYIK + szótár szerkesztése',
                    state: 'pages-faq',
                },
                {
                    title: 'Chat fix fül (gyerek), yelonról fül (felnőtt)',
                    state: 'pages-helpers',
                },
                {
                    title: 'Rólunk',
                    state: 'pages-about',
                },
                {
                    title: 'Mobilapplikáció fix fül',
                    state: 'pages-app',
                },
            ],
            'chat-settings': [
                {
                    title: 'Automatikus üzenetek szerkesztése',
                    state: 'chat-settings-automessages',
                },
                {
                    title: 'Témák szerkesztése',
                    state: 'chat-settings-topics',
                },
                {
                    title: 'Nyitvatartás',
                    state: 'chat-settings-businesshours',
                },
            ],
            'faq': [
                {
                    title: 'Lista nézet',
                    state: 'pages-faq',
                },
                {
                    title: 'Új elem hozzáadása',
                    state: 'pages-faq-add',
                },
                {
                    title: 'Kategória szerkesztés',
                    state: 'pages-faq-categories',
                },
            ],
        };

        // load a preset
        service.loadPreset = function (index) {
            if ($rootScope.currentUser.role === "superadmin" && index === "main") {
                service.sidebarItems = service.presets[$rootScope.currentUser.role];
            } else {
                service.sidebarItems = service.presets[index];
            }

        };

        // enable sidebar
        service.enable = function () {
            service.isDisabled = false;
            $rootScope.isSidebarDisabled = service.isDisabled;
        };

        // remove all menu bar items
        service.clearSidebarItems = function () {
            service.sidebarItems = [];
            service.isDisabled = true;
            $rootScope.isSidebarDisabled = service.isDisabled;
        };

        // overview menu items
        service.overviewSidebarItems = function (items) {
            service.clearSidebarItems();
            angular.forEach(items, function (item) {
                service.sidebarItems.push(item);
            });
        };
    }]);
})();
