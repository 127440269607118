(function () {
    'use strict';

    angular.module('Yelon').factory('ApiFactory', ['$http', '$q', 'apiPrefix', '$rootScope', 'FileSaver', 'Blob', ApiFactory]);

    function ApiFactory($http: angular.IHttpService, $q: angular.IQService, apiPrefix, $rootScope, FileSaver, Blob) {
        return {
            getFunction: getFunction,
            downloadFunction: downloadFunction,
            postFunction: postFunction,
            patchFunction: patchFunction,
            deleteFunction: deleteFunction,
        };

        function getFunction(url: string) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }

            return $http.get(apiPrefix + url).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function downloadFunction(url: string) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }

            return $http.get(apiPrefix + url, { responseType: 'arraybuffer' }).success(success).catch(returnError);

            function success(data, status, headers) {
                let header = headers('Content-Disposition'),
                    filename = url.split('/').reverse()[0];

                if (header) {
                    header = header.split(';')[1].trim().split('=')[1].replace(new RegExp('"', 'g'), '');
                    if (header.length > 0) {
                        filename = header;
                        console.log(filename);
                    }
                }

                let blob = new Blob([data], { type: headers('Content-Type') });
                FileSaver.saveAs(blob, filename);

                return data.data;
            }

            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function postFunction(url: string, data: any, settings: any) {
            return $http.post(apiPrefix + url, data, settings).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function patchFunction(url: string, data: any, settings: any) {
            return $http.patch(apiPrefix + url, data, settings).then(success).catch(returnError);

            function success() {
                $rootScope.lastSave = new Date();
                return true;
            }

            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen módosítás, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        function deleteFunction(url: string) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }

            return $http.delete(apiPrefix + url).then(success).catch(returnError);

            function success(data) {
                return angular.fromJson(data.data);
            }

            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen törlés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }

        /**
         *
         * @param response
         * @returns {boolean} should the caller show the alert window?
         */
        function handleError(response) {
            if (response.status === 500) {
                return true;
            }

            //expired token
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    alert('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }

            //other message
            if (typeof(response.data) !== "undefined" && response.data) {
                if (typeof(response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }

            return true;
        }
    }
})();
