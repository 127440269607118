namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        onAddEmojiIcon: any;
        session?: ChatSession;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            onAddEmojiIcon: '<',
            session: '='
        },
        controller: controllerCtrl,
        templateUrl: 'app/Emojipicker/template.html',
        transclude: true
    };

    function controllerCtrl() {
        this.$onInit = () => {
            this.emoji_icons = [":grinning:", ":grimacing:", ":grin:", ":joy:", ":smiley:", ":smile:", ":sweat_smile:", ":laughing:", ":innocent:", ":wink:", ":blush:", ":relaxed:", ":yum:", ":relieved:", ":heart_eyes:", ":kissing_heart:", ":kissing:", ":kissing_smiling_eyes:", ":kissing_closed_eyes:", ":stuck_out_tongue_winking_eye:", ":stuck_out_tongue_closed_eyes:", ":stuck_out_tongue:", ":sunglasses:", ":no_mouth:", ":pensive:", ":confused:", ":cry:"];
        };
    }

    angular
        .module('Yelon')
        .component('emojiPicker', component);
}
