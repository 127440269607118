/**
 * Created by ekema on 2016. 01. 29..
 */
/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';

    angular.module('Yelon').config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$authProvider', '$httpProvider', '$provide', 'apiPrefix', 'apiGeneralPrefix', function ($stateProvider, $urlRouterProvider, $locationProvider, $authProvider, $httpProvider, $provide, apiPrefix, apiGeneralPrefix) {

        $authProvider.loginUrl = apiGeneralPrefix + 'authenticate';
        //$authProvider.signupUrl = apiPrefix + 'register';

        $stateProvider
            .state('home', {
                url: '/',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                authenticate: 1,
                rolesAllowed: ["helper", "blogger", "supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Főoldal'
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles', {
                url: '/articles',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': () => {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Bejegyzések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('archivedchatconv', {
                url: '/archivedchatconv',
                templateUrl: 'app/ArchivedChatConv/archived_chat_conv.html',
                controller: 'ArchivedChatConvController as archivedChatConvCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Archivált beszélgetések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('archivedchat', {
                url: '/archivedchat/{id}',
                templateUrl: 'app/ArchivedChatConv/archivedchat.html',
                controller: 'ArchivedChatController as archiveChatCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Archivált beszélgetés',
                    parent: 'archivedchatconv',
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles-create', {
                url: '/articles/create/{type}/{mainType}',
                templateUrl: 'app/Articles/edit.html',
                controller: 'ArticleEditController as articleEditCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: '{{breadCrumbText}}',
                    parent: 'articles',
                },
                data: {
                    pageId: "article-editor"
                }
            })
            .state('articles-create.created', {
                url: "^/articles/create/{type}/{mainType}/{id}",
                data: {
                    pageId: "article-editor",
                    isCreated: true
                }
            })
            .state('articles-edit', {
                url: '/articles/edit/{type}/{mainType}/{id}',
                templateUrl: 'app/Articles/edit.html',
                controller: 'ArticleEditController as articleEditCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: '{{breadCrumbText}}',
                    parent: 'articles',
                },
                data: {
                    pageId: "article-editor"
                }
            })
            .state('articles-web', {
                url: '/articles/web',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': () => {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Webes bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles-fix', {
                url: '/articles/fix',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': () => {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Fix bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: "",
                    type: "fix"
                }
            })
            .state('articles-mobile', {
                url: '/articles/mobile',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': () => {
                        return 'mobile';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Mobile App bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles-adult', {
                url: '/articles/adult',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': () => {
                        return 'adult';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Felnőtt bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles-web-tags', {
                url: '/articles/web/tags',
                templateUrl: 'app/Articles/Tags/tags.html',
                controller: 'TagController as tagCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Tagek szerkesztése',
                    parent: 'articles-web',
                },
                data: {
                    pageId: ""
                }
            })
            .state('articles-web-categories', {
                url: '/articles/web/categories',
                templateUrl: 'app/Articles/Categories/categories.html',
                controller: 'CategoryController as categoryCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Kategóriák szerkesztése',
                    parent: 'articles-web',
                },
                data: {
                    pageId: ""
                }
            })
            .state('login', {
                url: '/login',
                templateUrl: 'app/Auth/login.html',
                controller: 'LoginController as login',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                authenticate: 2,
                data: {
                    pageId: ""
                }
            })
            .state('pages', {
                url: '/pages',
                controller: 'PagesController as pagesCtrl',
                templateUrl: 'app/Pages/index.html',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Fix oldalak',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('users', {
                url: '/users',
                controller: 'UsersController as usersCtrl',
                templateUrl: 'app/Users/index.html',
                rolesAllowed: ["superadmin"],
                authenticate: 1,
                ncyBreadcrumb: {
                    label: 'Felhasználók',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('newpassword', {
                url: '/newpassword',
                controller: 'NewPasswordController as newPasswordCtrl',
                templateUrl: 'app/Users/new_password.html',
                authenticate: 1,
                ncyBreadcrumb: {
                    label: 'Saját profil',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-faq', {
                url: '/pages/faq',
                controller: 'FAQController as faqCtrl',
                templateUrl: 'app/Pages/FAQ/index.html',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'GYIK',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-faq-categories', {
                url: '/pages/faq/categories',
                templateUrl: 'app/Pages/FAQ/categories.html',
                controller: 'FAQCategoriesController as faqCategoriesCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Kategóriák szerkesztése',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-faq-add', {
                url: '/pages/faq/add',
                templateUrl: 'app/Pages/FAQ/edit.html',
                controller: 'FAQEditController as faqEditCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Új elem hozzáadása',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-faq-edit', {
                url: '/pages/faq/edit/{id}',
                templateUrl: 'app/Pages/FAQ/edit.html',
                controller: 'FAQEditController as faqEditCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Elem szerkesztése',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-about', {
                url: '/pages/about',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': () => {
                        return 'about';
                    },
                    'title': () => {
                        return 'Rólunk';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Rólunk',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-app', {
                url: '/pages/application',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': () => {
                        return 'application';
                    },
                    'title': () => {
                        return 'Az applikáció';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Az applikáció',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
            .state('pages-helpers', {
                url: '/pages/helpers',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': () => {
                        return 'helpers';
                    },
                    'title': () => {
                        return 'Chat ügyelők';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Chat ügyelők',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
            .state('chat', {
                url: '/chat',
                templateUrl: 'app/Chat/chatoverview.html',
                controller: 'ChatController as chatCtrl',
                authenticate: 1,
                rolesAllowed: ["helper", "supervisor"],
                ncyBreadcrumb: {
                    label: 'Chat beszélgetések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('chat-settings', {
                url: '/chat-settings',
                templateUrl: 'app/ChatSettings/auto_messages.html',
                controller: 'AutoMessagesController as autoMessagesCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Chat beállítások',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
            .state('chat-settings-topics', {
                url: '/chat-settings/topics',
                templateUrl: 'app/ChatSettings/chat_topics.html',
                controller: 'ChatTopicsController as chatTopicsCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Témák szerkesztése',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
            .state('chat-settings-automessages', {
                url: '/chat-settings/automessages',
                templateUrl: 'app/ChatSettings/auto_messages.html',
                controller: 'AutoMessagesController as autoMessagesCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Automatikus üzenetek',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
            .state('chat-settings-businesshours', {
                url: '/chat-settings/businesshours',
                templateUrl: 'app/ChatSettings/business_hours.html',
                controller: 'BusinessHoursController as businessHoursCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Nyitvatartás',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
            .state('resetPassword', {
                url: '/resetpassword/{user_id}/{token}',
                templateUrl: 'app/Auth/resetpassword.html',
                controller: 'ResetPasswordController as resetPasswordCtrl',
                authenticate: 2,
                ncyBreadcrumb: {
                    skip: true,
                },
                data: {
                    pageId: ""
                }
            })

            /*.state('reset', {
             url: '/reset/:token',
             params: {
             token: null
             },
             templateUrl: '/app/Auth/reset.html',
             controller: 'ResetController as reset',
             authenticate: 2
             })
             .state('resetemail', {
             url: '/resetemail',
             templateUrl: '/app/Auth/reset-get.html',
             controller: 'ResetGetController as reset',
             authenticate: 2
             })*/
            /* Otherwise */
            .state("otherwise", {
                url: "*path",
                template: "Invalid Location",
                controller: [
                    '$state', '$scope',
                    function ($state: angular.ui.IStateService, $scope: angular.IScope) {
                        $scope['goHome'] = function () {
                            $state.go('home');
                        };
                    }]
            });

        $locationProvider.html5Mode(true);

    }]);

})();
