declare var emojione:any;

(function () {
    'use strict';

    angular.module('Yelon')
        .filter('emojione', ['$sce', ($sce) => {
            return (input) => {
                input = $sce.valueOf(input);

                if (typeof input !== 'string') {
                    return input;
                }

                return emojione.unicodeToImage(
                    input
                        .replace(/&/g, "&amp;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#039;")
                );
            };
        }]);
})();
