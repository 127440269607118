/**
 * Created by fures on 2016. 08. 18..
 */
(function () {
    'use strict';

    angular.module('Yelon').
    filter('commaList', function() {
        return function(elements, property) {
            let array = [];
            angular.forEach(elements, function (element, key) {
                array.push(element[property]);
            });
            return array.join(", ");
        };
    });
})();
