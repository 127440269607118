namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        title: string;
        subTitle: string;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            title: "=",
            subTitle: "=",
            requireTitle: "=",
            disabledAllinput: "<",
            isMotto: "@",
            updateShow: "<",
            currentShow: "@"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/titles.html',
        transclude: true
    };

    function controllerCtrl() {
        this.$onChanges = function(changes) {
            let currentShow = changes.currentShow;

            if (currentShow && !currentShow.isFirstChange() && this.showValue === undefined) {
                this.showValue = currentShow.currentValue;
            }
        };

        this.onBlur = function(event) {

            let value = event.currentTarget.value;

            if (value !== "") {
                this.requireTitle = false;
            } else {
                this.requireTitle = true;
            }

            this.title = value;
        };

        this.showTitle = function() {
            let newValue;

            if (this.disabledAllinput) {
                return;
            }

            if (this.showValue) {
                newValue = false;
            } else {
                newValue = true;
            }

            this.showValue = newValue;

            this.updateShow(newValue);
        };
    }

    angular
        .module('Yelon')
        .component('titlesInput', component);
}
