/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('ArticleController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', '$state', 'mainType', ArticleController]);

    function ArticleController(ApiFactory, SidebarService, spinnerService, $filter: angular.IFilterService, $scope: angular.IScope, $confirm, $state: angular.ui.IStateService, mainType: string) {
        let vm: any = this;
        if (mainType === 'web') {
            SidebarService.loadPreset('articles-web');
            vm.pageTitle = 'Webes bejegyzések';
        } else {
            SidebarService.loadPreset('articles');
            vm.pageTitle = mainType === 'adult' ? 'Felnőtt bejegyzések' : 'Mobil bejegyzések';
        }
        vm.mainType = mainType;
        SidebarService.enable();
        let orderBy = $filter('orderBy');
        vm.articles = [];
        vm.selectedValues = {};
        vm.attributes = {};
        vm.order = order;
        vm.to = null;
        vm.lastPage = 10;
        vm.predicate = 'title';
        vm.reverse = true;
        vm.filteredArticles = [];
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.searchString = "";
        vm.newArticleType = "article";
        vm.type = ($state.current.data.type) ? $state.current.data.type : null;
        vm.articlePageType = "article";

        if (vm.type === "fix") {
            vm.pageTitle = "Fix bejegyzések";
            vm.newArticleType = vm.type;
            vm.articlePageType = "fix";
        }

        vm.filter = filter;

        let _defaultOrder;
        let previousArticleQuery: any = JSON.parse(window.localStorage.getItem("previous_article_query")) || null;

        window.localStorage.setItem("currentStateName", $state.current.name);

        init();

        function init() {
            if (mainType === 'web') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('attributes').then(function (data) {
                    vm.attributes = data;
                    for (var i = 0; i < vm.attributes.length; i++) {
                        vm.selectedValues[i] = [];
                    }
                }).finally(() => {
                    spinnerService.decrementLoading();
                    vm.filter("updated_at");
                });
            } else {
                vm.filter("updated_at");
            }
        }

        vm.onClickFilterRemoveBtn = function() {
            previousArticleQuery = null;
            vm.type = null;
            vm.selectedValues = {};
            vm.attributes = {};
            vm.searchString = "";
            vm.reverse = true;
            init();
        };

        vm.publishedInFuture = function (article) {
            var nowDate = new Date();
            var pubDate = new Date(article.published_at);
            return nowDate < pubDate;
        };

        function filter(defaultOrder = null) {

            if (previousArticleQuery !== null) {
                defaultOrder = previousArticleQuery.defaultOrder;
                vm.selectedValues = previousArticleQuery.selectedValues;
                vm.searchString = previousArticleQuery.searchString;
            }

            spinnerService.incrementLoading();
            let queryParams = '?main_type=' + mainType;
            if (typeof vm.type === 'string' && vm.type.length > 0) {
                queryParams += '&type=' + vm.type;
            }

            if (vm.type === "fix") {
                queryParams = '?type=' + vm.type;
            }

            queryParams += vm.searchString.trim().length > 0 ? ('&q=' + vm.searchString) : '';
            if (mainType === 'web') {
                angular.forEach(vm.selectedValues, (value => {
                    angular.forEach(value, attr => {
                        queryParams += '&attributes[]=' + attr.id;
                    });
                }));
            }
            ApiFactory.getFunction('articles' + queryParams).then(function (data) {
                vm.articles = data;
                vm.currentPage = (previousArticleQuery !== null) ?  previousArticleQuery.currentPage : 1;
                if (defaultOrder === null) {
                    order('title');
                } else {
                    vm.reverse = (previousArticleQuery !== null) ? !previousArticleQuery.reverse : false;
                    vm.predicate = defaultOrder;
                    order(defaultOrder);
                }
                paginate(true);
            }).finally(() => {
                spinnerService.decrementLoading();
            });
        }

        function saveQueryConfig(defaultOrder) {
            previousArticleQuery = null;

            window.localStorage.setItem("previous_article_query", JSON.stringify({
                selectedValues: vm.selectedValues,
                searchString: vm.searchString,
                type: vm.type,
                currentPage: vm.currentPage,
                defaultOrder: defaultOrder,
                reverse: vm.reverse
            }));
        };

        function paginate(emptyAllowed = false) {
            if (!emptyAllowed && vm.articles.length === 0) {
                return;
            }
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;
            vm.filteredArticles = vm.articles.slice(begin, end);

            saveQueryConfig(_defaultOrder);
        }

        $scope.$watch("articleCtrl.currentPage", paginate);

        function order(predicate: string) {

            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.articles = orderBy(vm.articles, predicate, vm.reverse);

            _defaultOrder = predicate;

            paginate();
        }

        //delete item
        vm.deleteItem = function (article) {
            $confirm({
                    title: 'Megerősítés',
                    text: 'Biztosan törli a "' + article.title + '" elemet?'
                })
                .then(function () {
                    var delIndex = vm.articles.indexOf(article);
                    ApiFactory.deleteFunction('articles/' + article.id).then(function (data) {
                        vm.articles.splice(delIndex, 1);
                        vm.filter("updated_at");
                    });
                });
        };

        vm.createArticle = function () {
            $state.go('articles-create', {
                mainType: mainType,
                type: vm.newArticleType
            });
        };

        vm.archiveItem = function (article, archived) {
            if (archived) {
                $confirm({
                        title: 'Megerősítés',
                        text: 'Biztosan archiválja a "' + article.title + '" elemet?'
                    })
                    .then(function () {
                        article.archived = true;
                        ApiFactory.patchFunction('articles/' + article.id, article);
                    });
            } else {
                article.archived = false;
                ApiFactory.patchFunction('articles/' + article.id, article);
            }
        };
    }
})();
