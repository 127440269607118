import IRootScopeService = angular.IRootScopeService;
/**
 * Created by danielszenasi on 05/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').factory('LoginFactory', ['$http', '$auth', 'ChatFactory', '$rootScope', '$q', 'apiPrefix', 'apiGeneralPrefix', LoginFactory]);

    function LoginFactory($http:angular.IHttpService, $auth, ChatFactory, $rootScope:IRootScopeService, $q:angular.IQService, apiPrefix, apiGeneralPrefix) {
        return {
            init: init,
            loginFunction: loginFunction,
            refreshUserFunction: refreshUserFunction,
            refreshTokenFunction: refreshTokenFunction,
            getLoginImagesFunction: getLoginImagesFunction
        };

        function init() {
            // refresh token every 10 minutes
            setInterval(refreshTokenFunction, 600000);
        }

        function loginFunction(credentials) {
            return $auth.login(credentials).then(getUser).catch(returnError);

            function getUser() {
                return refreshUserFunction().then(function () {
                    $rootScope.$emit("login", {});
                });
            }

            function returnError(response) {
                return $q.reject({
                    loginError: true,
                    loginErrorText: response.data.error
                });
            }
        }

        function refreshUserFunction() {
            return $http.get(apiGeneralPrefix + 'authenticate/user').success(function (response: any) {
                var user = JSON.stringify(response.user);
                // Set the stringified user data into local storage
                localStorage.setItem('user', user);
                $rootScope['authenticated'] = true;
                $rootScope['currentUser'] = response.user;

                if (response.user !== null && (response.user.role === 'supervisor' || response.user.role === 'helper')) {
                    ChatFactory.init();
                }
            });
        }

        function refreshTokenFunction() {
            if ($auth.isAuthenticated()) {
                return $http.get(apiGeneralPrefix + 'authenticate/refresh').success(function (response: any) {
                    $auth.setToken(response.token);
                }).catch(() => {
                    $auth.logout();
                });
            }

            return $q.resolve();
        }

        function getLoginImagesFunction() {
            return $http.get(apiPrefix + 'loginimages/get').then(function (response: any) {
                var images = [];
                var temp = angular.fromJson(response.data);
                for (var i = 0; i !== temp.length; i++) {
                    images[i] = {url: temp[i]};
                }
                return images;
            });
        }

    }
})();
