/**
 * Created by fures on 2016. 08. 10..
 */
//import IRootScopeService = angular.IRootScopeService;
(function () {
    'use strict';

    angular.module('Yelon').directive('sidebar', ['$rootScope', sidebar]);

    function sidebar($rootScope:IRootScopeService) {

        return {
            restrict: 'A',
            scope: false,
            replace: true,
            templateUrl: "app/layouts/sidebar.html",
            controller: ['$scope', 'SidebarService', function ($scope:angular.IScope, SidebarService) {

                $scope['sidebarItemService'] = SidebarService;

                $rootScope.$on('$stateChangeStart', function () {
                    $scope['sidebarItemService'].clearSidebarItems();
                });

                $scope["onClickPopupItem"] = function(event) {
                    event.preventDefault();

                    $rootScope.$emit("onClickedPopup");
                };
            }],
            link: function () {

            }
        };
    }
})();
