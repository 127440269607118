namespace Yelon {
    'use strict';

    export interface IComponentBindings {
        paragraphs: any;
    }
    const component: ng.IComponentOptions = {
        bindings: {
            onUpdate: "<",
            paragraphs: "=",
            updateParagraph: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            deleteParagraph: "<",
            existParagraphs: "<",
            articleType: "@",
            updateOrder: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/blocks.html',
        transclude: true
    };

    function controllerCtrl() {
        let timer;
        let self = this;
        let prev;

        this.additionalButtons = [
            [{
                name: "groupFont",
                data: [{
                    name: "cmdStrikethrough",
                    toggle: false,
                    title: "Strikethrough",
                    icon: {
                        fa: "fa fa-strikethrough",
                        glyph: "glyphicon glyphicon-minus"
                    },
                    callback: function (e) {
                        // Give/remove ~~ surround the selection
                        var chunk, cursor, selected = e.getSelection(),
                            content = e.getContent();

                        if (selected.length === 0) {
                            // Give extra word
                            chunk = e.__localize('strikethrough');
                        } else {
                            chunk = selected.text;
                        }

                        // transform selection and set the cursor into chunked text
                        if (content.substr(selected.start - 2, 2) === '~~' &&
                            content.substr(selected.end, 2) === '~~') {
                            e.setSelection(selected.start - 2, selected.end + 2);
                            e.replaceSelection(chunk);
                            cursor = selected.start - 2;
                        } else {
                            e.replaceSelection('~~' + chunk + '~~');
                            cursor = selected.start + 2;
                        }

                        // Set the cursor
                        e.setSelection(cursor, cursor + chunk.length);
                    }
                }]
            }, {
                name: "groupMisc",
                data: [{
                    name: "cmdTable",
                    toggle: false,
                    title: "Table",
                    icon: {
                        fa: "fa fa-table",
                        glyph: "glyphicon glyphicon-th"
                    },
                    callback: function (e) {
                        // Replace selection with some drinks
                        var cursor,
                            selected = e.getSelection(),
                            content = e.getContent(),
                            chunk = "\n| Tables        | Are           | Cool  | \n" +
                            "| ------------- |:-------------:| -----:| \n" +
                            "| col 3 is      | right-aligned | $1600 | \n" +
                            "| col 2 is      | centered      |   $12 | \n" +
                            "| zebra stripes | are neat      |    $1 |";

                        // transform selection and set the cursor into chunked text
                        e.replaceSelection(chunk);
                        cursor = selected.start;

                        // Set the cursor
                        e.setSelection(cursor, cursor + chunk.length);
                    }
                }]
            }, {
                name: "groupMisc",
                data: [{
                    name: "cmdContinue",
                    toggle: false,
                    title: "Continue",
                    icon: {
                        fa: "fa fa-ellipsis-h"
                    },
                    callback: function(e) {
                        let cursor,
                            selected = e.getSelection(),
                            content = e.getContent(),
                            chunk = "---more---";

                        e.replaceSelection(chunk);
                        cursor = selected.start;

                        e.setSelection(cursor, cursor + chunk.length);
                    }
                }]
            }]
        ];

        this.$onInit = function () {
            this.addedBlocks = [];
            this.files = [];
            this.images = {};

            this.blocks = [{
                    title: "Sima szöveg",
                    type: "text",
                    enable: (this.articleType === "gallery") ? false : true
                }, {
                    title: "Kiemelt szöveg",
                    type: "highlight",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Kép",
                    type: "image",
                    enable: (this.articleType === "video" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Youtube videó",
                    type: "video",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                },
                /*{
                               title: "Galéria",
                               type: "gallery",
                               enable: (this.articleType === "video" || this.articleType === "quiz") ? false : true
                           },*/
                {
                    title: "Kvíz Html",
                    type: "iframe",
                    enable: (this.articleType === "quiz") ? true : false
                }, {
                    title: "Kvíz Script",
                    type: "iframe2",
                    enable: (this.articleType === "quiz") ? true : false
                }, {
                    title: "SoundCloud",
                    type: "soundcloud",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Instagram",
                    type: "instagram",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "tumblr",
                    type: "tumblr",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }
            ];
        };

        this.onClickRollBtn = function (clickIndex) {

            if (this.addedBlocks[parseInt(clickIndex)].rolledClass) {
                delete this.addedBlocks[parseInt(clickIndex)].rolledClass;
            } else {
                this.addedBlocks[parseInt(clickIndex)].rolledClass = "rolled-down";
            }

        };

        this.dragEnd = function () {
            this.onUpdate(this.paragraphs);
        };

        this.getContainerClass = function (index) {
            return (this.addedBlocks[parseInt(index)] && this.addedBlocks[parseInt(index)].rolledClass) ? this.addedBlocks[parseInt(index)].rolledClass : "";
        };

        this.changeBlockType = function () {
            let {
                type,
                content,
                order,
                image_id
            } = this.currentBlock;

            if (content === undefined) {
                content = "";
            }

            order = null;
            image_id = null;

            this.addedBlocks.push(this.currentBlock);

            if (!this.paragraphs) {
                this.paragraphs = [{
                    type,
                    content,
                    order,
                    image_id
                }];
            } else {
                this.paragraphs.push({
                    type,
                    content,
                    order,
                    image_id
                });
            }

        };

        this.onAddBtnClick = function () {
            let {
                type,
                content,
                order,
                image_id,
                title
            } = this.currentBlock;

            if (content === undefined) {
                content = "";
            }

            order = null;
            image_id = null;

            if (this.paragraphs.length === 0 && this.addedBlocks.length > 0) {
                this.paragraphs = [];
                this.addedBlocks = [];
            }

            this.addedBlocks.push({
                title
            });
            this.paragraphs.push({
                type,
                content,
                order,
                image_id
            });
        };

        this.onClickRemoveBlockBtn = function (index) {

            this.deleteParagraph(this.paragraphs[index], () => {
                this.addedBlocks.splice(index, 1);

                if (this.images[index] !== undefined) {
                    this.onClickRemoveBtn(index);
                }
            });
        };

        this.onClickRemoveBtn = function (index) {
            this.deleteFile(this.images[index].id, () => {
                delete this.images[index];
                delete this.files[index];
                this.paragraphs[index].image_id = null;
                this.paragraphs[index].image = null;
                this.updateParagraph(this.paragraphs[index]);
            });
            this.files.splice(index, 1);
        };

        this.onChangeEditor = function (paragraph) {

            if (paragraph.id) {
                if (timer !== undefined) {
                    window.clearTimeout(timer);
                    timer = undefined;
                }

                timer = window.setTimeout(() => {
                    this.updateParagraph(paragraph);
                }, 2000);
            }
        };

        this.onClickArrow = function (currentBlock, isUp, index) {

            let movedIndex;
            let moveBlock;
            let addBlock = this.addedBlocks[index];
            let moveParagraph;
            let currentImage;
            let moveImage;
            let currentFile;
            let moveFile;

            if (this.images[index]) {
                currentImage = this.images[index];
            }

            if (this.files[index]) {
                currentFile = this.files[index];
            }

            if (isUp) {
                movedIndex = index - 1;
            } else {
                movedIndex = index + 1;
            }

            if (movedIndex < 0) {
                movedIndex = this.addedBlocks.length - 1;
            } else if (movedIndex > this.addedBlocks.length - 1) {
                movedIndex = 0;
            }

            moveBlock = this.addedBlocks[movedIndex];
            moveParagraph = this.paragraphs[movedIndex];

            if (this.images[movedIndex]) {
                moveImage = this.images[movedIndex];
            }

            if (this.files[movedIndex]) {
                moveFile = this.files[moveFile];
            }

            this.paragraphs[movedIndex] = currentBlock;
            this.paragraphs[index] = moveParagraph;

            this.addedBlocks[movedIndex] = addBlock;
            this.addedBlocks[index] = moveBlock;

            if (currentImage) {
                this.images[movedIndex] = currentImage;
            }

            if (moveImage) {
                this.images[index] = moveImage;
            }

            if (currentFile) {
                this.files[movedIndex] = currentFile;
            }

            if (moveFile) {
                this.files[index] = moveFile;
            }

            this.updateOrder(currentBlock, movedIndex);
        };

        this.$onChanges = function (changes) {
            let uploadedFile = changes.uploadedFile;
            let existParagraphs = changes.existParagraphs;

            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                let fileProp = uploadedFile.currentValue;

                if (fileProp.type === "paragraph") {
                    this.images[fileProp.index] = {
                        url: fileProp.data.full_url,
                        id: fileProp.data.id
                    };

                    console.log('Image uploaded');
                    console.log(fileProp);
                }
            }

            if (existParagraphs !== undefined && !existParagraphs.isFirstChange()) {
                let values = existParagraphs.currentValue;

                this.paragraphs = null;

                values.forEach((value, mainIndex) => {
                    this.blocks.forEach((block, index) => {
                        if (value.type === block.type) {
                            let title = block.title;

                            this.addedBlocks.push({
                                title
                            });
                            if (value.type === "image" && value.image) {
                                console.log('Image change');
                                console.log(value);
                                this.images[mainIndex] = {
                                    url: value.image.full_url,
                                    id: value.image.id
                                };

                                if (!this.files[mainIndex]) {
                                    this.files[mainIndex] = {
                                        name: value.image.url.split('/').reverse()[0]
                                    };
                                } else {
                                    this.files[mainIndex].name = value.image.url.split('/').reverse()[0];
                                }

                                console.log(this.files[mainIndex]);
                            }
                        }
                    });
                });

                this.paragraphs = values;
            }
        };
    }

    angular
        .module('Yelon')
        .component('blocksInput', component);
}
