type ChatSessionEventTypes = "handover" | "end" | "join" | "leave" | "message" | "typing" | "archive"
    | "userTyping" | "sendingMessage" | "closing" | "takeover" | "giveback";

class ChatSession {
    private handlers = {};
    private id: string;
    private clients: Array<any>;
    private messages: Array<any>;
    private time: Date;
    private isMobile: boolean;
    private isTablet: boolean;
    private os:string = "";
    private helper: any;
    private type: string;
    private isTyping = false;
    private clientId: string = null;
    private isReadOnly = false;
    private emojiIconText = ":smile:";
    private messageInput = "";
    private isShowEmojiPicker: boolean = false;
    private prevMsg = "";
    private archived = false;
    private ended = false;
    private started = 0;
    private recipient = null;
    private name = "";

    constructor(data: any, clientId: string) {
        this.id = data.session_id;
        this.time = new Date(data.time);
        this.messages = data.messages;
        this.messages.map(msg => msg.time = new Date(msg.time));
        this.clients = data.clients;
        this.clientId = clientId;
        this.helper = data.helper;
        this.isMobile = data.mobile;
        this.isTablet = data.tablet;
        this.type = data.type;
        this.os = data.os;
        this.refreshChatTime();
        this.isReadOnly = this.type === "channel" && (this.helper === null || this.helper.id !== this.clientId);
        this.setConversationName();
    }

    private setConversationName() {
        if (this.type === 'direct') {
            this.clients.forEach(client => {
                if (client.id !== this.clientId) {
                    this.recipient = client;
                    this.name = client.name;
                }
            });
        } else {
            if (this.helper !== null && this.helper.id === this.clientId) {
                this.name = "Gyerekkel folytatott beszélgetés";
            } else {
                this.clients.forEach(client => {
                    if (this.clientId !== client.id && client.role === 'helper') {
                        this.name = client.name + ' beszélgetése';
                    }
                });
            }
        }
    }

    public inputChanged() {
        if (this.prevMsg.length === 0 && this.messageInput.length > 0) {
            this.trigger("userTyping", true);
        } else if (this.prevMsg.length > 0 && this.messageInput.length === 0) {
            this.trigger("userTyping", false);
        }
        this.prevMsg = this.messageInput;
    }

    public sendMessage() {
        if ((this.messageInput.trim()).length > 0) {
            this.trigger("sendingMessage", this.messageInput);
            this.messageInput = "";
            this.inputChanged();
        }
    }

    public closeConversation() {
        this.trigger("closing");
    }

    public takeOverConversation() {
        this.trigger('takeover');
    }

    public giveBackConversation() {
        this.trigger('giveback');
    }

    public refreshChatTime() {
        if (!this.ended) {
            this.started = Math.floor(((new Date()).getTime() - this.time.getTime()) / 60000);
        }
    }

    public announce(message: string) {
        this.messages.push({
            client: "system",
            message: "Rendszerüzenet érkezett: " + message,
            time: new Date()
        });
    }

    public onKeyPressed($event) {
        if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter") && $event.ctrlKey === false && $event.shiftKey === false) {
            $event.preventDefault();
            this.sendMessage();
        } else {
            this.inputChanged();
        }
    }

    on(eventName: ChatSessionEventTypes, handler: { (data?: any): void }): void {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName].push(handler);
        }
        else {
            this.handlers[eventName] = [handler];
        }
    }

    off(eventName: ChatSessionEventTypes, handler: { (data?: any): void }): void {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName] = this.handlers[eventName].filter(h => h !== handler);
        }
    }

    private trigger(eventName: ChatSessionEventTypes, data?: any): void {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName].forEach(h => h(data));
        }
    }

    public propagatedEventReceived(type: ChatSessionEventTypes, data: any) {
        switch (type) {
            case "message":
                let msg = {client: data.client, message: data.message, time: new Date()};
                this.messages.push(msg);
                break;
            case "typing":
                if (data.client.id !== this.clientId) {
                    this.isTyping = data.typing;
                }
                break;
            case "end":
                this.isReadOnly = true;
                this.isTyping = false;
                this.messageInput = "";
                this.inputChanged();
                this.ended = true;
                break;
            case "archive":
                this.isReadOnly = true;
                this.isTyping = false;
                this.archived = true;
                this.messages.push({
                    client: "system",
                    message: "A beszélgetés lezárásra került.",
                    time: new Date()
                });
                break;
            case "join":
                this.messages.push({
                    client: "system",
                    message: "Felhasználó csatlakozott a beszélgetéshez:" + data.client.name,
                    time: new Date()
                });
                break;
            case "leave":
                this.messages.push({
                    client: "system",
                    message: "Felhasználó elhagyta a beszélgetést:" + data.client.name,
                    time: new Date()
                });
                break;
            case "handover":
                let prevHelper = this.helper;
                this.helper = data.client;
                this.messages.push({
                    client: "system",
                    message: data.client.name + " átvette a beszélgetést tőle: " + (prevHelper === null ? 'system' : prevHelper.name),
                    time: new Date()
                });
                this.isReadOnly = this.type === "channel" && (this.helper === null || this.helper.id !== this.clientId);

                this.setConversationName();
                break;
        }
        this.trigger(type, data);
    }

    get Archived(): boolean {
        return this.archived;
    }

    get Helper(): any {
        return this.helper;
    }

    get Id(): string {
        return this.id;
    }

    get IsMobile(): boolean {
        return this.isMobile;
    }

    get IsTablet(): boolean {
        return this.isTablet;
    }

    get IsReadOnly(): boolean {
        return this.isReadOnly;
    }

    get IsTyping(): boolean {
        return this.isTyping;
    }

    get Messages(): Array<any> {
        return this.messages;
    }

    get Name(): any {
        return this.name;
    }

    get Recipient(): any {
        return this.recipient;
    }

    get Started(): number {
        return this.started;
    }

    get Time(): Date {
        return this.time;
    }

    get Type(): string {
        return this.type;
    }

    set IsReadOnly(val: boolean) {
        this.isReadOnly = val;
    }

    public openEmojiPicker() {
        this.isShowEmojiPicker = (!this.isShowEmojiPicker) ? true : false;
    }

    public onAddEmojiIcon(text: string) {
        console.log(text);
        this.messageInput += text;
        this.isShowEmojiPicker = false;
        this.inputChanged();
    }
}
