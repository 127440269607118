(function () {
    'use strict';

    angular.module('Yelon').controller('ArchivedChatController', ['ApiFactory', 'spinnerService', '$stateParams', '$scope', '$timeout', ArchivedChatController]);

    function ArchivedChatController(ApiFactory, spinnerService, $stateParams: angular.ui.IStateParamsService, $scope: angular.IScope, $timeout: angular.ITimeoutService) {
        let vm = this;

        let saveTimeout = null;
        let changedItems = {};

        spinnerService.incrementLoading();
        ApiFactory.getFunction('chatconversations/' + $stateParams['id']).then((data) => {
            console.log(data);
            vm.conversation = data;
            vm.conversation.chat_messages.map(msg => msg.date = new Date(msg.created_at));
            setUpWatch();

        }).finally(() => spinnerService.decrementLoading());

        function setUpWatch() {
            $scope.$watch(
                () => {
                    let tmp = {};
                    angular.forEach(vm.conversation.chat_messages, (msg) => {
                        tmp[msg.id] = msg.message;
                    });
                    return tmp;
                },
                (newVal, oldVal) => {
                    for (let i in oldVal) {
                        if (oldVal.hasOwnProperty(i) && newVal.hasOwnProperty(i) && !angular.equals(oldVal[i], newVal[i])) {
                            changedItems[i] = newVal[i];
                            if (saveTimeout !== null) {
                                $timeout.cancel(saveTimeout);
                            }
                            saveTimeout = $timeout(() => {
                                for (let j in changedItems) {
                                    if (changedItems.hasOwnProperty(j)) {
                                        ApiFactory.patchFunction('chatmessages/' + j, {message: changedItems[j]})
                                            .then(() => delete changedItems[j]);
                                    }
                                }
                                saveTimeout = null;
                            }, 3000);
                        }
                    }
                },
                true);
        }

        vm.deleteMessage = function (msgId: number, msgIdx: number) {
            ApiFactory.deleteFunction('chatmessages/' + msgId).then(() => {
                vm.conversation.chat_messages.splice(msgIdx, 1);
            });
        };

        vm.download = function () {
            spinnerService.incrementLoading();

            ApiFactory
                .downloadFunction('chatconversations/' + $stateParams['id'] + '.pdf')
                .finally(() => spinnerService.decrementLoading());
        };
    }
})();
