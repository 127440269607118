/**
 * Created by danielszenasi on 08/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('TagController', ['ApiFactory', 'SidebarService', 'spinnerService', TagController]);

    function TagController(ApiFactory, SidebarService, spinnerService) {
        let vm:any = this;
        vm.tags = [];
        vm.tagSelect = false;

        SidebarService.loadPreset('articles-web');
        SidebarService.enable();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('tags').then(function (data) {
            angular.forEach(data, function(value, key){
                let item = {id:value.id ,text: value.title};
                vm.tags.push(item);
            });
        }).finally(function() {
            spinnerService.decrementLoading();
        });

        vm.tagAdded = function($tag) {
            ApiFactory.postFunction('tags', {'title': $tag.text}).then(function (data) {
                $tag.id = data.id;
            });
        };

        vm.tagRemoved = function($tag) {
            ApiFactory.deleteFunction('tags/' + $tag.id).then(function () {});
        };
    }
})();
