/**
 * Created by danielszenasi on 16/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').
    filter('dateInMillis', function() {
        return function(dateString) {
            return Date.parse(dateString);
        };
    });

})();
