/**
 * Created by ekema on 2016. 04. 01..
 */
(function () {
    'use strict';

    angular.module('Yelon').directive("spinner", ['spinnerService', spinner]);

    function spinner(spinnerService) {
        return {
            restrict: 'A',
            templateUrl: 'app/layouts/spinner.html',
            scope: false,
            replace: true,
            controller: ['$scope', '$timeout', function ($scope:angular.IScope, $timeout:angular.ITimeoutService) {
                $scope['showSpinner'] = spinnerService.showSpinner;
                $scope.$watch(function () {
                    return spinnerService.showSpinner;
                }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue) {
                            $scope['showSpinner'] = spinnerService.showSpinner;
                        } else {
                            $timeout(function () {
                                $scope['showSpinner'] = spinnerService.showSpinner;
                            }, 500);
                        }
                    }
                }, true);
            }]
        };
    }
})();
