/**
 * Created by ekema on 2015. 12. 04..
 */
(function () {
    'use strict';

    angular.module('Yelon').factory('LogoutFactory', ['$http', '$auth', '$rootScope', '$q', 'apiGeneralPrefix', '$state', 'ChatFactory', LogoutFactory]);

    function LogoutFactory($http:angular.IHttpService, $auth, $rootScope:IRootScopeService, $q:angular.IQService, apiGeneralPrefix, $state:angular.ui.IStateService, ChatFactory) {
        return {
            logoutFunction: logoutFunction
        };

        function logoutFunction() {
            return $http.post(apiGeneralPrefix + 'logout', []).then(activated).catch(returnError);

            function activated() {
                return $auth.logout().then(delCreds());

                function delCreds() {
                    localStorage.removeItem('user');
                    $rootScope['authenticated'] = false;
                    $rootScope['currentUser'] = null;
                    ChatFactory.disconnect();

                    $state.go('login');
                }
            }

            function returnError(response) {
                if (response.data.error) {
                    return $q.reject({
                        logoutError: true,
                        logoutErrorText: response.data.error
                    });
                } else {
                    return $q.reject(response);
                }
            }
        }

    }
})();
