/**
 * Created by danielszenasi on 10/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('BusinessHoursController', ['ApiFactory', 'SidebarService', '$scope', '$timeout', '$filter', 'spinnerService', BusinessHoursController]);

    function BusinessHoursController(ApiFactory, SidebarService, $scope, $timeout, $filter, spinnerService) {
        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();

        let closedMessageTimeout = null,
            closingMessageTimeout = null;

        let vm: any = this;
        vm.saveBusinessHours = saveBusinessHours;

        vm.dayNames = [
            "Hétfő",
            "Kedd",
            "Szerda",
            "Csütörtök",
            "Péntek",
            "Szombat",
            "Vasárnap"
        ];

        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.closed_message = data.closed_message;
            vm.closing_message = data.closing_message;
            vm.business_hours = data.business_hours === null ? [{}, {}, {}, {}, {}, {}, {}] : data.business_hours;
            let sunday = vm.business_hours.shift();
            vm.business_hours.push(sunday);

            watchInputs();
        }).catch(() => vm.business_hours = [{}, {}, {}, {}, {}, {}, {}])
            .finally(function () {
            spinnerService.decrementLoading();
        });

        function watchInputs() {
            $scope.$watch('businessHoursCtrl.closed_message', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (closedMessageTimeout) {
                        $timeout.cancel(closedMessageTimeout);
                    }
                    closedMessageTimeout = $timeout(function () {
                        ApiFactory.patchFunction('settings/' + 'closed_message', {value: newVal}).then(function () {
                        });
                    }, 2000);
                }
            }, true);

            $scope.$watch('businessHoursCtrl.closing_message', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (closingMessageTimeout) {
                        $timeout.cancel(closingMessageTimeout);
                    }
                    closingMessageTimeout = $timeout(function () {
                        ApiFactory.patchFunction('settings/' + 'closing_message', {value: newVal}).then(function () {
                        });
                    }, 2000);
                }
            }, true);
        }

        function saveBusinessHours() {
            $timeout(() => {
                let tmpArr = [];
                angular.copy(vm.business_hours, tmpArr);
                let sunday = tmpArr.pop();
                tmpArr.unshift(sunday);
                ApiFactory.patchFunction('settings/business_hours', {value: tmpArr});
                //console.log(vm.business_hours);
            }, 1000);
        }

    }

})();
