/**
 * Created by danielszenasi on 05/08/16.
 */
/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';
    angular.module('Yelon').run(['$rootScope', '$state', '$auth', 'LoginFactory', 'LogoutFactory', 'ApiFactory', 'ChatFactory', '$http', function ($rootScope, $state, $auth, LoginFactory, LogoutFactory, ApiFactory, ChatFactory, $http) {
            $http.get("./app/emoji_strategy.json").then(function (result) {
                $rootScope["emojis"] = result.data;
            }).catch(function () {
                console.warn("Hiányzik az emoji_strategy.json file");
            });
            $rootScope['prevState'] = [];
            $rootScope['lastSave'] = false;
            $rootScope['errorMessage'] = false;
            var user = JSON.parse(localStorage.getItem('user'));
            LoginFactory.init();
            if ($auth.isAuthenticated() && user !== null && (user.role === 'supervisor' || user.role === 'helper')) {
                ChatFactory.init();
            }
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                $rootScope["pageId"] = toState.data.pageId;
                $rootScope['lastSave'] = false;
                if (!$rootScope['backBtn']) {
                    $rootScope['prevState'].push({ 'state': fromState, 'params': fromParams });
                }
                else {
                    $rootScope['backBtn'] = false;
                }
                // Grab the user from local storage and parse it to an object
                if ($auth.isAuthenticated()) {
                    var user = JSON.parse(localStorage.getItem('user'));
                }
                else {
                    delCreds();
                }
                if (user) {
                    $rootScope['currentUser'] = user;
                    $rootScope['authenticated'] = true;
                    if (toState.authenticate === 2) {
                        event.preventDefault();
                        $state.go('home');
                    }
                    if (typeof toState.rolesAllowed !== "undefined" && toState.rolesAllowed.indexOf(user.role) === -1) {
                        event.preventDefault();
                        $state.go('home');
                    }
                }
                if (toState.authenticate === 1 && !$rootScope['authenticated']) {
                    $rootScope['wantedState'] = toState;
                    $rootScope['wantedParams'] = toParams;
                    event.preventDefault();
                    $state.go('login');
                    return;
                }
                if ($rootScope['wantedState'] && toState.authenticate !== 2) {
                    event.preventDefault();
                    var tempState = $rootScope['wantedState'];
                    var tempParams = $rootScope['wantedParams'];
                    $rootScope['wantedState'] = false;
                    $rootScope['wantedParams'] = false;
                    $state.go(tempState.name, tempParams);
                }
            });
            /*ApiFactory.getFunction('info').then(function (data) {
             $rootScope['upload_max_filesize'] = parseInt(data.upload_max_filesize, 10);
             });*/
            $rootScope['logout'] = function () {
                LogoutFactory.logoutFunction();
            };
            function delCreds() {
                localStorage.removeItem('user');
                $rootScope['authenticated'] = false;
                $rootScope['currentUser'] = null;
            }
        }])
        .config(['tagsInputConfigProvider', function (tagsInputConfigProvider) {
            tagsInputConfigProvider.setDefaults('tagsInput', {
                replaceSpacesWithDashes: false
            });
        }]);
})();
/**
 * Created by ekema on 2016. 01. 29..
 */
/// <reference path="../../typings/tsd.d.ts"/>
(function () {
    'use strict';
    angular.module('Yelon').config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$authProvider', '$httpProvider', '$provide', 'apiPrefix', 'apiGeneralPrefix', function ($stateProvider, $urlRouterProvider, $locationProvider, $authProvider, $httpProvider, $provide, apiPrefix, apiGeneralPrefix) {
            $authProvider.loginUrl = apiGeneralPrefix + 'authenticate';
            //$authProvider.signupUrl = apiPrefix + 'register';
            $stateProvider
                .state('home', {
                url: '/',
                templateUrl: 'app/Home/index.html',
                controller: 'HomeController as homeCtrl',
                authenticate: 1,
                rolesAllowed: ["helper", "blogger", "supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Főoldal'
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles', {
                url: '/articles',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': function () {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Bejegyzések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('archivedchatconv', {
                url: '/archivedchatconv',
                templateUrl: 'app/ArchivedChatConv/archived_chat_conv.html',
                controller: 'ArchivedChatConvController as archivedChatConvCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Archivált beszélgetések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('archivedchat', {
                url: '/archivedchat/{id}',
                templateUrl: 'app/ArchivedChatConv/archivedchat.html',
                controller: 'ArchivedChatController as archiveChatCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Archivált beszélgetés',
                    parent: 'archivedchatconv',
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles-create', {
                url: '/articles/create/{type}/{mainType}',
                templateUrl: 'app/Articles/edit.html',
                controller: 'ArticleEditController as articleEditCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: '{{breadCrumbText}}',
                    parent: 'articles',
                },
                data: {
                    pageId: "article-editor"
                }
            })
                .state('articles-create.created', {
                url: "^/articles/create/{type}/{mainType}/{id}",
                data: {
                    pageId: "article-editor",
                    isCreated: true
                }
            })
                .state('articles-edit', {
                url: '/articles/edit/{type}/{mainType}/{id}',
                templateUrl: 'app/Articles/edit.html',
                controller: 'ArticleEditController as articleEditCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: '{{breadCrumbText}}',
                    parent: 'articles',
                },
                data: {
                    pageId: "article-editor"
                }
            })
                .state('articles-web', {
                url: '/articles/web',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': function () {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Webes bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles-fix', {
                url: '/articles/fix',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': function () {
                        return 'web';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Fix bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: "",
                    type: "fix"
                }
            })
                .state('articles-mobile', {
                url: '/articles/mobile',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': function () {
                        return 'mobile';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Mobile App bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles-adult', {
                url: '/articles/adult',
                templateUrl: 'app/Articles/index.html',
                controller: 'ArticleController as articleCtrl',
                resolve: {
                    'mainType': function () {
                        return 'adult';
                    }
                },
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Felnőtt bejegyzések',
                    parent: 'articles',
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles-web-tags', {
                url: '/articles/web/tags',
                templateUrl: 'app/Articles/Tags/tags.html',
                controller: 'TagController as tagCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Tagek szerkesztése',
                    parent: 'articles-web',
                },
                data: {
                    pageId: ""
                }
            })
                .state('articles-web-categories', {
                url: '/articles/web/categories',
                templateUrl: 'app/Articles/Categories/categories.html',
                controller: 'CategoryController as categoryCtrl',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Kategóriák szerkesztése',
                    parent: 'articles-web',
                },
                data: {
                    pageId: ""
                }
            })
                .state('login', {
                url: '/login',
                templateUrl: 'app/Auth/login.html',
                controller: 'LoginController as login',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                authenticate: 2,
                data: {
                    pageId: ""
                }
            })
                .state('pages', {
                url: '/pages',
                controller: 'PagesController as pagesCtrl',
                templateUrl: 'app/Pages/index.html',
                authenticate: 1,
                rolesAllowed: ["blogger", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Fix oldalak',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('users', {
                url: '/users',
                controller: 'UsersController as usersCtrl',
                templateUrl: 'app/Users/index.html',
                rolesAllowed: ["superadmin"],
                authenticate: 1,
                ncyBreadcrumb: {
                    label: 'Felhasználók',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('newpassword', {
                url: '/newpassword',
                controller: 'NewPasswordController as newPasswordCtrl',
                templateUrl: 'app/Users/new_password.html',
                authenticate: 1,
                ncyBreadcrumb: {
                    label: 'Saját profil',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-faq', {
                url: '/pages/faq',
                controller: 'FAQController as faqCtrl',
                templateUrl: 'app/Pages/FAQ/index.html',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'GYIK',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-faq-categories', {
                url: '/pages/faq/categories',
                templateUrl: 'app/Pages/FAQ/categories.html',
                controller: 'FAQCategoriesController as faqCategoriesCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Kategóriák szerkesztése',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-faq-add', {
                url: '/pages/faq/add',
                templateUrl: 'app/Pages/FAQ/edit.html',
                controller: 'FAQEditController as faqEditCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Új elem hozzáadása',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-faq-edit', {
                url: '/pages/faq/edit/{id}',
                templateUrl: 'app/Pages/FAQ/edit.html',
                controller: 'FAQEditController as faqEditCtrl',
                authenticate: 1,
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Elem szerkesztése',
                    parent: 'pages-faq',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-about', {
                url: '/pages/about',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': function () {
                        return 'about';
                    },
                    'title': function () {
                        return 'Rólunk';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Rólunk',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-app', {
                url: '/pages/application',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': function () {
                        return 'application';
                    },
                    'title': function () {
                        return 'Az applikáció';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Az applikáció',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
                .state('pages-helpers', {
                url: '/pages/helpers',
                controller: 'PagesEditController as pagesEditCtrl',
                templateUrl: 'app/Pages/Edit/edit.html',
                authenticate: 1,
                resolve: {
                    'page': function () {
                        return 'helpers';
                    },
                    'title': function () {
                        return 'Chat ügyelők';
                    }
                },
                rolesAllowed: ["superadmin"],
                ncyBreadcrumb: {
                    label: 'Chat ügyelők',
                    parent: 'pages',
                },
                data: {
                    pageId: ""
                }
            })
                .state('chat', {
                url: '/chat',
                templateUrl: 'app/Chat/chatoverview.html',
                controller: 'ChatController as chatCtrl',
                authenticate: 1,
                rolesAllowed: ["helper", "supervisor"],
                ncyBreadcrumb: {
                    label: 'Chat beszélgetések',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('chat-settings', {
                url: '/chat-settings',
                templateUrl: 'app/ChatSettings/auto_messages.html',
                controller: 'AutoMessagesController as autoMessagesCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Chat beállítások',
                    parent: 'home',
                },
                data: {
                    pageId: ""
                }
            })
                .state('chat-settings-topics', {
                url: '/chat-settings/topics',
                templateUrl: 'app/ChatSettings/chat_topics.html',
                controller: 'ChatTopicsController as chatTopicsCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Témák szerkesztése',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
                .state('chat-settings-automessages', {
                url: '/chat-settings/automessages',
                templateUrl: 'app/ChatSettings/auto_messages.html',
                controller: 'AutoMessagesController as autoMessagesCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Automatikus üzenetek',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
                .state('chat-settings-businesshours', {
                url: '/chat-settings/businesshours',
                templateUrl: 'app/ChatSettings/business_hours.html',
                controller: 'BusinessHoursController as businessHoursCtrl',
                authenticate: 1,
                rolesAllowed: ["supervisor", "superadmin"],
                ncyBreadcrumb: {
                    label: 'Nyitvatartás',
                    parent: 'chat-settings',
                },
                data: {
                    pageId: ""
                }
            })
                .state('resetPassword', {
                url: '/resetpassword/{user_id}/{token}',
                templateUrl: 'app/Auth/resetpassword.html',
                controller: 'ResetPasswordController as resetPasswordCtrl',
                authenticate: 2,
                ncyBreadcrumb: {
                    skip: true,
                },
                data: {
                    pageId: ""
                }
            })
                .state("otherwise", {
                url: "*path",
                template: "Invalid Location",
                controller: [
                    '$state', '$scope',
                    function ($state, $scope) {
                        $scope['goHome'] = function () {
                            $state.go('home');
                        };
                    }]
            });
            $locationProvider.html5Mode(true);
        }]);
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('ArchivedChatConvController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', ArchivedChatConvController]);
    function ArchivedChatConvController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm) {
        var vm = this;
        var user = JSON.parse(localStorage.getItem('user'));
        SidebarService.clearSidebarItems();
        var settings = JSON.parse(localStorage.getItem('archived_chat')) || {};
        var orderBy = $filter('orderBy');
        var currentPageNumber;
        vm.archived_chats = [];
        vm.selectedTopics = settings.selectedTopics || [];
        vm.chatTopics = [];
        vm.convType = "channel";
        vm.selectedUsers = settings.selectedUsers || [];
        vm.predicate = settings.predicate || 'id';
        vm.reverse = settings.reverse || false;
        vm.searchString = settings.searchString || "";
        vm.starredOnly = settings.starredOnly || false;
        vm.conversations = [];
        vm.from = settings.from ? { open: false, date: new Date(settings.from) } : { open: false, date: null };
        vm.to = settings.to ? { open: false, date: new Date(settings.to) } : { open: false, date: null };
        vm.paginate = paginate;
        vm.order = order;
        vm.selectAllChannel = false;
        vm.selectAllAdmin = false;
        vm.limit = settings.limit || '10';
        vm.buttonBar = {
            show: true,
            now: {
                show: true,
                text: 'Most'
            },
            today: {
                show: true,
                text: 'Ma'
            },
            clear: {
                show: true,
                text: 'Törlés'
            },
            date: {
                show: true,
                text: 'Dátum'
            },
            time: {
                show: true,
                text: 'Idő'
            },
            close: {
                show: true,
                text: 'Bezárás'
            }
        };
        init();
        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('chattopics').then(function (data) {
                vm.chatTopics = data;
            }).finally(function () {
                spinnerService.decrementLoading();
            });
            var currentPageNumber = (settings.currentPage) ? settings.currentPage : 1;
            paginate(null, currentPageNumber);
            ApiFactory.getFunction('users').then(function (data) {
                vm.users = data;
            });
            $scope.$watch('archivedChatConvCtrl.selectAllChannel', function (newValue, oldValue) {
                if (typeof vm.conversations[0] !== 'undefined') {
                    if (newValue) {
                        vm.conversations[0].data.forEach(function (conversation) {
                            conversation.selected = true;
                        });
                    }
                    else {
                        vm.conversations[0].data.forEach(function (conversation) {
                            conversation.selected = false;
                        });
                    }
                }
            });
            $scope.$watch('archivedChatConvCtrl.selectAllAdmin', function (newValue, oldValue) {
                if (typeof vm.conversations[1] !== 'undefined') {
                    if (newValue) {
                        vm.conversations[1].data.forEach(function (conversation) {
                            conversation.selected = true;
                        });
                    }
                    else {
                        vm.conversations[1].data.forEach(function (conversation) {
                            conversation.selected = false;
                        });
                    }
                }
            });
            $scope.$watch('archivedChatConvCtrl.limit', function (newValue, oldValue) {
                if (typeof vm.conversations[0] !== 'undefined' && typeof vm.conversations[1] !== 'undefined') {
                    vm.paginate();
                }
            });
        }
        vm.updateChatConv = function (chatConv, type) {
            if (type === 'starred') {
                chatConv.starred = !chatConv.starred;
            }
            if (type === 'closed') {
                chatConv.closed = !chatConv.closed;
            }
            ApiFactory.patchFunction('chatconversations/' + chatConv.id, chatConv).then(function () {
            });
        };
        vm.onClickFilterRemoveBtn = function () {
            vm.selectedTopics = [];
            vm.selectedUsers = [];
            vm.predicate = "id";
            vm.searchString = "";
            vm.starredOnly = false;
            vm.limit = 15;
            vm.from = { open: false, date: null };
            vm.to = { open: false, date: null };
            vm.paginate();
        };
        function getQuery() {
            var queryParams = "order=" + vm.predicate + "&asc=" + (vm.reverse ? 1 : 0) + "&limit=" + vm.limit;
            vm.selectedTopics.forEach(function (topic) {
                queryParams += '&topics[]=' + topic.id;
            });
            vm.selectedUsers.forEach(function (user) {
                queryParams += '&users[]=' + user.id;
            });
            if (vm.searchString.length > 0) {
                queryParams += '&q=' + vm.searchString;
            }
            if (vm.starredOnly === true) {
                queryParams += '&starred=1';
            }
            if (vm.from.date !== null && vm.to.date !== null) {
                var from = $filter('date')(vm.from.date, 'yyyy-MM-dd HH:mm:ss');
                var to = $filter('date')(vm.to.date, 'yyyy-MM-dd HH:mm:ss');
                queryParams += "&from=" + from + "&to=" + to;
            }
            localStorage.setItem('archived_chat', JSON.stringify({
                predicate: vm.predicate,
                reverse: vm.reverse,
                selectedUsers: vm.selectedUsers,
                selectedTopics: vm.selectedTopics,
                searchString: vm.searchString,
                starredOnly: vm.starredOnly,
                from: vm.from.date,
                to: vm.to.date,
                currentPage: currentPageNumber,
                limit: vm.limit
            }));
            return queryParams;
        }
        function paginate(type, page) {
            currentPageNumber = page;
            var queryParams = getQuery();
            if (!type || type === 'channel') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('chatconversations?page=' + (typeof page === "undefined" ? vm.conversations[0].current_page : page) + '&type=channel&' + queryParams).then(function (data) {
                    data.data = data.data.map(function (conversation) {
                        conversation.selected = false;
                        return conversation;
                    });
                    vm.conversations[0] = data;
                }).finally(function () { return spinnerService.decrementLoading(); });
            }
            if (!type || type === 'direct') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('chatconversations?page=' + (typeof page === "undefined" ? vm.conversations[1].current_page : page) + '&type=direct&' + queryParams).then(function (data) {
                    data.data = data.data.map(function (conversation) {
                        conversation.selected = false;
                        return conversation;
                    });
                    vm.conversations[1] = data;
                }).finally(function () { return spinnerService.decrementLoading(); });
            }
        }
        function order(predicate) {
            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : vm.reverse;
            paginate();
        }
        //delete item
        vm.deleteChatConv = function (id) {
            $confirm({ title: 'Megerősítés', text: 'Biztosan törli a beszélgetést?' })
                .then(function () {
                return ApiFactory.deleteFunction('chatconversations/' + id).then(function () { return vm.paginate(); });
            });
        };
        //download item
        vm.downloadChatConv = function (id) {
            spinnerService.incrementLoading();
            ApiFactory
                .downloadFunction('chatconversations/' + id + '.pdf')
                .finally(function () { return spinnerService.decrementLoading(); });
        };
        //download list
        vm.download = function () {
            spinnerService.incrementLoading();
            var query = getQuery();
            ApiFactory
                .downloadFunction('chatconversations.xlsx' + (query.length > 0 ? '?' + query : ''))
                .finally(function () { return spinnerService.decrementLoading(); });
        };
        vm.bulkAction = function (index, method) {
            console.log(method);
            var conversations = vm.conversations[index].data.filter(function (conversation) { return conversation.selected; });
            if (conversations.length === 0) {
                return;
            }
            switch (method) {
                case 'close':
                    var notClosed_1 = conversations.filter(function (conversation) { return !conversation.closed; });
                    if (notClosed_1.length > 0) {
                        $confirm({ title: 'Megerősítés', text: 'Biztosan zárolja az összes kijelölt beszélgetést?' })
                            .then(function () {
                            notClosed_1.forEach(function (conversation) {
                                spinnerService.incrementLoading();
                                ApiFactory
                                    .patchFunction('chatconversations/' + conversation.id, {
                                    closed: true
                                })
                                    .then(function () { return vm.paginate(); })
                                    .finally(function () { return spinnerService.decrementLoading(); });
                            });
                        });
                    }
                    else {
                        $confirm({ title: 'Megerősítés', text: 'Biztosan feloldja az összes kijelölt beszélgetést?' })
                            .then(function () {
                            conversations.filter(function (conversation) { return conversation.closed; }).forEach(function (conversation) {
                                spinnerService.incrementLoading();
                                ApiFactory
                                    .patchFunction('chatconversations/' + conversation.id, {
                                    closed: false
                                })
                                    .then(function () { return vm.paginate(); })
                                    .finally(function () { return spinnerService.decrementLoading(); });
                            });
                        });
                    }
                    break;
                case 'star':
                    var notStarred_1 = conversations.filter(function (conversation) { return !conversation.starred; });
                    if (notStarred_1.length > 0) {
                        $confirm({ title: 'Megerősítés', text: 'Biztosan csillagozza az összes kijelölt beszélgetést?' })
                            .then(function () {
                            notStarred_1.forEach(function (conversation) {
                                spinnerService.incrementLoading();
                                ApiFactory
                                    .patchFunction('chatconversations/' + conversation.id, {
                                    starred: true
                                })
                                    .then(function () { return vm.paginate(); })
                                    .finally(function () { return spinnerService.decrementLoading(); });
                            });
                        });
                    }
                    else {
                        $confirm({ title: 'Megerősítés', text: 'Biztosan leveszi a csillagozást az összes kijelölt beszélgetésről?' })
                            .then(function () {
                            conversations.filter(function (conversation) { return conversation.starred; }).forEach(function (conversation) {
                                spinnerService.incrementLoading();
                                ApiFactory
                                    .patchFunction('chatconversations/' + conversation.id, {
                                    starred: false
                                })
                                    .then(function () { return vm.paginate(); })
                                    .finally(function () { return spinnerService.decrementLoading(); });
                            });
                        });
                    }
                    break;
                case 'delete':
                    $confirm({ title: 'Megerősítés', text: 'Biztosan törli az összes kijelölt beszélgetést?' })
                        .then(function () {
                        conversations.forEach(function (conversation) {
                            spinnerService.incrementLoading();
                            ApiFactory
                                .deleteFunction('chatconversations/' + conversation.id)
                                .then(function () { return vm.paginate(); })
                                .finally(function () { return spinnerService.decrementLoading(); });
                        });
                    });
                    break;
                case 'download':
                    $confirm({ title: 'Megerősítés', text: 'Biztosan letölti az összes kijelölt beszélgetést?' })
                        .then(function () {
                        conversations.forEach(function (conversation) {
                            spinnerService.incrementLoading();
                            ApiFactory
                                .downloadFunction('chatconversations/' + conversation.id + '.pdf')
                                .finally(function () { return spinnerService.decrementLoading(); });
                        });
                    });
                    break;
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('ArchivedChatController', ['ApiFactory', 'spinnerService', '$stateParams', '$scope', '$timeout', ArchivedChatController]);
    function ArchivedChatController(ApiFactory, spinnerService, $stateParams, $scope, $timeout) {
        var vm = this;
        var saveTimeout = null;
        var changedItems = {};
        spinnerService.incrementLoading();
        ApiFactory.getFunction('chatconversations/' + $stateParams['id']).then(function (data) {
            console.log(data);
            vm.conversation = data;
            vm.conversation.chat_messages.map(function (msg) { return msg.date = new Date(msg.created_at); });
            setUpWatch();
        }).finally(function () { return spinnerService.decrementLoading(); });
        function setUpWatch() {
            $scope.$watch(function () {
                var tmp = {};
                angular.forEach(vm.conversation.chat_messages, function (msg) {
                    tmp[msg.id] = msg.message;
                });
                return tmp;
            }, function (newVal, oldVal) {
                for (var i in oldVal) {
                    if (oldVal.hasOwnProperty(i) && newVal.hasOwnProperty(i) && !angular.equals(oldVal[i], newVal[i])) {
                        changedItems[i] = newVal[i];
                        if (saveTimeout !== null) {
                            $timeout.cancel(saveTimeout);
                        }
                        saveTimeout = $timeout(function () {
                            var _loop_1 = function(j) {
                                if (changedItems.hasOwnProperty(j)) {
                                    ApiFactory.patchFunction('chatmessages/' + j, { message: changedItems[j] })
                                        .then(function () { return delete changedItems[j]; });
                                }
                            };
                            for (var j in changedItems) {
                                _loop_1(j);
                            }
                            saveTimeout = null;
                        }, 3000);
                    }
                }
            }, true);
        }
        vm.deleteMessage = function (msgId, msgIdx) {
            ApiFactory.deleteFunction('chatmessages/' + msgId).then(function () {
                vm.conversation.chat_messages.splice(msgIdx, 1);
            });
        };
        vm.download = function () {
            spinnerService.incrementLoading();
            ApiFactory
                .downloadFunction('chatconversations/' + $stateParams['id'] + '.pdf')
                .finally(function () { return spinnerService.decrementLoading(); });
        };
    }
})();
/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('ArticleController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', '$state', 'mainType', ArticleController]);
    function ArticleController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm, $state, mainType) {
        var vm = this;
        if (mainType === 'web') {
            SidebarService.loadPreset('articles-web');
            vm.pageTitle = 'Webes bejegyzések';
        }
        else {
            SidebarService.loadPreset('articles');
            vm.pageTitle = mainType === 'adult' ? 'Felnőtt bejegyzések' : 'Mobil bejegyzések';
        }
        vm.mainType = mainType;
        SidebarService.enable();
        var orderBy = $filter('orderBy');
        vm.articles = [];
        vm.selectedValues = {};
        vm.attributes = {};
        vm.order = order;
        vm.to = null;
        vm.lastPage = 10;
        vm.predicate = 'title';
        vm.reverse = true;
        vm.filteredArticles = [];
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.searchString = "";
        vm.newArticleType = "article";
        vm.type = ($state.current.data.type) ? $state.current.data.type : null;
        vm.articlePageType = "article";
        if (vm.type === "fix") {
            vm.pageTitle = "Fix bejegyzések";
            vm.newArticleType = vm.type;
            vm.articlePageType = "fix";
        }
        vm.filter = filter;
        var _defaultOrder;
        var previousArticleQuery = JSON.parse(window.localStorage.getItem("previous_article_query")) || null;
        window.localStorage.setItem("currentStateName", $state.current.name);
        init();
        function init() {
            if (mainType === 'web') {
                spinnerService.incrementLoading();
                ApiFactory.getFunction('attributes').then(function (data) {
                    vm.attributes = data;
                    for (var i = 0; i < vm.attributes.length; i++) {
                        vm.selectedValues[i] = [];
                    }
                }).finally(function () {
                    spinnerService.decrementLoading();
                    vm.filter("updated_at");
                });
            }
            else {
                vm.filter("updated_at");
            }
        }
        vm.onClickFilterRemoveBtn = function () {
            previousArticleQuery = null;
            vm.type = null;
            vm.selectedValues = {};
            vm.attributes = {};
            vm.searchString = "";
            vm.reverse = true;
            init();
        };
        vm.publishedInFuture = function (article) {
            var nowDate = new Date();
            var pubDate = new Date(article.published_at);
            return nowDate < pubDate;
        };
        function filter(defaultOrder) {
            if (defaultOrder === void 0) { defaultOrder = null; }
            if (previousArticleQuery !== null) {
                defaultOrder = previousArticleQuery.defaultOrder;
                vm.selectedValues = previousArticleQuery.selectedValues;
                vm.searchString = previousArticleQuery.searchString;
            }
            spinnerService.incrementLoading();
            var queryParams = '?main_type=' + mainType;
            if (typeof vm.type === 'string' && vm.type.length > 0) {
                queryParams += '&type=' + vm.type;
            }
            if (vm.type === "fix") {
                queryParams = '?type=' + vm.type;
            }
            queryParams += vm.searchString.trim().length > 0 ? ('&q=' + vm.searchString) : '';
            if (mainType === 'web') {
                angular.forEach(vm.selectedValues, (function (value) {
                    angular.forEach(value, function (attr) {
                        queryParams += '&attributes[]=' + attr.id;
                    });
                }));
            }
            ApiFactory.getFunction('articles' + queryParams).then(function (data) {
                vm.articles = data;
                vm.currentPage = (previousArticleQuery !== null) ? previousArticleQuery.currentPage : 1;
                if (defaultOrder === null) {
                    order('title');
                }
                else {
                    vm.reverse = (previousArticleQuery !== null) ? !previousArticleQuery.reverse : false;
                    vm.predicate = defaultOrder;
                    order(defaultOrder);
                }
                paginate(true);
            }).finally(function () {
                spinnerService.decrementLoading();
            });
        }
        function saveQueryConfig(defaultOrder) {
            previousArticleQuery = null;
            window.localStorage.setItem("previous_article_query", JSON.stringify({
                selectedValues: vm.selectedValues,
                searchString: vm.searchString,
                type: vm.type,
                currentPage: vm.currentPage,
                defaultOrder: defaultOrder,
                reverse: vm.reverse
            }));
        }
        ;
        function paginate(emptyAllowed) {
            if (emptyAllowed === void 0) { emptyAllowed = false; }
            if (!emptyAllowed && vm.articles.length === 0) {
                return;
            }
            var begin = ((vm.currentPage - 1) * vm.numPerPage), end = begin + vm.numPerPage;
            vm.filteredArticles = vm.articles.slice(begin, end);
            saveQueryConfig(_defaultOrder);
        }
        $scope.$watch("articleCtrl.currentPage", paginate);
        function order(predicate) {
            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.articles = orderBy(vm.articles, predicate, vm.reverse);
            _defaultOrder = predicate;
            paginate();
        }
        //delete item
        vm.deleteItem = function (article) {
            $confirm({
                title: 'Megerősítés',
                text: 'Biztosan törli a "' + article.title + '" elemet?'
            })
                .then(function () {
                var delIndex = vm.articles.indexOf(article);
                ApiFactory.deleteFunction('articles/' + article.id).then(function (data) {
                    vm.articles.splice(delIndex, 1);
                    vm.filter("updated_at");
                });
            });
        };
        vm.createArticle = function () {
            $state.go('articles-create', {
                mainType: mainType,
                type: vm.newArticleType
            });
        };
        vm.archiveItem = function (article, archived) {
            if (archived) {
                $confirm({
                    title: 'Megerősítés',
                    text: 'Biztosan archiválja a "' + article.title + '" elemet?'
                })
                    .then(function () {
                    article.archived = true;
                    ApiFactory.patchFunction('articles/' + article.id, article);
                });
            }
            else {
                article.archived = false;
                ApiFactory.patchFunction('articles/' + article.id, article);
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('ArticleEditController', ['$scope', '$state', '$stateParams', '$timeout',
        '$confirm', '$filter', 'ApiFactory', 'spinnerService', 'SidebarService', 'Upload', 'apiPrefix', 'sitePrefix',
        'tileColors', 'adultTileColors', '$rootScope',
        ArticleEditController
    ]);
    function ArticleEditController($scope, $state, $stateParams, $timeout, $confirm, $filter, ApiFactory, spinnerService, SidebarService, Upload, apiPrefix, sitePrefix, tileColors, adultTileColors, $rootScope) {
        var self = this;
        var articleId;
        var articleTypes = {
            article: "Cikk",
            gallery: "Galéria",
            quiz: "Kvíz",
            motto: "Mottó",
            video: "Videó",
            fix: "Fix"
        };
        var timer;
        var isNewArticle;
        var images;
        var initImages = false;
        var initCoverImage = false;
        var coverImage = {
            id: null,
            full_url: null,
            url: null,
            pivot: {
                article_id: null,
                disabled: false,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                type: "cover"
            }
        };
        var isDelete = false;
        var isLoaded = false;
        var isCreateParagraph;
        this.articleType = $stateParams["type"];
        $scope.breadCrumbText = "Új bejegyzés";
        //noinspection JSAnnotator
        this.currentState = window.localStorage.getItem("currentStateName");
        this.articleTypeText = articleTypes[$stateParams["type"]];
        this.mainType = $stateParams["mainType"];
        this.tileColors = ($stateParams["mainType"] === "adult") ? adultTileColors : tileColors;
        this.paragraphs = [];
        this.boundsCover = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
        this.selectedAllCategories = (this.mainType !== 'adult' && this.articleType !== "fix") ? false : true;
        this.requireTitle = true;
        this.isLoaded = false;
        this.sitePrefix = sitePrefix;
        if ($stateParams["id"] !== undefined) {
            articleId = $stateParams["id"];
            isNewArticle = false;
            $scope.breadCrumbText = "Bejegyzés szerkesztése";
            loadExistArticle();
        }
        else {
            if ($state !== undefined && $state.current.data.isCreated) {
                articleId = window.localStorage.getItem("article_id");
                isNewArticle = false;
                $scope.breadCrumbText = "Bejegyzés szerkesztése";
                loadExistArticle();
            }
            else {
                subscribeArticleWatch();
                this.article = new Article($stateParams["mainType"]);
                this.article.type = this.articleType;
                self.isLoaded = true;
            }
        }
        function loadExistArticle() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('articles/' + articleId + '/edit').then(function (data) {
                isLoaded = true;
                self.selectedAllCategories = true;
                self.requireTitle = false;
                images = data.images;
                data.images.forEach(function (image) {
                    if (image.pivot.type === "cover") {
                        coverImage = image;
                    }
                });
                self.existPopupMessage = data.popup_message;
                self.existDate = data.published_at;
                self.existTags = data.tags;
                self.existParagraphs = data.paragraphs;
                self.existImages = data.images;
                self.existAttributesValues = data.attributes_values;
                self.article = data;
                self.coverImage = coverImage;
            }).finally(function () {
                spinnerService.decrementLoading();
                $timeout(function () {
                    subscribeArticleWatch();
                }, 2000);
                self.isLoaded = true;
            });
        }
        function subscribeArticleWatch() {
            $scope.$watch("articleEditCtrl.article", function (newValue, oldValue) {
                if (newValue === undefined || isLoaded) {
                    isLoaded = false;
                    return;
                }
                if (newValue.duplicate || oldValue.duplicate) {
                    return;
                }
                var hasAttributes = (newValue.attributes !== undefined) ? true : false;
                if (self.mainType === 'adult' || newValue.type === "fix") {
                    hasAttributes = true;
                }
                var hasTitle = (newValue.title !== "") ? true : false;
                if (!self.selectedAllCategories || self.requireTitle) {
                    self.disabledAllInput = true;
                    return;
                }
                self.disabledAllInput = false;
                if (newValue.published_at !== undefined) {
                    if (newValue.id === null) {
                        if (hasAttributes && hasTitle) {
                            if (newValue.images === undefined && !initImages) {
                                setImagesForArticle();
                            }
                            else {
                                initImages = false;
                                createArticle(newValue);
                            }
                        }
                    }
                    else {
                        if (isNewArticle) {
                            articleId = newValue.id;
                            isNewArticle = false;
                            if (self.paragraphs.length > 0) {
                                self.paragraphs.forEach(function (paragraph, index) {
                                    createParagraph(paragraph, newValue.id, index);
                                });
                            }
                            if (initCoverImage && coverImage.id !== null) {
                                coverImage.pivot.article_id = articleId;
                                self.article.images = [coverImage];
                                initCoverImage = false;
                            }
                            return;
                        }
                        if (isCreateParagraph) {
                            return;
                        }
                        if (angular.toJson(newValue.paragraphs) !== angular.toJson(oldValue.paragraphs)) {
                            return;
                        }
                        if (newValue.published_at === null) {
                            if (timer) {
                                window.clearTimeout(timer);
                                timer = undefined;
                            }
                            timer = window.setTimeout(function () {
                                updateArticle(newValue);
                            }, 2000);
                        }
                        else {
                            updateArticle(newValue);
                        }
                    }
                }
                else {
                    if (newValue.id === null) {
                        if (hasAttributes && hasTitle) {
                            self.existDate = null;
                            newValue.published_at = null;
                        }
                    }
                }
            }, true);
        }
        $scope.$watch("articleEditCtrl.coverImage", function (newCover) {
            initCoverImage = true;
            coverImage.pivot.top = self.boundsCover.top;
            coverImage.pivot.right = self.boundsCover.right;
            coverImage.pivot.bottom = self.boundsCover.bottom;
            coverImage.pivot.left = self.boundsCover.left;
            if (coverImage.id !== null) {
                setImagesForArticle();
            }
        });
        function setImagesForArticle() {
            initImages = true;
            if (images) {
                images.forEach(function (image) {
                    image.pivot.article_id = articleId;
                });
                if (coverImage.id !== null) {
                    images = images.filter(function (image) { return image.pivot.type !== 'cover'; });
                    images.push(coverImage);
                }
                self.article.images = images;
            }
            else if (self.article.images === undefined) {
                self.article.images = [];
                if (coverImage.id !== null) {
                    images = [coverImage];
                    self.article.images = images;
                }
                else {
                    self.article.images = [];
                }
            }
            else {
                if (coverImage.id !== null && self.article.images.length === 4) {
                    self.article.images = self.article.images.filter(function (image) { return image.pivot.type !== 'cover'; });
                    self.article.images.push(coverImage);
                }
                else if (coverImage.id !== null) {
                    self.article.images = [coverImage];
                }
            }
        }
        $scope.$watchCollection("articleEditCtrl.paragraphs", function (newValue, oldValue) {
            var length = newValue.length - 1;
            if (length === -1) {
                return;
            }
            var lastParagraph = newValue[length];
            if (articleId && lastParagraph.id === undefined && (lastParagraph.type !== "image" || lastParagraph.type !== 'gallery')) {
                createParagraph(lastParagraph, articleId, length);
            }
        });
        function createArticle(articleData) {
            var prepareNotify = (articleData.published_at === null) ? "Piszkozat mentése..." : "Publikálás...";
            var successNotify = (articleData.published_at === null) ? "Piszkozat mentve" : "Publikálva";
            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };
            ApiFactory.postFunction("articles", articleData).then(function (data) {
                $scope.breadCrumbText = "Bejegyzés szerkesztése";
                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };
                window.localStorage.setItem("article_id", data.id);
                $state.go('articles-create.created', {
                    mainType: data.main_type,
                    type: data.type,
                    id: data.id
                }, {
                    notify: false
                });
                isNewArticle = true;
                self.article = data;
            });
        }
        function updateArticle(articleData) {
            if (isDelete) {
                isDelete = false;
                return;
            }
            var prepareNotify = (articleData.published_at === null) ? "Piszkozat mentése..." : "Publikálás...";
            var successNotify = (articleData.published_at === null) ? "Piszkozat mentve" : "Publikálva";
            if (articleData.notify) {
                prepareNotify = "Értesítés küldése...";
                successNotify = "Értesítés elküldve";
            }
            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };
            ApiFactory.patchFunction('articles/' + articleData.id, articleData).then(function (data) {
                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };
            });
        }
        function createParagraph(paragraph, articleId, indexNumber) {
            if (paragraph.id) {
                self.updateParagraph(paragraph);
                return;
            }
            isCreateParagraph = true;
            $rootScope.notificationProperties = {
                title: "Blokk létrehozása...",
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };
            ApiFactory.postFunction('articles/' + articleId + '/paragraphs', paragraph).then(function (data) {
                self.paragraphs[indexNumber] = data;
                isCreateParagraph = false;
                $rootScope.notificationProperties = {
                    title: "Blokk létrehozva",
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };
            });
        }
        this.updateParagraph = function (paragraph) {
            $rootScope.notificationProperties = {
                title: "Blokk frissítése...",
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };
            if (paragraph.type === "video") {
                var match = paragraph.content.match(/=(.+)$/);
                if (match !== null) {
                    paragraph.content = match[1];
                }
            }
            return ApiFactory.patchFunction('articles/' + self.article.id + '/paragraphs/' + paragraph.id, paragraph).then(function (data) {
                $rootScope.notificationProperties = {
                    title: "Blokk frissítve",
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };
            });
        };
        this.deleteParagraph = function (paragraph, callback) {
            $confirm({
                title: 'Megerősítés',
                text: 'Biztosan törli a "' + paragraph.content + '" elemet?'
            })
                .then(function () {
                var delIndex;
                if (self.article.paragraphs === undefined) {
                    delIndex = self.paragraphs.indexOf(paragraph);
                    self.paragraphs.splice(delIndex, 1);
                    return;
                }
                delIndex = self.paragraphs.indexOf(paragraph);
                ApiFactory.deleteFunction('articles/' + self.article.id + '/paragraphs/' + paragraph.id).then(function (data) {
                    self.paragraphs.splice(delIndex, 1);
                    callback();
                });
            });
        };
        this.getAttributes = function (callback) {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('attributes').then(callback).finally(function () {
                spinnerService.decrementLoading();
            });
        };
        this.getTags = function (callback) {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('tags').then(callback).finally(function () {
                spinnerService.decrementLoading();
            });
        };
        this.onChangeTileConfig = function (config) {
            if (self.article === undefined || isLoaded || self.article.id === null) {
                return;
            }
            self.article.color = config.tileColorRadioCheckValue;
            if (config.fileId && !config.tileTypeRadioCheckValue) {
                self.deleteFile(config.fileId, function () {
                    updateArticle(self.article);
                    config.callback();
                });
                return;
            }
            else if (!config.fileId && !config.tileTypeRadioCheckValue) {
                updateArticle(self.article);
                return;
            }
            if (!articleId) {
                images = config.croppedImages;
            }
            else {
                initImages = false;
                images = config.croppedImages;
                setImagesForArticle();
            }
        };
        this.onChangeBgConfig = function (config) {
            if (self.article === undefined || isLoaded) {
                return;
            }
            self.article.cover_color = config.tileColorRadioCheckValue;
            if (config.tileColorRadioCheckValue !== null && config.radioChecked === 0) {
                coverImage.pivot.disabled = true;
                if (config.fileId) {
                    self.deleteFile(config.fileId, function () {
                        updateArticle(self.article);
                        config.callback();
                    });
                }
            }
            else if (coverImage.id !== null) {
                coverImage.pivot.disabled = false;
                coverImage.pivot.left = config.bounds.left;
                coverImage.pivot.top = config.bounds.top;
                coverImage.pivot.right = config.bounds.right;
                coverImage.pivot.bottom = config.bounds.bottom;
                setImagesForArticle();
            }
        };
        this.onChangeBlockPosition = function (blocks) {
            self.article.paragraphs = blocks;
        };
        this.fileUpload = function (file, type, index) {
            var indexNumber = parseInt(index);
            spinnerService.incrementLoading();
            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': file,
                    'directory': ""
                }
            }).then(function (response) {
                self.uploadedFileProperties = {
                    type: type,
                    data: response.data,
                    index: indexNumber
                };
                if (type === "paragraph") {
                    self.paragraphs[indexNumber].image_id = response.data.id;
                    delete self.paragraphs[indexNumber].image;
                    if (articleId) {
                        createParagraph(self.paragraphs[indexNumber], articleId, indexNumber);
                    }
                }
                else if (type === "cover") {
                    coverImage.full_url = response.data.full_url;
                    coverImage.url = response.data.url;
                    coverImage.id = response.data.id;
                }
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            }).finally(function () { return spinnerService.decrementLoading(); });
        };
        this.deleteFile = function (id, callback) {
            isDelete = true;
            self.article.images.forEach(function (image, index) {
                if (image.id === id) {
                    var delNumber = (image.pivot.type === "cover") ? 1 : 4;
                    if (image.pivot.type === "cover") {
                        coverImage.id = null;
                    }
                    self.article.images.splice(index, delNumber);
                }
            });
            ApiFactory.deleteFunction('images/' + id).then(function () {
                if (typeof callback === "function") {
                    callback();
                }
            });
        };
        this.updateOrder = function (movedParagraph, index) {
            movedParagraph.order = index + 1;
            spinnerService.incrementLoading();
            self.updateParagraph(movedParagraph).finally(function () { return spinnerService.decrementLoading(); });
        };
        this.updateShow = function (value) {
            self.article.show_title = value;
        };
        this.duplicateArticle = function (callbackDuplicate) {
            self.article.duplicate = "mobile";
            var prepareNotify = "Duplikálás...";
            var successNotify = "Duplikálva";
            $rootScope.notificationProperties = {
                title: prepareNotify,
                timeout: function (callback, time) {
                    $timeout(callback, time);
                }
            };
            ApiFactory.patchFunction('articles/' + self.article.id, self.article).then(function (data) {
                delete self.article.duplicate;
                $rootScope.notificationProperties = {
                    title: successNotify,
                    timeout: function (callback, time) {
                        $timeout(callback, time);
                    }
                };
                callbackDuplicate();
            });
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('LoginController', ['$state', 'LoginFactory', 'PasswordModalFactory', LoginController]);
    function LoginController($state, LoginFactory, PasswordModalFactory) {
        var vm = this;
        vm.login = login;
        vm.resetemail = resetemail;
        vm.loginError = false;
        vm.loginErrorText = '';
        vm.loginErrors = {};
        function login(isValid) {
            if (isValid) {
                var credentials = {
                    email: vm.email,
                    password: vm.password
                };
                LoginFactory.loginFunction(credentials).then(function () {
                    $state.go('home');
                }).catch(function (data) {
                    vm.loginError = true;
                    vm.loginErrorText = data.loginErrorText;
                    vm.loginErrors[data.loginErrorText] = true;
                });
            }
        }
        function resetemail() {
            PasswordModalFactory.openModal({
                title: 'Új jelszó igénylése',
                ok: 'Új jelszó küldése',
                text: 'Kapni fogsz egy levelet a megadott email cimre, amelyben találsz egy linket, amelyen keresztül megadhatod, majd megerősítheted új jelszavadat!',
                cancel: "Mégsem"
            });
        }
    }
})();
/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('ResetPasswordController', ['$state', '$stateParams', 'PasswordFactory', ResetPasswordController]);
    function ResetPasswordController($state, $stateParams, PasswordFactory) {
        var vm = this;
        vm.resetPassword = resetPassword;
        vm.resetError = false;
        vm.loginErrorText = '';
        vm.resetErrors = {};
        function resetPassword() {
            var payload = {
                'password': vm.new_password,
                'password_confirmation': vm.re_new_password
            };
            console.log(payload);
            if ($stateParams['user_id'] && $stateParams['token']) {
                PasswordFactory.resetPassword($stateParams['user_id'], $stateParams['token'], payload);
            }
        }
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('ChatController', ['ChatFactory', '$scope', '$confirm', '$uibModal', '$interval', '$rootScope', '$sce', ChatController]);
    function ChatController(ChatFactory, $scope, $confirm, $uibModal, $interval, $rootScope, $sce) {
        var vm = this;
        vm.user = JSON.parse(localStorage.getItem('user'));
        vm.messages = [];
        vm.selectedTopics = [];
        vm.rating = {};
        vm.sessions = {};
        vm.activeTab = 0;
        vm.hasActiveGuestSession = false;
        vm.isConnected = false;
        vm.count = {
            active: 0,
            auto: [0, 0, 0],
            pending: 0,
            total: 0
        };
        vm.clients = [];
        var ratingModals = {};
        var prevInput = "";
        var element;
        $scope.$on("$destroy", destroy);
        function notify(msg) {
            var beep = document.getElementById('beep');
            beep.pause();
            beep.currentTime = 0;
            beep.play();
            if ("Notification" in window && Notification.permission === "granted") {
                new Notification(msg);
            }
        }
        ChatFactory.getClientId().then(function (id) {
            destroy();
            vm.isConnected = true;
            vm.clientId = id;
            vm.sessions = ChatFactory.getSessions();
            for (var i in vm.sessions) {
                if (vm.sessions.hasOwnProperty(i)) {
                    setUpSession(vm.sessions[i]);
                }
            }
            for (var i in vm.sessions) {
                if (vm.sessions.hasOwnProperty(i) && vm.sessions[i].Type === "channel") {
                    vm.hasActiveGuestSession = true;
                }
            }
            var conversations = ChatFactory.getConversations();
            var clients = ChatFactory.getClients();
            vm.clients = clients.clients;
            vm.count = conversations.count;
            vm.startNewConversation = startNewConversation;
            vm.openConversationWith = openConversationWith;
            vm.joinConversation = joinConversation;
            function startNewConversation() {
                ChatFactory.assignConversation();
            }
            function joinConversation(sessionId) {
                ChatFactory.joinConversation(sessionId);
            }
            function openConversationWith(user) {
                ChatFactory.openConversationWith(user);
            }
            ChatFactory.on("connect", function () {
                vm.isConnected = true;
            });
            ChatFactory.on("disconnect", function (data) {
                vm.sessions = {};
                vm.messages = [];
                vm.isConnected = false;
                vm.count = {
                    active: 0,
                    auto: [0, 0, 0],
                    pending: 0,
                    total: 0
                };
                vm.clients = [];
            });
            ChatFactory.on("clients", function (data) {
                vm.clients = data.clients;
            });
            ChatFactory.on("conversations", function (data) {
                if (vm.user.role === 'helper' && typeof vm.count !== 'undefined') {
                    if (vm.count.pending === 0 && data.count.pending > 0 && !vm.hasActiveGuestSession) {
                        notify("Új várakozó a chaten");
                    }
                }
                vm.count = data.count;
            });
            ChatFactory.on("announcement", function (message) {
                for (var i in vm.sessions) {
                    if (vm.sessions.hasOwnProperty(i)) {
                        vm.sessions[i].announce(message);
                    }
                }
            });
            ChatFactory.on("start", function (session) {
                session["onKeyDown"] = onKeyDown;
                session["keyPressed"] = keyPressed;
                session["textPasted"] = textPasted;
                session["isEnableInput"] = isEnableInput;
                session["onAddEmojiIcon"] = onAddEmojiIcon;
                setUpSession(session);
                vm.sessions[session.Id] = session;
                //setTimeout(() => $anchorScroll(session.Id + "_lastMsg"), 100);
            });
            $interval(function () {
                for (var i in vm.sessions) {
                    if (vm.sessions.hasOwnProperty(i)) {
                        vm.sessions[i].refreshChatTime();
                    }
                }
            }, 1000);
        });
        function setUpSession(session) {
            if (session.Type === "channel") {
                vm.hasActiveGuestSession = true;
            }
            // set up event handlers
            session.on("message", function (msg) {
                /*if (document.hidden && msg.client.id !== vm.clientId) {
                    if (session.Type === "direct" && msg.client.role !== "guest") {
                        notify("Üzenet érkezett tőle: " + msg.client.name);
                    } else if (vm.user.role === 'helper' && session.Type === "channel" && msg.client.role === "guest") {
                        notify("Üzenet érkezett");
                    }
                }*/
            });
            session.on("end", function () {
                if (session.Type === 'channel' && session.Helper.id === vm.clientId) {
                    ratingModals[session.Id] = $uibModal.open({
                        windowClass: 'chat-rating',
                        templateUrl: 'app/layouts/chat-rating-modal.html',
                        controller: 'RatingModalController as rating',
                        backdrop: 'static',
                        keyboard: false
                    });
                    ratingModals[session.Id].result.then(function (data) {
                        ChatFactory.rateConversation(session.Id, data);
                    });
                }
                if (session.Type === "channel") {
                    vm.hasActiveGuestSession = false;
                }
            });
            session.on("userTyping", function (data) {
                ChatFactory.setIsTyping(session.Id, data);
            });
            session.on("sendingMessage", function (data) {
                ChatFactory.sendMessage(session.Id, data);
                session["messageText"] = "";
                if (session["textareaInput"]) {
                    session["textareaInput"].innerHTML = "";
                }
                if (session["messageInput"]) {
                    session["messageInput"] = "";
                }
            });
            session.on("closing", function () {
                $confirm({
                    title: 'Biztos, hogy lezárod a beszélgetést?',
                    text: 'Figyelem! A lezárt beszélgetés tovább már nem folytatható.',
                    cancel: 'Vissza',
                    ok: 'Lezárás'
                }).then(function () {
                    ChatFactory.closeConversation(session.Id);
                });
            });
            session.on("takeover", function () { return ChatFactory.takeOverConversation(session.Id); });
            session.on("giveback", function () { return ChatFactory.giveBackConversation(session.Id); });
            session.on("archive", function () {
                if (ratingModals.hasOwnProperty(session.Id)) {
                    ratingModals[session.Id].dismiss();
                }
                delete vm.sessions[session.Id];
                if (!vm.sessions.hasOwnProperty(vm.activeTab)) {
                    vm.activeTab = Object.keys(vm.sessions).length !== 0 ? Object.keys(vm.sessions)[Object.keys(vm.sessions).length - 1] : 0;
                }
            });
        }
        function destroy() {
            console.log("destroy");
            vm.sessions = {};
            var events = [
                "disconnect",
                "clients",
                "conversations",
                "announcement",
                "start"
            ];
            for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                var event_1 = events_1[_i];
                console.log("removing listener " + event_1);
                ChatFactory.off(event_1);
            }
        }
        function onKeyDown($event) {
            if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter" || $event.keyIdentifier === "Enter") && $event.ctrlKey === false && $event.shiftKey === false) {
                $event.preventDefault();
                this.isEnterKey = true;
            }
        }
        function keyPressed($event, session) {
            session.textareaInput = $event.target;
            if (this.isEnterKey) {
                $event.preventDefault();
                ChatFactory.sendMessage(session.id, session.messageInput);
                session.messageText = "";
                session.messageInput = "";
                $event.target.innerHTML = "";
                delete this.isEnterKey;
            }
            else {
                var replaceText = $event.target.innerHTML.replace(/(<img src=".[\/a-z0-9\."\s=]+)(:[a-z_]+:)(">)/g, '$2');
                replaceText = replaceText.replace(/<br>/g, "\n");
                replaceText = replaceText.replace(/&nbsp;/g, "");
                replaceText = replaceText.replace(/&lt;/g, "<");
                replaceText = replaceText.replace(/&gt;/g, ">");
                session.messageInput = replaceText;
            }
            if (session.prevMsg.length === 0 && session.messageInput.length > 0) {
                session.trigger("userTyping", true);
            }
            else if (session.prevMsg.length > 0 && session.messageInput.length === 0) {
                session.trigger("userTyping", false);
            }
            session.prevMsg = session.messageInput;
        }
        function textPasted($event) {
            $event.preventDefault();
            document.execCommand("insertHTML", false, $event.clipboardData.getData('text/plain'));
        }
        function onAddEmojiIcon() {
            var text = arguments[0];
            var session = arguments[1];
            var searchName = text.replace(/[:]/g, "");
            var selectedEmojiObj = $rootScope.emojis[searchName];
            var imgHtml = $sce.trustAsHtml("<img src=\"./assets/img/emojis/" + selectedEmojiObj.unicode + ".svg\" alt=\"" + text + "\">");
            session.messageText = session.messageInput;
            if (session.messageText !== "") {
                session.messageText = session.messageText.replace(/([:][a-z_]+[:])/g, function () {
                    var texts = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        texts[_i - 0] = arguments[_i];
                    }
                    searchName = texts[0].replace(/[:]/g, "");
                    selectedEmojiObj = $rootScope.emojis[searchName];
                    texts[1] = $sce.trustAsHtml("<img src=\"./assets/img/emojis/" + selectedEmojiObj.unicode + ".svg\" alt=\"" + texts[0] + "\">");
                    return texts[1];
                });
                session.messageText = session.messageText.replace(/<br>/g, "\n");
            }
            session.messageInput += text + ' ';
            session.messageText += imgHtml;
            session.isShowEmojiPicker = false;
        }
        function isEnableInput() {
            return (!vm.isConnected) ? false : true;
        }
    }
})();
angular.module('Yelon').controller('RatingModalController', ['$uibModalInstance', 'ApiFactory', RatingModalController]);
function RatingModalController($uibModalInstance, ApiFactory) {
    var vm = this;
    ApiFactory.getFunction('chattopics').then(function (data) {
        vm.topics = data;
    });
    vm.sendRating = function () {
        var ratingValues = {
            starred: typeof vm.rating.isStarred === "undefined" ? false : vm.rating.isStarred,
            topics: [],
            comment: vm.rating.comment
        };
        for (var id in vm.rating.selectedTopics) {
            if (vm.rating.selectedTopics.hasOwnProperty(id)) {
                ratingValues.topics.push(id);
            }
        }
        $uibModalInstance.close(ratingValues);
    };
}
(function () {
    'use strict';
    angular.module('Yelon').controller('AutoMessagesController', ['ApiFactory', '$timeout', '$scope', 'SidebarService', 'spinnerService', 'apiPrefix', 'Upload', '$confirm', AutoMessagesController]);
    function AutoMessagesController(ApiFactory, $timeout, $scope, SidebarService, spinnerService, apiPrefix, Upload, $confirm) {
        var vm = this, autoTimeout = null, welcomeTimeout = null, endTimeout = null;
        vm.addAutoMsg = function () { return vm.auto_messages.push({}); };
        vm.removeAutoMsg = function (idx) { return vm.auto_messages.splice(idx, 1); };
        vm.timerOptions = [];
        for (var i = 0; i <= 600; i += 10) {
            vm.timerOptions.push({
                name: i + " másodperc",
                value: i * 1000
            });
        }
        vm.errors = {};
        vm.picFile = false;
        vm.isUploadedPicFile = false;
        var _picFileId;
        var deleteImage = function (id, callback) {
            if (callback === void 0) { callback = null; }
            ApiFactory.deleteFunction('images/' + id).then(function () {
                ApiFactory.patchFunction('settings/closed_image', {
                    value: null
                }).then(function () {
                    delete vm.picFileUrl;
                    delete vm.picFile;
                    vm.isUploadedPicFile = false;
                    if (callback !== null) {
                        callback();
                    }
                });
            });
        };
        vm.upload = function () {
            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': vm.picFile,
                    'directory': ""
                }
            }).then(function (resp) {
                ApiFactory.patchFunction('settings/closed_image', {
                    value: resp.data.id
                }).then(function () {
                    vm.picFileUrl = resp.data.full_url;
                    vm.picFileId = resp.data.id;
                    if (_picFileId) {
                        deleteImage(_picFileId);
                    }
                });
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
        vm.deleteWithConfirm = function () {
            $confirm({
                title: 'Megerősítés',
                text: 'Biztosan törlöd?'
            })
                .then(function () {
                deleteImage(_picFileId, function () {
                    _picFileId = undefined;
                });
            });
        };
        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.auto_messages = data.auto_messages === null ? [{}, {}, {}] : data.auto_messages;
            //vm.auto_messages = [{},{},{}];
            vm.welcome_message = data.welcome_message;
            vm.end_message = data.end_message;
            vm.closed_image = data.closed_image;
            if (vm.closed_image !== null) {
                vm.picFileId = vm.closed_image;
                _picFileId = vm.closed_image;
                ApiFactory.getFunction('images/' + _picFileId).then(function (data) {
                    vm.picFileUrl = data.full_url;
                    vm.isUploadedPicFile = true;
                });
            }
            //vm.watchInputs();
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        $scope.$watch('autoMessagesCtrl.auto_messages', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                if (autoTimeout !== null) {
                    $timeout.cancel(autoTimeout);
                }
                autoTimeout = $timeout(function () {
                    vm.errors = {};
                    ApiFactory.patchFunction('settings/auto_messages', {
                        value: newVal
                    }).catch(function (data) {
                        Object.keys(data.data.errors).forEach(function (key) {
                            var res = key.match(/value.([0-9]+).([a-z]+)/i);
                            if (typeof vm.errors[res[1]] === "undefined") {
                                vm.errors[res[1]] = [];
                            }
                            vm.errors[res[1]].push(res[2]);
                        });
                    });
                }, 3000);
            }
        }, true);
        $scope.$watch('autoMessagesCtrl.welcome_message', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                console.log(oldVal, newVal);
                if (welcomeTimeout !== null) {
                    $timeout.cancel(welcomeTimeout);
                }
                welcomeTimeout = $timeout(function () {
                    ApiFactory.patchFunction('settings/welcome_message', {
                        value: newVal
                    });
                }, 3000);
            }
        }, true);
        $scope.$watch('autoMessagesCtrl.end_message', function (newVal, oldVal) {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                console.log(oldVal, newVal);
                if (endTimeout !== null) {
                    $timeout.cancel(endTimeout);
                }
                endTimeout = $timeout(function () {
                    ApiFactory.patchFunction('settings/end_message', {
                        value: newVal
                    });
                }, 3000);
            }
        }, true);
    }
})();
/**
 * Created by danielszenasi on 10/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('BusinessHoursController', ['ApiFactory', 'SidebarService', '$scope', '$timeout', '$filter', 'spinnerService', BusinessHoursController]);
    function BusinessHoursController(ApiFactory, SidebarService, $scope, $timeout, $filter, spinnerService) {
        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();
        var closedMessageTimeout = null, closingMessageTimeout = null;
        var vm = this;
        vm.saveBusinessHours = saveBusinessHours;
        vm.dayNames = [
            "Hétfő",
            "Kedd",
            "Szerda",
            "Csütörtök",
            "Péntek",
            "Szombat",
            "Vasárnap"
        ];
        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.closed_message = data.closed_message;
            vm.closing_message = data.closing_message;
            vm.business_hours = data.business_hours === null ? [{}, {}, {}, {}, {}, {}, {}] : data.business_hours;
            var sunday = vm.business_hours.shift();
            vm.business_hours.push(sunday);
            watchInputs();
        }).catch(function () { return vm.business_hours = [{}, {}, {}, {}, {}, {}, {}]; })
            .finally(function () {
            spinnerService.decrementLoading();
        });
        function watchInputs() {
            $scope.$watch('businessHoursCtrl.closed_message', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (closedMessageTimeout) {
                        $timeout.cancel(closedMessageTimeout);
                    }
                    closedMessageTimeout = $timeout(function () {
                        ApiFactory.patchFunction('settings/' + 'closed_message', { value: newVal }).then(function () {
                        });
                    }, 2000);
                }
            }, true);
            $scope.$watch('businessHoursCtrl.closing_message', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (closingMessageTimeout) {
                        $timeout.cancel(closingMessageTimeout);
                    }
                    closingMessageTimeout = $timeout(function () {
                        ApiFactory.patchFunction('settings/' + 'closing_message', { value: newVal }).then(function () {
                        });
                    }, 2000);
                }
            }, true);
        }
        function saveBusinessHours() {
            $timeout(function () {
                var tmpArr = [];
                angular.copy(vm.business_hours, tmpArr);
                var sunday = tmpArr.pop();
                tmpArr.unshift(sunday);
                ApiFactory.patchFunction('settings/business_hours', { value: tmpArr });
                //console.log(vm.business_hours);
            }, 1000);
        }
    }
})();
/**
 * Created by danielszenasi on 09/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('ChatTopicsController', ['ApiFactory', 'SidebarService', 'spinnerService', ChatTopicsController]);
    function ChatTopicsController(ApiFactory, SidebarService, spinnerService) {
        var vm = this;
        vm.topics = [];
        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('chattopics').then(function (data) {
            angular.forEach(data, function (value, key) {
                var item = { id: value.id, text: value.title };
                vm.topics.push(item);
            });
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        vm.topicAdded = function ($tag) {
            ApiFactory.postFunction('chattopics', { 'title': $tag.text }).then(function (data) {
                $tag.id = data.id;
            });
        };
        vm.topicRemoved = function ($tag) {
            ApiFactory.deleteFunction('chattopics/' + $tag.id).then(function () { });
        };
    }
})();
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            onAddEmojiIcon: '<',
            session: '='
        },
        controller: controllerCtrl,
        templateUrl: 'app/Emojipicker/template.html',
        transclude: true
    };
    function controllerCtrl() {
        var _this = this;
        this.$onInit = function () {
            _this.emoji_icons = [":grinning:", ":grimacing:", ":grin:", ":joy:", ":smiley:", ":smile:", ":sweat_smile:", ":laughing:", ":innocent:", ":wink:", ":blush:", ":relaxed:", ":yum:", ":relieved:", ":heart_eyes:", ":kissing_heart:", ":kissing:", ":kissing_smiling_eyes:", ":kissing_closed_eyes:", ":stuck_out_tongue_winking_eye:", ":stuck_out_tongue_closed_eyes:", ":stuck_out_tongue:", ":sunglasses:", ":no_mouth:", ":pensive:", ":confused:", ":cry:"];
        };
    }
    angular
        .module('Yelon')
        .component('emojiPicker', component);
})(Yelon || (Yelon = {}));
/**
 * Created by danielszenasi on 08/08/16.
*/
(function () {
    'use strict';
    angular.module('Yelon').controller('HomeController', ['$auth', 'SidebarService', '$rootScope', 'ApiFactory', HomeController]);
    function HomeController($auth, SidebarService, $rootScope, ApiFactory) {
        var vm = this;
        var user = JSON.parse(localStorage.getItem('user'));
        SidebarService.loadPreset(user.role);
        if (SidebarService.isDisabled && !$rootScope.isSubscribeOnClick) {
            $rootScope.$on("onClickedPopup", function () {
                $rootScope.popupModel = {
                    title: null,
                    message: null,
                    url: null
                };
                $rootScope.showPopupForm = true;
            });
            $rootScope.isSubscribeOnClick = true;
        }
        SidebarService.enable();
        if (!$rootScope.onSubmitPopupForm) {
            $rootScope.onSubmitPopupForm = function (event) {
                ApiFactory.postFunction("notifications", $rootScope.popupModel).then(function (result) {
                    delete $rootScope.showPopupForm;
                });
            };
            $rootScope.onClosePopupForm = function (event) {
                event.preventDefault();
                delete $rootScope.showPopupForm;
            };
        }
    }
})();
/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('PagesController', ['ApiFactory', 'SidebarService', PagesController]);
    function PagesController(ApiFactory, SidebarService) {
        var vm = this;
        SidebarService.loadPreset('pages');
        SidebarService.enable();
    }
})();
/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('NewPasswordController', ['$state', 'PasswordFactory', NewPasswordController]);
    function NewPasswordController($state, PasswordFactory) {
        var vm = this;
        vm.newPassword = newPassword;
        function newPassword() {
            var payload = {
                'old_password': vm.current_password,
                'password': vm.new_password,
                'password_confirmation': vm.re_new_password
            };
            console.log(payload);
            PasswordFactory.newPassword(payload);
        }
    }
})();
/**
 * Created by danielszenasi on 15/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('UsersController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', 'roleNames', UsersController]);
    function UsersController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm, roleNames) {
        var vm = this;
        vm.roleNames = roleNames;
        SidebarService.clearSidebarItems();
        var orderBy = $filter('orderBy');
        vm.attributes = {};
        vm.order = order;
        vm.to = null;
        vm.predicate = 'title';
        vm.reverse = true;
        vm.pagedUsers = [];
        vm.numPerPage = 10;
        vm.lastPage = 10;
        vm.maxSize = 5;
        vm.users = [];
        init();
        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('users').then(function (data) {
                vm.users = data;
                vm.currentPage = 1;
                order('name');
                vm.user_role = "helper";
            }).finally(function () {
                spinnerService.decrementLoading();
            });
        }
        vm.createUser = function () {
            ApiFactory.postFunction('users', {
                'name': vm.user_name,
                'role': vm.user_role,
                'email': vm.user_email
            }).then(function (data) {
                vm.users.push(data);
                vm.users = orderBy(vm.users, vm.predicate, vm.reverse);
                paginate();
                alert('Sikeres hozzáadás!');
            }).catch(function () {
                alert("Hozzáadás sikertelen. Lehet, hogy már regisztrált e-mail?");
            });
        };
        function paginate(emptyAllowed) {
            if (emptyAllowed === void 0) { emptyAllowed = false; }
            if (!emptyAllowed && vm.users.length === 0) {
                return;
            }
            var begin = ((vm.currentPage - 1) * vm.numPerPage), end = begin + vm.numPerPage;
            vm.pagedUsers = vm.users.slice(begin, end);
        }
        $scope.$watch("usersCtrl.currentPage", paginate);
        function order(predicate) {
            vm.predicate = predicate;
            vm.reverse = (vm.predicate === predicate) ? !vm.reverse : false;
            vm.users = orderBy(vm.users, predicate, vm.reverse);
            paginate();
        }
        //delete item
        vm.deleteItem = function (user) {
            $confirm({ title: 'Megerősítés', text: 'Biztosan törli a "' + user.name + '" elemet?' })
                .then(function () {
                var delIndex = vm.users.indexOf(user);
                ApiFactory.deleteFunction('users/' + user.id).then(function () {
                    vm.users.splice(delIndex, 1);
                    paginate();
                });
            });
        };
    }
})();
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            notifyProperties: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/components/notification.html',
        transclude: true
    };
    function controllerCtrl() {
        this.$onChanges = function (changes) {
            var _this = this;
            if (changes.notifyProperties) {
                var properties = changes.notifyProperties.currentValue;
                if (properties !== undefined) {
                    if (!this.notifies) {
                        this.notifies = [properties];
                    }
                    else {
                        this.notifies.push(properties);
                    }
                    var length_1 = this.notifies.length - 1;
                    var lastNotify_1 = this.notifies[length_1];
                    lastNotify_1.timeout(function () {
                        lastNotify_1.showClass = "show";
                        _this.notifies.forEach(function (notify) {
                            if (!notify.isHide) {
                                notify.isHide = true;
                                notify.timeout(function () {
                                    delete notify.showClass;
                                    notify.timeout(function () {
                                        _this.notifies.splice(0, 1);
                                    }, 1000);
                                }, 2000);
                            }
                        });
                    }, 0);
                }
            }
        };
    }
    angular
        .module('Yelon')
        .component('notification', component);
})(Yelon || (Yelon = {}));
(function () {
    'use strict';
    angular.module('Yelon').directive("charLimit", charLimit);
    function charLimit() {
        return {
            restrict: "A",
            scope: {
                charLimit: '='
            },
            link: function (scope, elem, attrs) {
                scope.keycodes = [8, 37, 38, 39, 40, 46];
                elem.bind('keyup', function (event) {
                    var element = elem.parent();
                    //element.toggleClass('warning', scope.charLimit - elem.val().length <= 10);
                    element.toggleClass('has-error', elem.val().length >= scope.charLimit);
                });
                elem.bind('keypress', function (event) {
                    // Once the limit has been met or exceeded, prevent all keypresses from working
                    if (elem.val().length >= scope.charLimit) {
                        // Except backspace, delete, and arrow keys.
                        if (scope.keycodes.indexOf(event.keyCode) === -1) {
                            event.preventDefault();
                        }
                    }
                });
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("elastic", ['$timeout', elastic]);
    function elastic($timeout) {
        return {
            restrict: "A",
            link: function ($scope, element, attrs) {
                $scope.initialHeight = $scope.initialHeight || element[0].style.height;
                var resize = function () {
                    element[0].style.height = $scope.initialHeight;
                    element[0].style.height = "" + (element[0].scrollHeight + 3) + "px";
                };
                element.on("input change", resize);
                $timeout(resize, 0);
            }
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("fileread", [fileread]);
    function fileread() {
        return {
            scope: {
                fileread: "=",
                upload: "<",
                fileType: "@",
                fileIndex: "@?"
            },
            link: function (scope, element, attributes) {
                var el = (element[0].tagName === "INPUT") ? element : element.children("input");
                el.bind("change", function (event) {
                    scope.$apply(function () {
                        scope.fileread = event.target.files[0];
                        scope.upload(event.target.files[0], scope.fileType, scope.fileIndex);
                    });
                });
            }
        };
    }
})();
/**
 * Created by csoma on 2016. 09. 22..
 */
(function () {
    'use strict';
    angular.module('Yelon').directive("nullable-date", function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.nullableDate = function (modelValue, viewValue) {
                    if (typeof modelValue === "undefined") {
                        return false;
                    }
                    else if (modelValue === null) {
                        return true;
                    }
                    else {
                        return !isNaN(Date.parse(modelValue));
                    }
                };
            }
        };
    });
})();
/**
 * Created by fures on 2016. 08. 10..
 */
//import IRootScopeService = angular.IRootScopeService;
(function () {
    'use strict';
    angular.module('Yelon').directive('sidebar', ['$rootScope', sidebar]);
    function sidebar($rootScope) {
        return {
            restrict: 'A',
            scope: false,
            replace: true,
            templateUrl: "app/layouts/sidebar.html",
            controller: ['$scope', 'SidebarService', function ($scope, SidebarService) {
                    $scope['sidebarItemService'] = SidebarService;
                    $rootScope.$on('$stateChangeStart', function () {
                        $scope['sidebarItemService'].clearSidebarItems();
                    });
                    $scope["onClickPopupItem"] = function (event) {
                        event.preventDefault();
                        $rootScope.$emit("onClickedPopup");
                    };
                }],
            link: function () {
            }
        };
    }
})();
/**
 * Created by ekema on 2016. 04. 01..
 */
(function () {
    'use strict';
    angular.module('Yelon').directive("spinner", ['spinnerService', spinner]);
    function spinner(spinnerService) {
        return {
            restrict: 'A',
            templateUrl: 'app/layouts/spinner.html',
            scope: false,
            replace: true,
            controller: ['$scope', '$timeout', function ($scope, $timeout) {
                    $scope['showSpinner'] = spinnerService.showSpinner;
                    $scope.$watch(function () {
                        return spinnerService.showSpinner;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            if (newValue) {
                                $scope['showSpinner'] = spinnerService.showSpinner;
                            }
                            else {
                                $timeout(function () {
                                    $scope['showSpinner'] = spinnerService.showSpinner;
                                }, 500);
                            }
                        }
                    }, true);
                }]
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').directive("superTagInput", superTagInput);
    function superTagInput() {
        return {
            restrict: "E",
            scope: {
                label: '@',
                options: '=',
                tags: '=',
                autocompleteOnly: '=?',
                displayProperty: '@?',
                minTags: '=',
                maxTags: '=?'
            },
            templateUrl: 'app/layouts/super-tag-input.html',
            controller: ['$scope', '$filter', function ($scope, $filter) {
                    $scope.displayProperty = typeof $scope.displayProperty === "undefined" ? "title" : $scope.displayProperty;
                    $scope.filteredOptions = [];
                    $scope.selectedValue = null;
                    if (typeof $scope.maxTags === "undefined") {
                        $scope.maxTags = 100;
                    }
                    $scope.findTags = function ($query) {
                        return $scope.filteredOptions.filter(function (val) {
                            return val[$scope.displayProperty].toLowerCase().indexOf($query.toLowerCase()) !== -1;
                        });
                    };
                    $scope.addTag = function () {
                        if ($scope.selectedValue === null) {
                            return;
                        }
                        $scope.tags.push($scope.selectedValue);
                    };
                    $scope.filterOptions = function () {
                        if (typeof ($scope.options) === "undefined") {
                            $scope.filteredOptions = [];
                            return;
                        }
                        $scope.filteredOptions = $scope.options.filter(function (option) {
                            var found = false;
                            angular.forEach($scope.tags, function (tag, key) {
                                if (found) {
                                    return;
                                }
                                if (tag.id === option.id && tag[$scope.displayProperty] === option[$scope.displayProperty]) {
                                    found = true;
                                }
                            });
                            return !found;
                        });
                    };
                }],
            link: function (scope, element, attrs) {
                scope.filterOptions();
                scope.autocompleteOnly = angular.isDefined(scope.autocompleteOnly) ? scope.autocompleteOnly : true;
                scope.$watch('tags', function (newValue, oldValue) {
                    if (newValue) {
                        scope.filterOptions();
                    }
                }, true);
                scope.$watch('options', function (newValue, oldValue) {
                    scope.filterOptions();
                }, true);
            },
        };
    }
})();
/**
 * Created by danielszenasi on 17/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').directive("timepickerpopup", timepickerPopup);
    function timepickerPopup() {
        return {
            scope: {
                timeData: '=timepickerpopup',
                timeUpdateCallback: '&'
            },
            templateUrl: 'app/layouts/timepicker-popup.html',
            controller: ['$scope', function ($scope) {
                    $scope.timepickerOptions = {
                        readonlyInput: true,
                        showMeridian: false
                    };
                    $scope.buttonBar = {
                        show: false
                    };
                    $scope.checkClosed = function () {
                        $scope.closed = ($scope.timeData.from === "00:00" && $scope.timeData.to === "00:00");
                    };
                    $scope.checkClosed();
                    // Perfectly chosen nails: I literally had no other choice to get it right with the new settings table, without rewriting the whole module (although it should be rewritten...)
                    $scope.updateFrom = function () {
                        $scope.checkClosed();
                        if ($scope.from.date !== null) {
                            $scope.timeData.from = ("0" + $scope.from.date.getHours()).slice(-2) + ':' + ("0" + $scope.from.date.getMinutes()).slice(-2);
                        }
                        else {
                            $scope.timeData.from = "00:00";
                        }
                        $scope.timeUpdateCallback();
                    };
                    $scope.updateTo = function () {
                        $scope.checkClosed();
                        if ($scope.to.date !== null) {
                            $scope.timeData.to = ("0" + $scope.to.date.getHours()).slice(-2) + ':' + ("0" + $scope.to.date.getMinutes()).slice(-2);
                        }
                        else {
                            $scope.timeData.to = "00:00";
                        }
                        $scope.timeUpdateCallback();
                    };
                    $scope.toggleClosed = function () {
                        if ($scope.closed) {
                            $scope.from.date = null;
                            $scope.updateFrom();
                            $scope.to.date = null;
                            $scope.updateTo();
                        }
                    };
                    $scope.openCalendar = function (e, picker) {
                        if (!$scope[picker]) {
                            $scope.to = {
                                date: new Date(1970, 0, 1, 16, 0, 0),
                            };
                            $scope.from = {
                                date: new Date(1970, 0, 1, 8, 0, 0),
                            };
                            $scope.updateFrom();
                            $scope.updateTo();
                        }
                        $scope[picker].open = true;
                    };
                }],
            link: function (scope, element, attrs) {
                scope.$watch('timeData', function (newValue, oldValue) {
                    if (newValue) {
                        if (newValue.from === null && newValue.to === null) {
                            scope.closed = true;
                        }
                        else {
                            var array = [];
                            if (newValue.to) {
                                array = newValue.to.split(":");
                                scope.to = {
                                    date: new Date(1970, 0, 1, array[0], array[1], 0),
                                };
                            }
                            if (newValue.from) {
                                array = newValue.from.split(":");
                                scope.from = {
                                    date: new Date(1970, 0, 1, array[0], array[1], 0),
                                };
                            }
                        }
                    }
                }, true);
            },
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').factory('ApiFactory', ['$http', '$q', 'apiPrefix', '$rootScope', 'FileSaver', 'Blob', ApiFactory]);
    function ApiFactory($http, $q, apiPrefix, $rootScope, FileSaver, Blob) {
        return {
            getFunction: getFunction,
            downloadFunction: downloadFunction,
            postFunction: postFunction,
            patchFunction: patchFunction,
            deleteFunction: deleteFunction,
        };
        function getFunction(url) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }
            return $http.get(apiPrefix + url).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function downloadFunction(url) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }
            return $http.get(apiPrefix + url, { responseType: 'arraybuffer' }).success(success).catch(returnError);
            function success(data, status, headers) {
                var header = headers('Content-Disposition'), filename = url.split('/').reverse()[0];
                if (header) {
                    header = header.split(';')[1].trim().split('=')[1].replace(new RegExp('"', 'g'), '');
                    if (header.length > 0) {
                        filename = header;
                        console.log(filename);
                    }
                }
                var blob = new Blob([data], { type: headers('Content-Type') });
                FileSaver.saveAs(blob, filename);
                return data.data;
            }
            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen lekérdezés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function postFunction(url, data, settings) {
            return $http.post(apiPrefix + url, data, settings).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function patchFunction(url, data, settings) {
            return $http.patch(apiPrefix + url, data, settings).then(success).catch(returnError);
            function success() {
                $rootScope.lastSave = new Date();
                return true;
            }
            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen módosítás, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        function deleteFunction(url) {
            for (var i = 1; i < arguments.length; i++) {
                url += "/" + arguments[i];
            }
            return $http.delete(apiPrefix + url).then(success).catch(returnError);
            function success(data) {
                return angular.fromJson(data.data);
            }
            function returnError(response) {
                if (handleError(response)) {
                    alert('Sikertelen törlés, kérlek próbáld újra! Hibakód: ' + response.status);
                }
                return $q.reject(response);
            }
        }
        /**
         *
         * @param response
         * @returns {boolean} should the caller show the alert window?
         */
        function handleError(response) {
            if (response.status === 500) {
                return true;
            }
            //expired token
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    alert('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }
            //other message
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }
            return true;
        }
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').factory('ChatFactory', ['socketFactory', '$auth', '$q', 'chatPrefix', ChatFactory]);
    function ChatFactory(socketFactory, $auth, $q, chatPrefix) {
        var service = this;
        var handlers = {};
        var clients = {};
        var conversations = {};
        service.ioSocket = io(chatPrefix, { autoConnect: false });
        service.isLoggedIn = false;
        service.clientId = null;
        // Initializing public functions
        service.init = init;
        service.on = on;
        service.off = off;
        service.assignConversation = assignConversation;
        service.openConversationWith = openConversationWith;
        service.joinConversation = joinConversation;
        service.takeOverConversation = takeOverConversation;
        service.giveBackConversation = giveBackConversation;
        service.sendMessage = sendMessage;
        service.closeConversation = closeConversation;
        service.rateConversation = rateConversation;
        service.getClientId = getClientId;
        service.getClients = getClients;
        service.getConversations = getConversations;
        service.getSessions = getSessions;
        service.setIsTyping = setIsTyping;
        service.disconnect = disconnect;
        service.socket = socketFactory({
            prefix: '',
            ioSocket: service.ioSocket
        });
        var sessions = {};
        return service;
        function init() {
            service.socket.removeAllListeners();
            //Setting up event handlers
            service.socket.on('connect', function () {
                trigger("connect");
                service.socket.on('token:validated', function (data) {
                    service.isLoggedIn = true;
                    service.clientId = data.id;
                    // Ask for notification permission in advance
                    if ("Notification" in window && Notification.permission !== 'granted' && Notification.permission !== 'denied') {
                        Notification.requestPermission();
                    }
                });
                service.socket.on('chat:clients', function (data) {
                    clients = data;
                    trigger("clients", data);
                });
                service.socket.on('chat:conversations', function (data) {
                    conversations = data;
                    trigger("conversations", data);
                });
                service.socket.on("chat:start", chatStarted);
                service.socket.on("chat:announcement", function (data) {
                    console.log("Announcement received: ", data);
                    trigger("announcement", data.message);
                });
                //let each session handle its own events
                service.socket.on("chat:handover", function (data) {
                    propagateSessionEvent("handover", data);
                });
                service.socket.on("chat:end", function (data) {
                    propagateSessionEvent("end", data);
                });
                service.socket.on("chat:join", function (data) {
                    propagateSessionEvent("join", data);
                });
                service.socket.on("chat:leave", function (data) {
                    propagateSessionEvent("leave", data);
                });
                service.socket.on("chat:message", function (data) {
                    propagateSessionEvent("message", data);
                });
                service.socket.on("chat:typing", function (data) {
                    propagateSessionEvent("typing", data);
                });
                service.socket.on("chat:archive", function (data) {
                    propagateSessionEvent("archive", data);
                });
                service.socket.emit('token:validate', $auth.getToken());
            });
            service.socket.on('disconnect', function (data) {
                sessions = {};
                clients = {};
                conversations = {};
                service.isLoggedIn = false;
                service.clientId = null;
                service.socket.removeAllListeners('chat:clients');
                service.socket.removeAllListeners('chat:conversations');
                service.socket.removeAllListeners('chat:start');
                service.socket.removeAllListeners('chat:announcement');
                service.socket.removeAllListeners('chat:handover');
                service.socket.removeAllListeners('chat:end');
                service.socket.removeAllListeners('chat:join');
                service.socket.removeAllListeners('chat:leave');
                service.socket.removeAllListeners('chat:message');
                service.socket.removeAllListeners('chat:typing');
                service.socket.removeAllListeners('chat:archive');
                service.socket.removeAllListeners('token:validated');
                trigger('disconnect');
            });
            service.ioSocket.open();
        }
        //Service event handling
        function on(type, handler) {
            if (handlers.hasOwnProperty(type)) {
                handlers[type].push(handler);
            }
            else {
                handlers[type] = [handler];
            }
        }
        function off(type, handler) {
            if (handlers.hasOwnProperty(type)) {
                if (typeof handler === "undefined") {
                    handlers[type] = [];
                }
                handlers[type] = handlers[type].filter(function (h) { return h !== handler; });
            }
        }
        function trigger(type, data) {
            if (handlers.hasOwnProperty(type)) {
                handlers[type].forEach(function (h) { return h(data); });
            }
        }
        function sendMessage(sessionId, message) {
            message = message.replace(/<div>/g, "");
            message = message.replace(/<\/div>/g, "\n");
            service.socket.emit("chat:message", sessionId, message);
        }
        function assignConversation() {
            service.socket.emit('chat:assign');
        }
        function openConversationWith(user) {
            service.socket.emit('chat:create', user.id);
        }
        function joinConversation(sessionId) {
            service.socket.emit("chat:join", sessionId);
        }
        function takeOverConversation(sessionId) {
            service.socket.emit("chat:takeover", sessionId);
        }
        function giveBackConversation(sessionId) {
            service.socket.emit("chat:giveback", sessionId);
        }
        function closeConversation(sessionId) {
            service.socket.emit("chat:close", sessionId);
        }
        function rateConversation(sessionId, ratingData) {
            service.socket.emit("chat:evaluate", sessionId, ratingData.starred, ratingData.topics, ratingData.comment);
        }
        function getClientId() {
            var deferred = $q.defer();
            if (this.clientId !== null) {
                deferred.resolve(this.clientId);
            }
            else {
                service.socket.on('token:validated', function (data) {
                    deferred.resolve(data.id);
                });
            }
            return deferred.promise;
        }
        function getClients() {
            return clients;
        }
        function getSessions() {
            console.log(sessions);
            return sessions;
        }
        function getConversations() {
            return conversations;
        }
        //Private functions, mainly event handlers
        function chatStarted(data) {
            sessions[data.session_id] = new ChatSession(data, service.clientId);
            trigger("start", sessions[data.session_id]);
        }
        function setIsTyping(sessionId, isTyping) {
            service.socket.emit("chat:typing", sessionId, isTyping);
        }
        function propagateSessionEvent(event, data) {
            if (sessions.hasOwnProperty(data.session_id)) {
                sessions[data.session_id].propagatedEventReceived(event, data);
            }
        }
        function disconnect() {
            service.socket.disconnect();
        }
    }
})();
/**
 * Created by danielszenasi on 05/09/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('ChatRatingModalFactory', ['$uibModal', ModalFactory]);
    function ModalFactory($uibModal) {
        return {
            openModal: openModal,
        };
        function openModal(data) {
            var defaultLabels = {
                title: 'Kérjük, hogy értékeld a beszélgetést',
                ok: 'Értékelés küldése',
                cancel: false
            };
            data = angular.extend({}, defaultLabels, data || {});
            $uibModal.open({
                windowClass: 'chat-rating',
                templateUrl: 'app/layouts/chat-rating-modal.html',
                controller: 'chatRatingModalController',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
        }
    }
    angular.module('Yelon').controller('chatRatingModalController', ['$scope', '$uibModalInstance', 'data', '$http', 'ApiFactory', chatRatingModalController]);
    function chatRatingModalController($scope, $uibModalInstance, data, $http, ApiFactory) {
        $scope['data'] = angular.copy(data);
        $scope['SelectedTopics'] = [];
        ApiFactory.getFunction('chattopics').then(function (data) {
            $scope['topics'] = data;
        }).finally(function () {
        });
        $scope['selectedTopics'] = {
            ids: {}
        };
        $scope['ok'] = function (closeMessage) {
            ApiFactory.postFunction('chattopics', {}).then(function (data) {
            }).finally(function () {
            });
            $uibModalInstance.close(closeMessage);
        };
        $scope['cancel'] = function (dismissMessage) {
            if (angular.isUndefined(dismissMessage)) {
                dismissMessage = 'cancel';
            }
            $uibModalInstance.dismiss(dismissMessage);
        };
    }
})();
/**
 * Created by danielszenasi on 05/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('LoginFactory', ['$http', '$auth', 'ChatFactory', '$rootScope', '$q', 'apiPrefix', 'apiGeneralPrefix', LoginFactory]);
    function LoginFactory($http, $auth, ChatFactory, $rootScope, $q, apiPrefix, apiGeneralPrefix) {
        return {
            init: init,
            loginFunction: loginFunction,
            refreshUserFunction: refreshUserFunction,
            refreshTokenFunction: refreshTokenFunction,
            getLoginImagesFunction: getLoginImagesFunction
        };
        function init() {
            // refresh token every 10 minutes
            setInterval(refreshTokenFunction, 600000);
        }
        function loginFunction(credentials) {
            return $auth.login(credentials).then(getUser).catch(returnError);
            function getUser() {
                return refreshUserFunction().then(function () {
                    $rootScope.$emit("login", {});
                });
            }
            function returnError(response) {
                return $q.reject({
                    loginError: true,
                    loginErrorText: response.data.error
                });
            }
        }
        function refreshUserFunction() {
            return $http.get(apiGeneralPrefix + 'authenticate/user').success(function (response) {
                var user = JSON.stringify(response.user);
                // Set the stringified user data into local storage
                localStorage.setItem('user', user);
                $rootScope['authenticated'] = true;
                $rootScope['currentUser'] = response.user;
                if (response.user !== null && (response.user.role === 'supervisor' || response.user.role === 'helper')) {
                    ChatFactory.init();
                }
            });
        }
        function refreshTokenFunction() {
            if ($auth.isAuthenticated()) {
                return $http.get(apiGeneralPrefix + 'authenticate/refresh').success(function (response) {
                    $auth.setToken(response.token);
                }).catch(function () {
                    $auth.logout();
                });
            }
            return $q.resolve();
        }
        function getLoginImagesFunction() {
            return $http.get(apiPrefix + 'loginimages/get').then(function (response) {
                var images = [];
                var temp = angular.fromJson(response.data);
                for (var i = 0; i !== temp.length; i++) {
                    images[i] = { url: temp[i] };
                }
                return images;
            });
        }
    }
})();
/**
 * Created by ekema on 2015. 12. 04..
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('LogoutFactory', ['$http', '$auth', '$rootScope', '$q', 'apiGeneralPrefix', '$state', 'ChatFactory', LogoutFactory]);
    function LogoutFactory($http, $auth, $rootScope, $q, apiGeneralPrefix, $state, ChatFactory) {
        return {
            logoutFunction: logoutFunction
        };
        function logoutFunction() {
            return $http.post(apiGeneralPrefix + 'logout', []).then(activated).catch(returnError);
            function activated() {
                return $auth.logout().then(delCreds());
                function delCreds() {
                    localStorage.removeItem('user');
                    $rootScope['authenticated'] = false;
                    $rootScope['currentUser'] = null;
                    ChatFactory.disconnect();
                    $state.go('login');
                }
            }
            function returnError(response) {
                if (response.data.error) {
                    return $q.reject({
                        logoutError: true,
                        logoutErrorText: response.data.error
                    });
                }
                else {
                    return $q.reject(response);
                }
            }
        }
    }
})();
/**
 * Created by danielszenasi on 10/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('ModalFactory', ['$uibModal', ModalFactory]);
    function ModalFactory($uibModal) {
        return {
            openModal: openModal,
        };
        function openModal(data) {
            var defaultLabels = {
                title: 'Confirm',
                ok: 'OK',
                cancel: 'Cancel'
            };
            data = angular.extend({}, defaultLabels, data || {});
            $uibModal.open({
                templateUrl: 'app/layouts/modal.html',
                controller: 'modalController',
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
        }
    }
    angular.module('Yelon').controller('modalController', ['$scope', '$uibModalInstance', 'data', modalController]);
    function modalController($scope, $uibModalInstance, data) {
        $scope['data'] = angular.copy(data);
        $scope['ok'] = function (closeMessage) {
            $uibModalInstance.close(closeMessage);
        };
        $scope['cancel'] = function (dismissMessage) {
            if (angular.isUndefined(dismissMessage)) {
                dismissMessage = 'cancel';
            }
            $uibModalInstance.dismiss(dismissMessage);
        };
    }
})();
/**
 * Created by danielszenasi on 19/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('PasswordFactory', ['$http', '$auth', '$rootScope', '$state', '$q', 'apiGeneralPrefix', PasswordFactory]);
    function PasswordFactory($http, $auth, $rootScope, $state, $q, apiGeneralPrefix) {
        return {
            newPassword: newPassword,
            resetPassword: resetPassword,
        };
        function newPassword(payload) {
            $http.post(apiGeneralPrefix + "authenticate/change-password", payload).then(success).catch(returnError);
            function success(data) {
                $rootScope.lastSave = new Date();
            }
        }
        function resetPassword(user_id, token, payload) {
            $http.post(apiGeneralPrefix + "authenticate/new-password/" + user_id + "/" + token, payload).then(function () {
                alert("A jelszó sikeresen megváltozott");
                $state.go('login');
            }).catch(returnError);
        }
        function returnError(response) {
            if (handleError(response)) {
                alert('Sikertelen művelet, kérlek próbáld újra! Hibakód: ' + response.status);
            }
            return $q.reject(response);
        }
        function handleError(response) {
            if (response.status === 500) {
                return true;
            }
            //expired token
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.error) !== "undefined" && response.data.error === "token_not_provided") {
                    alert('Lejárt vagy érvénytelen munkamenet, kérlek jelentkezz be újra!');
                    return false;
                }
            }
            //other message
            if (typeof (response.data) !== "undefined" && response.data) {
                if (typeof (response.data.message) !== "undefined") {
                    $rootScope.errorMessage = response.data.message;
                    return false;
                }
            }
            return true;
        }
    }
})();
/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').factory('PasswordModalFactory', ['$uibModal', ModalFactory]);
    function ModalFactory($uibModal) {
        return {
            openModal: openModal,
        };
        function openModal(data) {
            var defaultLabels = {
                title: 'Confirm',
                ok: 'OK',
                cancel: 'Cancel'
            };
            data = angular.extend({}, defaultLabels, data || {});
            $uibModal.open({
                windowClass: 'password-forgotten',
                templateUrl: 'app/layouts/password-forgotten-modal.html',
                controller: 'passwordModalController',
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
        }
    }
    angular.module('Yelon').controller('passwordModalController', ['$scope', '$uibModalInstance', 'data', '$http', 'apiGeneralPrefix', passwordModalController]);
    function passwordModalController($scope, $uibModalInstance, data, $http, apiGeneralPrefix) {
        $scope['data'] = angular.copy(data);
        $scope['ok'] = function (closeMessage) {
            alert('A jelszó megváltoztatásához szükséges adatokat elküldtük a megadott e-mail címre');
            $http.post(apiGeneralPrefix + "authenticate/reset-password", { 'email': $scope['email'] }).then(function () {
                $uibModalInstance.close(closeMessage);
            }).catch();
            console.log($scope['email']);
        };
        $scope['cancel'] = function (dismissMessage) {
            if (angular.isUndefined(dismissMessage)) {
                dismissMessage = 'cancel';
            }
            $uibModalInstance.dismiss(dismissMessage);
        };
    }
})();
/**
 * Created by fures on 2016. 08. 18..
 */
(function () {
    'use strict';
    angular.module('Yelon').
        filter('ceil', function () {
        return function (x) {
            return Math.ceil(x);
        };
    });
})();
/**
 * Created by fures on 2016. 08. 18..
 */
(function () {
    'use strict';
    angular.module('Yelon').
        filter('commaList', function () {
        return function (elements, property) {
            var array = [];
            angular.forEach(elements, function (element, key) {
                array.push(element[property]);
            });
            return array.join(", ");
        };
    });
})();
/**
 * Created by danielszenasi on 16/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').
        filter('dateInMillis', function () {
        return function (dateString) {
            return Date.parse(dateString);
        };
    });
})();
(function () {
    'use strict';
    angular.module('Yelon')
        .filter('emojione', ['$sce', function ($sce) {
            return function (input) {
                input = $sce.valueOf(input);
                if (typeof input !== 'string') {
                    return input;
                }
                return emojione.unicodeToImage(input
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;"));
            };
        }]);
})();
/**
 * Created by fures on 2016. 08. 16..
 */
var Article = (function () {
    function Article(main_type) {
        this.id = null;
        this.user_id = null;
        this.title = "";
        this.teaser = "";
        this.main_type = main_type;
        this.type = "article";
        this.color = "";
        this.popup_message = "";
        this.archived = false;
    }
    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    Article.prototype.compareBy = function (id) {
        return (this.id === id);
    };
    return Article;
}());
var ChatSession = (function () {
    function ChatSession(data, clientId) {
        this.handlers = {};
        this.os = "";
        this.isTyping = false;
        this.clientId = null;
        this.isReadOnly = false;
        this.emojiIconText = ":smile:";
        this.messageInput = "";
        this.isShowEmojiPicker = false;
        this.prevMsg = "";
        this.archived = false;
        this.ended = false;
        this.started = 0;
        this.recipient = null;
        this.name = "";
        this.id = data.session_id;
        this.time = new Date(data.time);
        this.messages = data.messages;
        this.messages.map(function (msg) { return msg.time = new Date(msg.time); });
        this.clients = data.clients;
        this.clientId = clientId;
        this.helper = data.helper;
        this.isMobile = data.mobile;
        this.isTablet = data.tablet;
        this.type = data.type;
        this.os = data.os;
        this.refreshChatTime();
        this.isReadOnly = this.type === "channel" && (this.helper === null || this.helper.id !== this.clientId);
        this.setConversationName();
    }
    ChatSession.prototype.setConversationName = function () {
        var _this = this;
        if (this.type === 'direct') {
            this.clients.forEach(function (client) {
                if (client.id !== _this.clientId) {
                    _this.recipient = client;
                    _this.name = client.name;
                }
            });
        }
        else {
            if (this.helper !== null && this.helper.id === this.clientId) {
                this.name = "Gyerekkel folytatott beszélgetés";
            }
            else {
                this.clients.forEach(function (client) {
                    if (_this.clientId !== client.id && client.role === 'helper') {
                        _this.name = client.name + ' beszélgetése';
                    }
                });
            }
        }
    };
    ChatSession.prototype.inputChanged = function () {
        if (this.prevMsg.length === 0 && this.messageInput.length > 0) {
            this.trigger("userTyping", true);
        }
        else if (this.prevMsg.length > 0 && this.messageInput.length === 0) {
            this.trigger("userTyping", false);
        }
        this.prevMsg = this.messageInput;
    };
    ChatSession.prototype.sendMessage = function () {
        if ((this.messageInput.trim()).length > 0) {
            this.trigger("sendingMessage", this.messageInput);
            this.messageInput = "";
            this.inputChanged();
        }
    };
    ChatSession.prototype.closeConversation = function () {
        this.trigger("closing");
    };
    ChatSession.prototype.takeOverConversation = function () {
        this.trigger('takeover');
    };
    ChatSession.prototype.giveBackConversation = function () {
        this.trigger('giveback');
    };
    ChatSession.prototype.refreshChatTime = function () {
        if (!this.ended) {
            this.started = Math.floor(((new Date()).getTime() - this.time.getTime()) / 60000);
        }
    };
    ChatSession.prototype.announce = function (message) {
        this.messages.push({
            client: "system",
            message: "Rendszerüzenet érkezett: " + message,
            time: new Date()
        });
    };
    ChatSession.prototype.onKeyPressed = function ($event) {
        if (($event.code === "Enter" || $event.code === "NumpadEnter" || $event.key === "Enter" || $event.key === "NumpadEnter") && $event.ctrlKey === false && $event.shiftKey === false) {
            $event.preventDefault();
            this.sendMessage();
        }
        else {
            this.inputChanged();
        }
    };
    ChatSession.prototype.on = function (eventName, handler) {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName].push(handler);
        }
        else {
            this.handlers[eventName] = [handler];
        }
    };
    ChatSession.prototype.off = function (eventName, handler) {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName] = this.handlers[eventName].filter(function (h) { return h !== handler; });
        }
    };
    ChatSession.prototype.trigger = function (eventName, data) {
        if (this.handlers.hasOwnProperty(eventName)) {
            this.handlers[eventName].forEach(function (h) { return h(data); });
        }
    };
    ChatSession.prototype.propagatedEventReceived = function (type, data) {
        switch (type) {
            case "message":
                var msg = { client: data.client, message: data.message, time: new Date() };
                this.messages.push(msg);
                break;
            case "typing":
                if (data.client.id !== this.clientId) {
                    this.isTyping = data.typing;
                }
                break;
            case "end":
                this.isReadOnly = true;
                this.isTyping = false;
                this.messageInput = "";
                this.inputChanged();
                this.ended = true;
                break;
            case "archive":
                this.isReadOnly = true;
                this.isTyping = false;
                this.archived = true;
                this.messages.push({
                    client: "system",
                    message: "A beszélgetés lezárásra került.",
                    time: new Date()
                });
                break;
            case "join":
                this.messages.push({
                    client: "system",
                    message: "Felhasználó csatlakozott a beszélgetéshez:" + data.client.name,
                    time: new Date()
                });
                break;
            case "leave":
                this.messages.push({
                    client: "system",
                    message: "Felhasználó elhagyta a beszélgetést:" + data.client.name,
                    time: new Date()
                });
                break;
            case "handover":
                var prevHelper = this.helper;
                this.helper = data.client;
                this.messages.push({
                    client: "system",
                    message: data.client.name + " átvette a beszélgetést tőle: " + (prevHelper === null ? 'system' : prevHelper.name),
                    time: new Date()
                });
                this.isReadOnly = this.type === "channel" && (this.helper === null || this.helper.id !== this.clientId);
                this.setConversationName();
                break;
        }
        this.trigger(type, data);
    };
    Object.defineProperty(ChatSession.prototype, "Archived", {
        get: function () {
            return this.archived;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Helper", {
        get: function () {
            return this.helper;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Id", {
        get: function () {
            return this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "IsMobile", {
        get: function () {
            return this.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "IsTablet", {
        get: function () {
            return this.isTablet;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "IsReadOnly", {
        get: function () {
            return this.isReadOnly;
        },
        set: function (val) {
            this.isReadOnly = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "IsTyping", {
        get: function () {
            return this.isTyping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Messages", {
        get: function () {
            return this.messages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Name", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Recipient", {
        get: function () {
            return this.recipient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Started", {
        get: function () {
            return this.started;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Time", {
        get: function () {
            return this.time;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSession.prototype, "Type", {
        get: function () {
            return this.type;
        },
        enumerable: true,
        configurable: true
    });
    ChatSession.prototype.openEmojiPicker = function () {
        this.isShowEmojiPicker = (!this.isShowEmojiPicker) ? true : false;
    };
    ChatSession.prototype.onAddEmojiIcon = function (text) {
        console.log(text);
        this.messageInput += text;
        this.isShowEmojiPicker = false;
        this.inputChanged();
    };
    return ChatSession;
}());
/**
 * Created by danielszenasi on 11/08/16.
 */
var FaqItem = (function () {
    function FaqItem() {
        this.id = 0;
        this.faq_category_id = 0;
        this.order = 0;
        this.question = "";
        this.answer = "";
    }
    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    FaqItem.prototype.compareBy = function (id) {
        return (this.id === id);
    };
    return FaqItem;
}());
var Paragraph = (function () {
    function Paragraph(type) {
        this.id = 0;
        this.type = "";
        this.content = "";
        this.type = type;
    }
    /**
     * Returns if the products' id matches the given one
     * @param id : value to compare
     * @returns {boolean} : indicates match
     */
    Paragraph.prototype.compareBy = function (id) {
        return (this.id === id);
    };
    return Paragraph;
}());
/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';
    angular.module('Yelon').service('SidebarService', ['$rootScope', function ($rootScope) {
            var service = this;
            service.sidebarItems = [];
            service.isDisabled = true;
            $rootScope.isSidebarDisabled = service.isDisabled;
            // presets
            service.presets = {
                'main': [
                    {
                        title: 'Chat beszélgetések',
                        state: 'chat',
                    },
                    {
                        title: 'Bejegyzések',
                        state: 'articles',
                    },
                    {
                        title: 'Archivált beszélgetések',
                        state: 'archivedchatconv',
                    },
                    {
                        title: 'Fix elemek az oldalon',
                        state: 'pages',
                    },
                    {
                        title: 'Chat beállítások',
                        state: 'chat-settings',
                    },
                    {
                        title: "Értesítés küldés",
                        isPopup: true
                    },
                    {
                        title: 'Felhasználók',
                        state: 'users',
                    },
                ],
                'supervisor': [
                    {
                        title: 'Chat beszélgetések',
                        state: 'chat',
                    },
                    {
                        title: 'Archivált beszélgetések',
                        state: 'archivedchatconv',
                    },
                    {
                        title: 'Chat beállítások',
                        state: 'chat-settings',
                    },
                    {
                        title: "Értesítés küldés",
                        isPopup: true
                    }
                ],
                'superadmin': [
                    {
                        title: 'Bejegyzések',
                        state: 'articles',
                    },
                    {
                        title: 'Archivált beszélgetések',
                        state: 'archivedchatconv',
                    },
                    {
                        title: 'Fix elemek az oldalon',
                        state: 'pages',
                    },
                    {
                        title: 'Chat beállítások',
                        state: 'chat-settings',
                    },
                    {
                        title: "Értesítés küldés",
                        isPopup: true
                    },
                    {
                        title: 'Felhasználók',
                        state: 'users',
                    },
                ],
                'helper': [
                    {
                        title: 'Chat beszélgetések',
                        state: 'chat',
                    }
                ],
                'blogger': [
                    {
                        title: 'Bejegyzések',
                        state: 'articles',
                    }
                ],
                'articles': [
                    {
                        title: 'Webes bejegyzések',
                        state: 'articles-web',
                    },
                    {
                        title: 'Mobil App bejegyzések',
                        state: 'articles-mobile',
                    },
                    {
                        title: 'Felnőtt bejegyzések',
                        state: 'articles-adult',
                    }
                ],
                'articles-web': [
                    {
                        title: 'Gyerek oldal bejegyzései',
                        state: 'articles-web',
                    },
                    {
                        title: 'Mobilapplikáció bejegyzései',
                        state: 'articles-mobile',
                    },
                    {
                        title: 'Felnőtt oldal bejegyzései',
                        state: 'articles-adult',
                    },
                    {
                        title: 'Fix bejegyzések',
                        state: 'articles-fix',
                    },
                    {
                        title: 'Tagek szerkesztése',
                        state: 'articles-web-tags',
                    },
                    {
                        title: 'Kategóriák szerkesztése',
                        state: 'articles-web-categories',
                    },
                ],
                'pages': [
                    {
                        title: 'GYIK + szótár szerkesztése',
                        state: 'pages-faq',
                    },
                    {
                        title: 'Chat fix fül (gyerek), yelonról fül (felnőtt)',
                        state: 'pages-helpers',
                    },
                    {
                        title: 'Rólunk',
                        state: 'pages-about',
                    },
                    {
                        title: 'Mobilapplikáció fix fül',
                        state: 'pages-app',
                    },
                ],
                'chat-settings': [
                    {
                        title: 'Automatikus üzenetek szerkesztése',
                        state: 'chat-settings-automessages',
                    },
                    {
                        title: 'Témák szerkesztése',
                        state: 'chat-settings-topics',
                    },
                    {
                        title: 'Nyitvatartás',
                        state: 'chat-settings-businesshours',
                    },
                ],
                'faq': [
                    {
                        title: 'Lista nézet',
                        state: 'pages-faq',
                    },
                    {
                        title: 'Új elem hozzáadása',
                        state: 'pages-faq-add',
                    },
                    {
                        title: 'Kategória szerkesztés',
                        state: 'pages-faq-categories',
                    },
                ],
            };
            // load a preset
            service.loadPreset = function (index) {
                if ($rootScope.currentUser.role === "superadmin" && index === "main") {
                    service.sidebarItems = service.presets[$rootScope.currentUser.role];
                }
                else {
                    service.sidebarItems = service.presets[index];
                }
            };
            // enable sidebar
            service.enable = function () {
                service.isDisabled = false;
                $rootScope.isSidebarDisabled = service.isDisabled;
            };
            // remove all menu bar items
            service.clearSidebarItems = function () {
                service.sidebarItems = [];
                service.isDisabled = true;
                $rootScope.isSidebarDisabled = service.isDisabled;
            };
            // overview menu items
            service.overviewSidebarItems = function (items) {
                service.clearSidebarItems();
                angular.forEach(items, function (item) {
                    service.sidebarItems.push(item);
                });
            };
        }]);
})();
/**
 * Created by ekema on 2016. 04. 01..
 */
(function () {
    'use strict';
    angular.module('Yelon').service('spinnerService', [spinnerService]);
    function spinnerService() {
        var currentlyLoading = 0;
        var showSpinner = false;
        return {
            incrementLoading: function () {
                currentlyLoading++;
                this.showSpinner = true;
            },
            decrementLoading: function () {
                currentlyLoading--;
                if (currentlyLoading === 0) {
                    this.showSpinner = false;
                }
            },
            showSpinner: showSpinner
        };
    }
})();
(function () {
    'use strict';
    angular.module('Yelon').controller('CategoryController', ['ApiFactory', 'SidebarService', 'spinnerService', '$filter', '$scope', '$confirm', '$timeout', 'Upload', 'apiPrefix', 'tileColors', CategoryController]);
    function CategoryController(ApiFactory, SidebarService, spinnerService, $filter, $scope, $confirm, $timeout, Upload, apiPrefix, tileColors) {
        var vm = this;
        SidebarService.loadPreset('articles-web');
        SidebarService.enable();
        vm.attributes = {};
        vm.creating = false; //is there any creating in progress
        vm.picFile = false;
        vm.tileColors = tileColors;
        init();
        function init() {
            spinnerService.incrementLoading();
            ApiFactory.getFunction('attributes').then(function (data) {
                vm.attributes = data;
            }).finally(function () {
                spinnerService.decrementLoading();
            });
        }
        vm.deleteItem = function (parentIndex, index) {
            var attributesValue = vm.attributes[parentIndex].attributes_values[index];
            $confirm({ title: 'Megerősítés', text: 'Biztosan törli a "' + attributesValue.title + '" elemet?' })
                .then(function () {
                ApiFactory.deleteFunction('attributes/' + vm.attributes[parentIndex].id + '/attributesvalues/' + attributesValue.id).then(function (data) {
                    vm.attributes[parentIndex].attributes_values.splice(index, 1);
                });
            });
        };
        vm.moveItem = function (parentIndex, index, dir) {
            if (vm.creating) {
                return;
            }
            var attributesValues = vm.attributes[parentIndex].attributes_values;
            var attributesValue = attributesValues[index];
            dir === "up" ? attributesValue.order-- : attributesValue.order++;
            ApiFactory.patchFunction('attributes/' + vm.attributes[parentIndex].id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                if (typeof (data.message) !== "undefined" && data.message === "cant_save") {
                    alert('Sikertelen mozgatás, kérlek próbáld újra!');
                    attributesValue.order = index;
                    return;
                }
                //move item phisically in the array
                attributesValues.splice(attributesValue.order, 0, attributesValues.splice(index, 1)[0]);
            });
        };
        vm.updateItem = function (attribute, attributesValue) {
            if (attributesValue.isNew) {
                console.log(vm['attribute_' + attribute.id + '_' + attributesValue.id ? attributesValue.id : 'new' + '_form']);
            }
            else {
                ApiFactory.patchFunction('attributes/' + attribute.id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                    if (typeof (data.message) !== "undefined" && data.message === "cant_save") {
                        alert('Sikertelen mentés, kérlek próbáld újra!');
                    }
                });
            }
        };
        vm.updateItemWithTimeOut = function (attribute, attributesValue) {
            if (typeof (attributesValue.timeOut) !== "undefined" && attributesValue.timeOut) {
                $timeout.cancel(attributesValue.timeOut);
            }
            attributesValue.timeOut = $timeout(function () {
                if (attributesValue.isNew) {
                    console.log(vm['attribute_' + attribute.id + '_' + (attributesValue.id ? attributesValue.id : 'new') + '_form']);
                    if (vm['attribute_' + attribute.id + '_' + (attributesValue.id ? attributesValue.id : 'new') + '_form'].$valid) {
                        ApiFactory.postFunction('attributes/' + attribute.id + '/attributesvalues', attributesValue).then(function (data) {
                            console.log(data);
                            attributesValue.id = data.id;
                            attributesValue.isNew = false;
                            //add the item
                        }).finally(function () {
                            vm.creating = false;
                        });
                    }
                }
                else {
                    ApiFactory.patchFunction('attributes/' + attribute.id + '/attributesvalues/' + attributesValue.id, attributesValue).then(function (data) {
                        if (typeof (data.message) !== "undefined" && data.message === "cant_save") {
                            alert('Sikertelen mentés, kérlek próbáld újra!');
                        }
                    });
                }
            }, 2000);
        };
        vm.createItem = function (parentIndex) {
            vm.creating = true;
            var tmpVal = {
                attribute_id: vm.attributes[parentIndex].id,
                isNew: true,
                order: vm.attributes.length,
                title: ''
            };
            vm.attributes[parentIndex].attributes_values.push(tmpVal);
        };
    }
})();
/**
 * Created by danielszenasi on 08/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('TagController', ['ApiFactory', 'SidebarService', 'spinnerService', TagController]);
    function TagController(ApiFactory, SidebarService, spinnerService) {
        var vm = this;
        vm.tags = [];
        vm.tagSelect = false;
        SidebarService.loadPreset('articles-web');
        SidebarService.enable();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('tags').then(function (data) {
            angular.forEach(data, function (value, key) {
                var item = { id: value.id, text: value.title };
                vm.tags.push(item);
            });
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        vm.tagAdded = function ($tag) {
            ApiFactory.postFunction('tags', { 'title': $tag.text }).then(function (data) {
                $tag.id = data.id;
            });
        };
        vm.tagRemoved = function ($tag) {
            ApiFactory.deleteFunction('tags/' + $tag.id).then(function () { });
        };
    }
})();
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            tileColors: "<",
            onUpdate: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            coverImage: "<",
            boundsCover: "=",
            existThemecolor: "@",
            existImages: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/backgroundUpload.html',
        transclude: true
    };
    function controllerCtrl() {
        var fileId;
        this.tileColorRadioChecked = null;
        this.$onInit = function () {
            if (!fileId) {
                this.bgRadioChecked = 0;
                if (this.tileColorRadioChecked === null) {
                    this.tileColorRadioChecked = this.tileColors[0];
                }
            }
            this.bgRadioBoxes = [{
                    value: 0,
                    text: "Nincs háttérkép"
                }, {
                    value: 1,
                    text: "Háttérkép"
                }];
        };
        this.$postLink = function () {
            if (!fileId) {
                this.onChangeInput();
            }
        };
        this.getCheckedClass = function (checked, value) {
            return (checked !== null && value.toString() === checked.toString()) ? "checked" : "";
        };
        this.getCheckedClassByColors = function (index) {
            return (this.tileColors[index] === this.tileColorRadioChecked) ? "checked" : "";
        };
        this.saveCroppedImages = function () {
            this.onChangeInput();
        };
        this.onChangeInput = function (isColorRadioBtnClick) {
            var _this = this;
            this.onUpdate({
                bounds: this.boundsCover,
                tileColorRadioCheckValue: this.tileColorRadioChecked,
                radioChecked: this.bgRadioChecked,
                fileId: fileId,
                callback: function () {
                    fileId = undefined;
                    delete _this.existMessage;
                    delete _this.sourceImage;
                    delete _this.file;
                }
            });
        };
        this.$onChanges = function (changes) {
            var _this = this;
            var uploadedFile = changes.uploadedFile;
            var existThemecolor = changes.existThemecolor;
            var existImages = changes.existImages;
            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                var fileProp = uploadedFile.currentValue;
                if (fileProp.type === "cover") {
                    fileId = fileProp.data.id;
                    this.tileColorRadioChecked = null;
                    this.existMessage = "Van feltöltve kép";
                    this.onChangeInput();
                }
            }
            if (existThemecolor !== undefined && existThemecolor.isFirstChange()) {
                this.tileColorRadioChecked = existThemecolor.currentValue;
            }
            if (existImages !== undefined && existImages.isFirstChange()) {
                var values = existImages.currentValue;
                if (values !== undefined && values.length > 0) {
                    values.forEach(function (value) {
                        if (value.pivot.type === "cover" && !_this.sourceImage) {
                            _this.setCroppedImage(value);
                        }
                    });
                }
            }
        };
        this.setCroppedImage = function (image) {
            var _this = this;
            if (image.pivot.disabled === 0) {
                this.bgRadioChecked = 1;
                this.tileColorRadioChecked = null;
            }
            else {
                this.bgRadioChecked = 0;
            }
            this.existMessage = "Van feltöltve kép";
            this.boundsCover.left = image.pivot.left;
            this.boundsCover.right = image.pivot.right;
            this.boundsCover.top = image.pivot.top;
            this.boundsCover.bottom = image.pivot.bottom;
            fileId = image.id;
            this.file = {
                name: image.full_url.split('/').reverse()[0]
            };
            this.toDataUrl(image.full_url, function (img) {
                _this.sourceImage = img;
            }, 'image/jpeg');
        };
        this.onClickRemoveBtn = function (event) {
            var _this = this;
            event.preventDefault();
            this.deleteFile(fileId, function () {
                fileId = undefined;
                delete _this.existMessage;
                delete _this.sourceImage;
                delete _this.file;
                _this.bgRadioChecked = 0;
            });
        };
        this.toDataUrl = function (src, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
                img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = src;
            }
        };
    }
    angular
        .module('Yelon')
        .component('backgroundUpload', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            onUpdate: "<",
            paragraphs: "=",
            updateParagraph: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            deleteParagraph: "<",
            existParagraphs: "<",
            articleType: "@",
            updateOrder: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/blocks.html',
        transclude: true
    };
    function controllerCtrl() {
        var timer;
        var self = this;
        var prev;
        this.additionalButtons = [
            [{
                    name: "groupFont",
                    data: [{
                            name: "cmdStrikethrough",
                            toggle: false,
                            title: "Strikethrough",
                            icon: {
                                fa: "fa fa-strikethrough",
                                glyph: "glyphicon glyphicon-minus"
                            },
                            callback: function (e) {
                                // Give/remove ~~ surround the selection
                                var chunk, cursor, selected = e.getSelection(), content = e.getContent();
                                if (selected.length === 0) {
                                    // Give extra word
                                    chunk = e.__localize('strikethrough');
                                }
                                else {
                                    chunk = selected.text;
                                }
                                // transform selection and set the cursor into chunked text
                                if (content.substr(selected.start - 2, 2) === '~~' &&
                                    content.substr(selected.end, 2) === '~~') {
                                    e.setSelection(selected.start - 2, selected.end + 2);
                                    e.replaceSelection(chunk);
                                    cursor = selected.start - 2;
                                }
                                else {
                                    e.replaceSelection('~~' + chunk + '~~');
                                    cursor = selected.start + 2;
                                }
                                // Set the cursor
                                e.setSelection(cursor, cursor + chunk.length);
                            }
                        }]
                }, {
                    name: "groupMisc",
                    data: [{
                            name: "cmdTable",
                            toggle: false,
                            title: "Table",
                            icon: {
                                fa: "fa fa-table",
                                glyph: "glyphicon glyphicon-th"
                            },
                            callback: function (e) {
                                // Replace selection with some drinks
                                var cursor, selected = e.getSelection(), content = e.getContent(), chunk = "\n| Tables        | Are           | Cool  | \n" +
                                    "| ------------- |:-------------:| -----:| \n" +
                                    "| col 3 is      | right-aligned | $1600 | \n" +
                                    "| col 2 is      | centered      |   $12 | \n" +
                                    "| zebra stripes | are neat      |    $1 |";
                                // transform selection and set the cursor into chunked text
                                e.replaceSelection(chunk);
                                cursor = selected.start;
                                // Set the cursor
                                e.setSelection(cursor, cursor + chunk.length);
                            }
                        }]
                }, {
                    name: "groupMisc",
                    data: [{
                            name: "cmdContinue",
                            toggle: false,
                            title: "Continue",
                            icon: {
                                fa: "fa fa-ellipsis-h"
                            },
                            callback: function (e) {
                                var cursor, selected = e.getSelection(), content = e.getContent(), chunk = "---more---";
                                e.replaceSelection(chunk);
                                cursor = selected.start;
                                e.setSelection(cursor, cursor + chunk.length);
                            }
                        }]
                }]
        ];
        this.$onInit = function () {
            this.addedBlocks = [];
            this.files = [];
            this.images = {};
            this.blocks = [{
                    title: "Sima szöveg",
                    type: "text",
                    enable: (this.articleType === "gallery") ? false : true
                }, {
                    title: "Kiemelt szöveg",
                    type: "highlight",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Kép",
                    type: "image",
                    enable: (this.articleType === "video" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Youtube videó",
                    type: "video",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                },
                /*{
                               title: "Galéria",
                               type: "gallery",
                               enable: (this.articleType === "video" || this.articleType === "quiz") ? false : true
                           },*/
                {
                    title: "Kvíz Html",
                    type: "iframe",
                    enable: (this.articleType === "quiz") ? true : false
                }, {
                    title: "Kvíz Script",
                    type: "iframe2",
                    enable: (this.articleType === "quiz") ? true : false
                }, {
                    title: "SoundCloud",
                    type: "soundcloud",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "Instagram",
                    type: "instagram",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }, {
                    title: "tumblr",
                    type: "tumblr",
                    enable: (this.articleType === "gallery" || this.articleType === "quiz") ? false : true
                }
            ];
        };
        this.onClickRollBtn = function (clickIndex) {
            if (this.addedBlocks[parseInt(clickIndex)].rolledClass) {
                delete this.addedBlocks[parseInt(clickIndex)].rolledClass;
            }
            else {
                this.addedBlocks[parseInt(clickIndex)].rolledClass = "rolled-down";
            }
        };
        this.dragEnd = function () {
            this.onUpdate(this.paragraphs);
        };
        this.getContainerClass = function (index) {
            return (this.addedBlocks[parseInt(index)] && this.addedBlocks[parseInt(index)].rolledClass) ? this.addedBlocks[parseInt(index)].rolledClass : "";
        };
        this.changeBlockType = function () {
            var _a = this.currentBlock, type = _a.type, content = _a.content, order = _a.order, image_id = _a.image_id;
            if (content === undefined) {
                content = "";
            }
            order = null;
            image_id = null;
            this.addedBlocks.push(this.currentBlock);
            if (!this.paragraphs) {
                this.paragraphs = [{
                        type: type,
                        content: content,
                        order: order,
                        image_id: image_id
                    }];
            }
            else {
                this.paragraphs.push({
                    type: type,
                    content: content,
                    order: order,
                    image_id: image_id
                });
            }
        };
        this.onAddBtnClick = function () {
            var _a = this.currentBlock, type = _a.type, content = _a.content, order = _a.order, image_id = _a.image_id, title = _a.title;
            if (content === undefined) {
                content = "";
            }
            order = null;
            image_id = null;
            if (this.paragraphs.length === 0 && this.addedBlocks.length > 0) {
                this.paragraphs = [];
                this.addedBlocks = [];
            }
            this.addedBlocks.push({
                title: title
            });
            this.paragraphs.push({
                type: type,
                content: content,
                order: order,
                image_id: image_id
            });
        };
        this.onClickRemoveBlockBtn = function (index) {
            var _this = this;
            this.deleteParagraph(this.paragraphs[index], function () {
                _this.addedBlocks.splice(index, 1);
                if (_this.images[index] !== undefined) {
                    _this.onClickRemoveBtn(index);
                }
            });
        };
        this.onClickRemoveBtn = function (index) {
            var _this = this;
            this.deleteFile(this.images[index].id, function () {
                delete _this.images[index];
                delete _this.files[index];
                _this.paragraphs[index].image_id = null;
                _this.paragraphs[index].image = null;
                _this.updateParagraph(_this.paragraphs[index]);
            });
            this.files.splice(index, 1);
        };
        this.onChangeEditor = function (paragraph) {
            var _this = this;
            if (paragraph.id) {
                if (timer !== undefined) {
                    window.clearTimeout(timer);
                    timer = undefined;
                }
                timer = window.setTimeout(function () {
                    _this.updateParagraph(paragraph);
                }, 2000);
            }
        };
        this.onClickArrow = function (currentBlock, isUp, index) {
            var movedIndex;
            var moveBlock;
            var addBlock = this.addedBlocks[index];
            var moveParagraph;
            var currentImage;
            var moveImage;
            var currentFile;
            var moveFile;
            if (this.images[index]) {
                currentImage = this.images[index];
            }
            if (this.files[index]) {
                currentFile = this.files[index];
            }
            if (isUp) {
                movedIndex = index - 1;
            }
            else {
                movedIndex = index + 1;
            }
            if (movedIndex < 0) {
                movedIndex = this.addedBlocks.length - 1;
            }
            else if (movedIndex > this.addedBlocks.length - 1) {
                movedIndex = 0;
            }
            moveBlock = this.addedBlocks[movedIndex];
            moveParagraph = this.paragraphs[movedIndex];
            if (this.images[movedIndex]) {
                moveImage = this.images[movedIndex];
            }
            if (this.files[movedIndex]) {
                moveFile = this.files[moveFile];
            }
            this.paragraphs[movedIndex] = currentBlock;
            this.paragraphs[index] = moveParagraph;
            this.addedBlocks[movedIndex] = addBlock;
            this.addedBlocks[index] = moveBlock;
            if (currentImage) {
                this.images[movedIndex] = currentImage;
            }
            if (moveImage) {
                this.images[index] = moveImage;
            }
            if (currentFile) {
                this.files[movedIndex] = currentFile;
            }
            if (moveFile) {
                this.files[index] = moveFile;
            }
            this.updateOrder(currentBlock, movedIndex);
        };
        this.$onChanges = function (changes) {
            var _this = this;
            var uploadedFile = changes.uploadedFile;
            var existParagraphs = changes.existParagraphs;
            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                var fileProp = uploadedFile.currentValue;
                if (fileProp.type === "paragraph") {
                    this.images[fileProp.index] = {
                        url: fileProp.data.full_url,
                        id: fileProp.data.id
                    };
                    console.log('Image uploaded');
                    console.log(fileProp);
                }
            }
            if (existParagraphs !== undefined && !existParagraphs.isFirstChange()) {
                var values = existParagraphs.currentValue;
                this.paragraphs = null;
                values.forEach(function (value, mainIndex) {
                    _this.blocks.forEach(function (block, index) {
                        if (value.type === block.type) {
                            var title = block.title;
                            _this.addedBlocks.push({
                                title: title
                            });
                            if (value.type === "image" && value.image) {
                                console.log('Image change');
                                console.log(value);
                                _this.images[mainIndex] = {
                                    url: value.image.full_url,
                                    id: value.image.id
                                };
                                if (!_this.files[mainIndex]) {
                                    _this.files[mainIndex] = {
                                        name: value.image.url.split('/').reverse()[0]
                                    };
                                }
                                else {
                                    _this.files[mainIndex].name = value.image.url.split('/').reverse()[0];
                                }
                                console.log(_this.files[mainIndex]);
                            }
                        }
                    });
                });
                this.paragraphs = values;
            }
        };
    }
    angular
        .module('Yelon')
        .component('blocksInput', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            categories: "=",
            getAttributes: "<",
            existAttributes: "<",
            selectedAllcategories: "=",
            publishDate: "<",
            mainType: "@"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/categories.html',
        transclude: true
    };
    function controllerCtrl() {
        var self = this;
        var prevAttributes = [];
        var isExistAttributes = false;
        function loadAttributes() {
            self.getAttributes(function (results) {
                self.ages = [];
                results.forEach(function (attribute, index) {
                    switch (attribute.id) {
                        case 1:
                            self.themes = attribute.attributes_values;
                            break;
                        case 2:
                        case 4:
                            self.ages = self.ages.concat(attribute.attributes_values);
                            break;
                        case 3:
                            self.sexes = attribute.attributes_values;
                            break;
                    }
                });
            });
        }
        this.changeTheme = function () {
            if (!this.selectedThemes) {
                this.selectedThemes = [this.currentTheme];
            }
            else {
                this.selectedThemes.push(this.currentTheme);
            }
            this.setCategories(this.currentTheme.id);
        };
        this.changeAge = function () {
            if (this.currentAge === null) {
                return;
            }
            if (!this.selectedAges) {
                this.selectedAges = [this.currentAge];
            }
            else {
                this.selectedAges.push(this.currentAge);
            }
            this.setCategories(this.currentAge.id, "age");
        };
        this.changeSex = function () {
            if (this.currentSex === null) {
                return;
            }
            if (!this.selectedSexes) {
                this.selectedSexes = [this.currentSex];
            }
            else {
                this.selectedSexes.push(this.currentSex);
            }
            this.setCategories(this.currentSex.id, "sex");
        };
        this.onClickRemoveBtn = function (index, selectedArray, item) {
            var _this = this;
            if ((item.attribute_id === 2 || item.attribute_id === 3) && selectedArray.length === 1 && this.publishDate !== null) {
                return;
            }
            selectedArray.splice(index, 1);
            if (item.attribute_id === 1) {
                delete this.selectedThemes;
                delete this.currentTheme;
            }
            else if (item.attribute_id === 2) {
                this.ages.push(item);
            }
            else {
                this.sexes.push(item);
            }
            if (this.categories.length > 0) {
                this.categories.forEach(function (categoryItemId, index) {
                    if (categoryItemId === item.id) {
                        _this.categories.splice(index, 1);
                    }
                });
            }
        };
        this.setCategories = function (value, type) {
            var _this = this;
            if (this.selectedThemes && this.selectedAges && this.selectedSexes) {
                this.selectedAllcategories = true;
            }
            switch (type) {
                case "age":
                    this.ages.forEach(function (item, index) {
                        if (item.id === value) {
                            _this.ages.splice(index, 1);
                        }
                    });
                    break;
                case "sex":
                    this.sexes.forEach(function (item, index) {
                        if (item.id === value) {
                            _this.sexes.splice(index, 1);
                        }
                    });
                    break;
            }
            if (!this.categories) {
                this.categories = [value];
            }
            else {
                this.categories.push(value);
            }
        };
        this.$onChanges = function (changes) {
            var existAttributes = changes.existAttributes;
            if (existAttributes !== undefined && existAttributes.isFirstChange()) {
                loadAttributes();
            }
            else if (existAttributes !== undefined && !existAttributes.isFirstChange()) {
                var values_1 = existAttributes.currentValue;
                window.setTimeout(function () {
                    setExistCategories(values_1);
                }, 250);
            }
        };
        function setExistCategories(values) {
            self.selectedSexes = [];
            self.selectedAges = [];
            values.forEach(function (value) {
                var attrId = value.attribute_id;
                switch (attrId) {
                    case 1:
                        self.themes.forEach(function (theme, index) {
                            if (theme.id === value.id) {
                                self.currentTheme = theme;
                                self.selectedThemes = [self.currentTheme];
                                prevAttributes.push(theme.id);
                            }
                        });
                        break;
                    case 2:
                    case 4:
                        self.ages.forEach(function (age, index) {
                            if (age.id === value.id) {
                                self.selectedAges.push(age);
                                prevAttributes.push(age.id);
                                self.ages.splice(index, 1);
                            }
                        });
                        break;
                    case 3:
                        self.sexes.forEach(function (sex, index) {
                            if (sex.id === value.id) {
                                self.selectedSexes.push(sex);
                                prevAttributes.push(sex.id);
                                self.sexes.splice(index, 1);
                            }
                        });
                        break;
                }
                if (!self.categories) {
                    self.categories = prevAttributes;
                }
            });
        }
        ;
    }
    angular
        .module('Yelon')
        .component('categoriesInput', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            messageText: "=",
            existText: "@",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/popupMessage.html',
        transclude: true
    };
    function controllerCtrl() {
        this.$onInit = function () {
            this.radioValue = false;
            this.disabled = true;
        };
        this.getCheckedClass = function () {
            var className = (this.disabled) ? "" : "checked";
            this.radioValue = (className === "") ? false : true;
            if (!this.radioValue && this.messageText) {
                this.messageText = null;
            }
            return className;
        };
        this.$onChanges = function (changes) {
            var messageText = changes.existText;
            if (messageText !== undefined && !messageText.isFirstChange()) {
                this.messageText = messageText.currentValue;
                this.disabled = false;
            }
        };
    }
    angular
        .module('Yelon')
        .component('popupMessage', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            publishDate: "=",
            existDate: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/publishDate.html',
        transclude: true
    };
    function controllerCtrl() {
        this.buttonBar = {
            show: true,
            now: {
                show: true,
                text: 'Most'
            },
            today: {
                show: true,
                text: 'Ma'
            },
            clear: {
                show: true,
                text: 'Törlés'
            },
            date: {
                show: true,
                text: 'Dátum'
            },
            time: {
                show: true,
                text: 'Idő'
            },
            close: {
                show: true,
                text: 'Bezárás'
            }
        };
        this.$onInit = function () {
            var currentDate = new Date();
            this.dateValue = currentDate;
            this.radioValue = null;
            this.currentValue = currentDate;
            this.isOpen = false;
        };
        this.$onChanges = function (changes) {
            var publishDate = changes.existDate;
            if (publishDate !== undefined && !publishDate.isFirstChange()) {
                if (publishDate.currentValue === null) {
                    this.radioValue = "draft";
                    this.currentValue = null;
                }
                else {
                    this.dateValue = new Date(publishDate.currentValue);
                    this.currentValue = publishDate.currentValue;
                    this.radioValue = publishDate.currentValue;
                }
            }
        };
        this.getCheckedClass = function (isDraft) {
            var className = "";
            if (isDraft && this.radioValue === "draft") {
                className = "checked";
                this.publishDate = null;
                this.currentValue = null;
            }
            else if (!isDraft && this.radioValue !== null && this.radioValue !== 'draft') {
                className = "checked";
                this.currentValue = this.radioValue;
            }
            return className;
        };
        this.onClickPublish = function () {
            if (this.radioValue !== "draft") {
                this.publishDate = formatDate(this.dateValue);
            }
        };
        function formatDate(date) {
            var dateValue = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
            var monthValue = date.getMonth() + 1;
            monthValue = (monthValue < 10) ? "0" + monthValue : monthValue;
            var hourValue = (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
            var minValue = (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
            var secondValue = (date.getSeconds() < 10) ? "0" + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + "-" + monthValue + "-" + dateValue + " " + hourValue + ":" + minValue + ":" + secondValue;
        }
        this.openCalendar = function (event) {
            event.preventDefault();
            event.stopPropagation();
            if (this.radioValue !== "draft") {
                this.isOpen = true;
            }
        };
    }
    angular
        .module('Yelon')
        .component('publishDate', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            selectedTags: "=",
            getTags: "<",
            existTags: "<",
            disabledAllinput: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/tags.html',
        transclude: true
    };
    function controllerCtrl() {
        var self = this;
        this.$onInit = function () {
            var _this = this;
            this.getTags(function (results) {
                _this.tags = results;
            });
        };
        this.$onChanges = function (changes) {
            var existTags = changes.existTags;
            if (existTags !== undefined && !existTags.isFirstChange()) {
                var values_2 = existTags.currentValue;
                window.setTimeout(function () {
                    setExistTags(values_2);
                }, 10);
            }
        };
        function setExistTags(values) {
            self.selectedTags = [];
            values.forEach(function (value) {
                self.tags.forEach(function (tag, index) {
                    if (tag.id === value.id) {
                        self.selectedTags.push(tag);
                        self.tags.splice(index, 1);
                    }
                });
            });
        }
        ;
        this.onSelected = function () {
            var _this = this;
            if (!this.selectedTags) {
                this.selectedTags = [this.currentTag];
            }
            else {
                this.selectedTags.push(this.currentTag);
            }
            this.tags.forEach(function (tag, index) {
                if (tag === _this.currentTag) {
                    _this.tags.splice(index, 1);
                    _this.currentTag = null;
                }
            });
        };
        this.addBtnClick = function () {
            this.onSelected();
        };
        this.onClickRemoveBtn = function (index, tag) {
            this.selectedTags.splice(index, 1);
            this.tags.push(tag);
        };
    }
    angular
        .module('Yelon')
        .component('tagsEditorInput', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            tileColors: "<",
            onUpdate: "<",
            fileUpload: "<",
            uploadedFile: "<",
            deleteFile: "<",
            existTilecolor: "@",
            existImages: "<",
            disabledAllinput: "<",
            tileTypeRadioBoxes: "<"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/tileUpload.html',
        transclude: true
    };
    function controllerCtrl() {
        var fileId, fullUrl, Url;
        this.tileColorRadioChecked = null;
        this.tileTypeRadioBoxes = [{
                value: 0,
                text: "Látszik",
                class: "little",
                canvasId: "little-canvas",
                bounds: {
                    top: 0,
                    right: 0,
                    bottom: 200,
                    left: 200
                },
                cropWidth: 200,
                cropHeight: 200,
                croppedImage: null
            }, {
                value: 1,
                text: "Látszik",
                class: "little",
                canvasId: "little-canvas-1",
                bounds: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
                cropWidth: 400,
                cropHeight: 200,
                croppedImage: null
            }, {
                value: 2,
                text: "Látszik",
                class: "half-big",
                canvasId: "half-big-canvas",
                bounds: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
                cropWidth: 200,
                cropHeight: 400,
                croppedImage: null
            }];
        this.$onInit = function () {
            this.tileRadioBoxes = [{
                    value: 0,
                    text: "Nincs csempekép"
                }, {
                    value: 1,
                    text: "Csempekép"
                }];
            if (!fileId) {
                this.tileRadioChecked = 0;
                this.tileTypeRadioChecked = null;
                if (this.tileColorRadioChecked === null) {
                    this.tileColorRadioChecked = this.tileColors[0];
                }
                this.sourceImage = null;
            }
        };
        this.$postLink = function () {
            if (!fileId) {
                this.onChangeInput();
            }
        };
        this.getCheckedClass = function (checked, value) {
            return (checked !== null && value.toString() === checked.toString()) ? "checked" : "";
        };
        this.getCheckedClassByColors = function (index) {
            return (this.tileColors[index] === this.tileColorRadioChecked) ? "checked" : "";
        };
        this.onChangeInput = function () {
            var _this = this;
            var croppedImages = [];
            if (this.tileRadioChecked === 0) {
                this.tileTypeRadioChecked = false;
            }
            else {
                this.tileTypeRadioChecked = true;
                this.tileTypeRadioBoxes.forEach(function (radios, index) {
                    var _a = radios.bounds, top = _a.top, bottom = _a.bottom, left = _a.left, right = _a.right;
                    var pivot = {
                        top: top,
                        bottom: bottom,
                        left: left,
                        right: right,
                        disabled: (_this.tileTypeRadioChecked === index) ? true : false,
                        image_id: fileId,
                        article_id: null,
                        type: "tile" + (index + 1)
                    };
                    croppedImages[index] = {
                        url: Url,
                        full_url: fullUrl,
                        id: fileId,
                        pivot: pivot
                    };
                    if (index === 0) {
                        croppedImages[3] = {
                            url: Url,
                            full_url: fullUrl,
                            id: fileId,
                            pivot: {
                                top: top,
                                bottom: bottom,
                                left: left,
                                right: right,
                                disabled: (_this.tileTypeRadioChecked === index) ? true : false,
                                image_id: fileId,
                                article_id: null,
                                type: "tile4"
                            }
                        };
                    }
                });
            }
            this.onUpdate({
                tileTypeRadioCheckValue: this.tileTypeRadioChecked,
                tileColorRadioCheckValue: this.tileColorRadioChecked,
                croppedImages: croppedImages,
                fileId: fileId,
                callback: function () {
                    fileId = undefined;
                    delete _this.existMessage;
                    delete _this.sourceImage;
                    delete _this.file;
                }
            });
        };
        this.saveCroppedImages = function () {
            this.onChangeInput();
        };
        this.$onChanges = function (changes) {
            var _this = this;
            var uploadedFile = changes.uploadedFile;
            var existTilecolor = changes.existTilecolor;
            var existImages = changes.existImages;
            if (uploadedFile !== undefined && !uploadedFile.isFirstChange()) {
                var fileProp = uploadedFile.currentValue;
                if (fileProp.type === "tile") {
                    fileId = fileProp.data.id;
                    fullUrl = fileProp.data.full_url;
                    Url = fileProp.data.url;
                    this.existMessage = "Van feltöltve kép";
                    this.tileColorRadioChecked = null;
                }
            }
            if (existTilecolor !== undefined && existTilecolor.isFirstChange()) {
                this.tileColorRadioChecked = existTilecolor.currentValue;
            }
            if (existImages !== undefined && existImages.isFirstChange()) {
                var values = existImages.currentValue;
                if (values !== undefined && values.length > 0) {
                    values.forEach(function (value, index) {
                        if (value.pivot.type !== "cover") {
                            if (value.pivot.type !== 'tile4') {
                                _this.tileTypeRadioBoxes[index].bounds = {
                                    top: value.pivot.top,
                                    left: value.pivot.left,
                                    right: value.pivot.right,
                                    bottom: value.pivot.bottom
                                };
                            }
                            else if (!fileId) {
                                _this.setSourceImage(value);
                            }
                        }
                    });
                }
            }
        };
        this.setSourceImage = function (image) {
            var _this = this;
            fileId = image.id;
            fullUrl = image.full_url;
            Url = image.url;
            this.tileRadioChecked = 1;
            this.tileColorRadioChecked = null;
            this.existMessage = "Van feltöltve kép";
            this.file = {
                name: fullUrl.split('/').reverse()[0]
            };
            this.toDataUrl(image.full_url, function (img) {
                _this.sourceImage = img;
            }, 'image/jpeg');
        };
        this.onClickRemoveBtn = function (event) {
            var _this = this;
            event.preventDefault();
            this.deleteFile(fileId, function () {
                delete _this.sourceImage;
                delete _this.file;
                fileId = undefined;
                delete _this.existMessage;
                _this.tileRadioChecked = 0;
            });
        };
        this.toDataUrl = function (src, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            img.src = src;
            if (img.complete || img.complete === undefined) {
                img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = src;
            }
        };
    }
    angular
        .module('Yelon')
        .component('tileUpload', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            title: "=",
            subTitle: "=",
            requireTitle: "=",
            disabledAllinput: "<",
            isMotto: "@",
            updateShow: "<",
            currentShow: "@"
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/titles.html',
        transclude: true
    };
    function controllerCtrl() {
        this.$onChanges = function (changes) {
            var currentShow = changes.currentShow;
            if (currentShow && !currentShow.isFirstChange() && this.showValue === undefined) {
                this.showValue = currentShow.currentValue;
            }
        };
        this.onBlur = function (event) {
            var value = event.currentTarget.value;
            if (value !== "") {
                this.requireTitle = false;
            }
            else {
                this.requireTitle = true;
            }
            this.title = value;
        };
        this.showTitle = function () {
            var newValue;
            if (this.disabledAllinput) {
                return;
            }
            if (this.showValue) {
                newValue = false;
            }
            else {
                newValue = true;
            }
            this.showValue = newValue;
            this.updateShow(newValue);
        };
    }
    angular
        .module('Yelon')
        .component('titlesInput', component);
})(Yelon || (Yelon = {}));
var Yelon;
(function (Yelon) {
    'use strict';
    var component = {
        bindings: {
            typeText: '@',
            archiveValue: "=",
            duplicateArticle: "<",
            mainType: "@",
            notify: "=",
            articleType: "@",
            fixTile: "="
        },
        controller: controllerCtrl,
        templateUrl: 'app/Articles/EditorComponents/typeInput.html',
        transclude: true
    };
    function controllerCtrl() {
        this.isDuplicated = false;
        this.fixTiles = [{
                title: "Sima 'Rólunk' oldal",
                value: "0-rolunk"
            }, {
                title: "Sima 'Chat' oldal",
                value: "0-segitoink"
            }, {
                title: "Sima 'Mobilalkalmazás' oldal",
                value: "0-applikacio"
            }, {
                title: "Felnőtt 'Rólunk' oldal",
                value: "1-rolunk"
            }, {
                title: "Felnőtt 'Yelonról' oldal",
                value: "1-segitoink"
            }, {
                title: "Felnőtt 'Mobilalkalmazás' oldal",
                value: "1-applikacio"
            }];
        this.changeArchiveStatus = function () {
            this.archiveValue = (this.archiveValue) ? false : true;
        };
        this.onClickDuplicate = function () {
            var _this = this;
            this.duplicateArticle(function () {
                _this.isDuplicated = true;
            });
        };
    }
    angular
        .module('Yelon')
        .component('typeInput', component);
})(Yelon || (Yelon = {}));
(function () {
    'use strict';
    angular.module('Yelon').controller('PagesEditController', ['ApiFactory', 'SidebarService', '$timeout', 'spinnerService', 'Upload', 'apiPrefix', '$confirm', 'page', 'title', "$scope", PagesEditController]);
    function PagesEditController(ApiFactory, SidebarService, $timeout, spinnerService, Upload, apiPrefix, $confirm, page, title, $scope) {
        var vm = this;
        vm.picFile = false;
        vm.adultPicFile = false;
        vm.imageUrl = "";
        vm.adultImageUrl = "";
        vm.page = page;
        vm.title = title;
        vm.isUploadedPicFile = false;
        vm.isUploadedAdultFile = false;
        var timeouts = {};
        var _adultImageId;
        var _ImageId;
        SidebarService.loadPreset('pages');
        SidebarService.enable();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.p1 = data[page + '_p1'];
            vm.p2 = data[page + '_p2'];
            if (page !== "application") {
                vm.mobile = data[page + '_mobile'];
            }
            _ImageId = data[page + '_image'];
            _adultImageId = data['adult_' + page + '_image'];
            vm.imageId = data[page + '_image'];
            vm.adultImageId = data['adult_' + page + '_image'];
            vm.adult_p1 = data['adult_' + page + '_p1'];
            vm.adult_p2 = data['adult_' + page + '_p2'];
            if (vm.imageId !== null) {
                updateImageUrl(vm.imageId, false);
            }
            if (vm.adultImageId !== null) {
                updateImageUrl(vm.adultImageId, true);
            }
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        //file upload
        function updateImageUrl(image_id, isAdult) {
            if (image_id) {
                ApiFactory.getFunction('images/' + image_id).then(function (data) {
                    if (isAdult) {
                        vm.adultImageUrl = data.full_url;
                        vm.isUploadedAdultFile = true;
                    }
                    else {
                        vm.imageUrl = data.full_url;
                        vm.isUploadedPicFile = true;
                    }
                });
            }
        }
        // upload on file select or drop
        vm.upload = function (isAdult) {
            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': isAdult ? vm.adultPicFile : vm.picFile,
                    'directory': ""
                }
            }).then(function (resp) {
                ApiFactory.patchFunction('settings/' + (isAdult ? 'adult_' : '') + page + '_image', {
                    value: resp.data.id
                }).then(function () {
                    if (isAdult) {
                        vm.adultImageUrl = resp.data.full_url;
                        vm.adultImageId = resp.data.id;
                        vm.isUploadedAdultFile = true;
                        //delete old image
                        if (_adultImageId) {
                            deleteImage(_adultImageId, false, isAdult, function () {
                                _adultImageId = resp.data.id;
                            });
                        }
                    }
                    else {
                        vm.imageUrl = resp.data.full_url;
                        vm.imageId = resp.data.id;
                        vm.isUploadedPicFile = true;
                        //delete old image
                        if (_ImageId) {
                            deleteImage(_ImageId, false, isAdult, function () {
                                _ImageId = resp.data.id;
                            });
                        }
                    }
                });
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
        vm.deleteWithConfirm = function (image_id, clear, isAdult) {
            if (clear === void 0) { clear = false; }
            $confirm({
                title: 'Megerősítés',
                text: 'Biztosan törlöd?'
            })
                .then(function () {
                deleteImage(image_id, clear, isAdult, function () {
                    if (isAdult) {
                        vm.adultPicFile = false;
                        _adultImageId = undefined;
                    }
                    else {
                        vm.picFile = false;
                        _ImageId = undefined;
                    }
                });
            });
        };
        $scope.$watch("pagesEditCtrl.picFile", function (value) {
            if (value !== false) {
                vm.isUploadedPicFile = false;
            }
        });
        $scope.$watch("pagesEditCtrl.adultPicFile", function (value) {
            if (value !== false) {
                vm.isUploadedAdultFile = false;
            }
        });
        function deleteImage(image_id, clear, isAdult, callback) {
            if (clear === void 0) { clear = false; }
            if (callback === void 0) { callback = null; }
            if (image_id) {
                ApiFactory.deleteFunction('images/' + image_id).then(function () {
                    if (clear) {
                        if (isAdult) {
                            vm.adultImageUrl = "";
                            ApiFactory.patchFunction('settings/adult_' + page + '_image', {
                                value: null
                            }).then(function () {
                            });
                        }
                        else {
                            vm.imageUrl = "";
                            ApiFactory.patchFunction('settings/' + page + '_image', {
                                value: null
                            }).then(function () {
                            });
                        }
                    }
                    if (callback !== null) {
                        callback();
                    }
                });
            }
        }
        vm.inputChanged = function (name, isAdult) {
            if (isAdult === void 0) { isAdult = false; }
            var inName = isAdult ? 'adult_' + name : name;
            if (timeouts[inName]) {
                $timeout.cancel(timeouts[inName]);
            }
            timeouts[inName] = $timeout(function () {
                ApiFactory.patchFunction('settings/' + (isAdult ? 'adult_' : '') + page + '_' + name, {
                    value: vm[inName]
                }).then(function () {
                    console.log('Updated');
                });
            }, 2000);
        };
    }
})();
/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('FAQController', ['$filter', 'ApiFactory', 'SidebarService', 'spinnerService', '$confirm', FAQController]);
    function FAQController($filter, ApiFactory, SidebarService, spinnerService, $confirm) {
        var vm = this;
        vm.categories = [{
                id: 0,
                label: 'Válassz kategóriát'
            }];
        vm.categoryNames = { 0: 'összes' };
        vm.originalFaqItems = [];
        vm.faqItems = [];
        vm.selectedCategory = 0;
        vm.selectedCategoryName = 'összes';
        SidebarService.loadPreset('faq');
        SidebarService.enable();
        //load categories
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            angular.forEach(data, function (value, key) {
                var item = { id: value.id, label: value.title };
                vm.categories.push(item);
                vm.categoryNames[value.id] = value.title;
            });
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        //load faqitems
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqitems').then(function (data) {
            vm.originalFaqItems = data;
            vm.faqItems = vm.originalFaqItems;
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        //update category name and faqitems list
        vm.filterItems = function () {
            if (vm.selectedCategory === 0) {
                vm.faqItems = vm.originalFaqItems;
                return;
            }
            vm.faqItems = $filter('filter')(vm.originalFaqItems, { faq_category_id: vm.selectedCategory }, true);
        };
        //delete item
        vm.deleteItem = function (faqItem) {
            $confirm({ title: 'Megerősítés', text: 'Biztosan törli a "' + faqItem.question + '" elemet?' })
                .then(function () {
                var delIndex = vm.originalFaqItems.indexOf(faqItem);
                ApiFactory.deleteFunction('faqitems/' + faqItem.id).then(function (data) {
                    vm.originalFaqItems.splice(delIndex, 1);
                    vm.filterItems();
                });
            });
        };
        vm.movedItem = function (index) {
            var movedFaqItem = new FaqItem();
            angular.copy(vm.faqItems[index], movedFaqItem);
            vm.faqItems.splice(index, 1);
            var newPosition = -1;
            angular.forEach(vm.faqItems, function (val, key) {
                if (angular.equals(val, movedFaqItem)) {
                    newPosition = key;
                }
            });
            movedFaqItem.order = newPosition + 1;
            ApiFactory.patchFunction('faqitems/' + movedFaqItem.id, movedFaqItem).then(function (data) {
                console.log('updated');
            });
        };
    }
})();
/**
 * Created by danielszenasi on 09/08/16.
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('FAQCategoriesController', ['ApiFactory', 'SidebarService', 'spinnerService', FAQCategoriesController]);
    function FAQCategoriesController(ApiFactory, SidebarService, spinnerService) {
        var vm = this;
        vm.categories = [];
        SidebarService.loadPreset('faq');
        SidebarService.enable();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            angular.forEach(data, function (value, key) {
                var item = { id: value.id, text: value.title };
                //console.log(item);
                vm.categories.push(item);
            });
        }).finally(function () {
            spinnerService.decrementLoading();
        });
        vm.categoryAdded = function ($tag) {
            ApiFactory.postFunction('faqcategories', { 'title': $tag.text }).then(function (data) {
                $tag.id = data.id;
            });
        };
        vm.categoryRemoved = function ($tag) {
            ApiFactory.deleteFunction('faqcategories/' + $tag.id).then(function () { });
        };
    }
})();
/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';
    angular.module('Yelon').controller('FAQEditController', ['$scope', '$stateParams', '$timeout', 'ApiFactory', 'spinnerService', 'SidebarService', FAQEditController]);
    function FAQEditController($scope, $stateParams, $timeout, ApiFactory, spinnerService, SidebarService) {
        var vm = this;
        SidebarService.loadPreset('faq');
        SidebarService.enable();
        vm.timeout = false;
        vm.editing = false;
        vm.categories = [];
        vm.faqItem = new FaqItem();
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            // load categories
            angular.forEach(data, function (value, key) {
                var item = { id: value.id, label: value.title };
                vm.categories.push(item);
            });
            // load item
            if (typeof ($stateParams['id']) !== "undefined") {
                vm.editing = true;
                spinnerService.incrementLoading();
                ApiFactory.getFunction('faqitems/' + $stateParams['id'] + '/edit').then(function (data) {
                    vm.faqItem = data;
                }).finally(function () {
                    spinnerService.decrementLoading();
                    vm.watchInputs();
                });
            }
            // select first option by default
            if (!vm.editing && vm.faqItem.faq_category_id === 0) {
                vm.faqItem.faq_category_id = vm.categories[0].id;
            }
        }).finally(function () {
            spinnerService.decrementLoading();
            if (!vm.editing) {
                vm.watchInputs();
            }
        });
        // watch item for changes
        vm.watchInputs = function () {
            $scope.$watch('faqEditCtrl.faqItem', function (newVal, oldVal) {
                if (typeof oldVal !== 'undefined' && !angular.equals(oldVal, newVal)) {
                    if (vm.timeout) {
                        $timeout.cancel(vm.timeout);
                    }
                    vm.timeout = $timeout(function () {
                        if (vm.faqItem.id) {
                            //update
                            ApiFactory.patchFunction('faqitems/' + newVal.id, newVal).then(function () {
                                console.log('Updated');
                            });
                        }
                        else {
                            if (vm.faqItem.question !== "" || vm.faqItem.answer !== "") {
                                //store
                                ApiFactory.postFunction('faqitems', vm.faqItem).then(function (data) {
                                    vm.faqItem = data;
                                });
                            }
                        }
                    }, 2000);
                }
            }, true);
        };
    }
})();
