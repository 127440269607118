/**
 * Created by danielszenasi on 18/08/16.
 */
(function () {
    'use strict';

    angular.module('Yelon').factory('PasswordModalFactory', ['$uibModal', ModalFactory]);

    function ModalFactory($uibModal) {
        return {
            openModal: openModal,
        };

        function openModal(data) {
            var defaultLabels = {
                title: 'Confirm',
                ok: 'OK',
                cancel: 'Cancel'
            };
            data = angular.extend({}, defaultLabels, data || {});

            $uibModal.open({
                windowClass: 'password-forgotten',
                templateUrl: 'app/layouts/password-forgotten-modal.html',
                controller: 'passwordModalController',
                resolve: {
                    data: function () {
                        return data;
                    }
                }
            });
        }
    }

    angular.module('Yelon').controller('passwordModalController', ['$scope', '$uibModalInstance', 'data','$http','apiGeneralPrefix', passwordModalController]);

    function passwordModalController($scope:angular.IScope, $uibModalInstance, data, $http, apiGeneralPrefix) {
        $scope['data'] = angular.copy(data);

        $scope['ok'] = function (closeMessage) {
            alert('A jelszó megváltoztatásához szükséges adatokat elküldtük a megadott e-mail címre');
            $http.post(apiGeneralPrefix + "authenticate/reset-password", {'email' : $scope['email']}).then(function () {
                $uibModalInstance.close(closeMessage);
            }).catch();

            console.log($scope['email']);

        };

        $scope['cancel'] = function (dismissMessage) {
            if (angular.isUndefined(dismissMessage)) {
                dismissMessage = 'cancel';
            }
            $uibModalInstance.dismiss(dismissMessage);
        };
    }
})();
