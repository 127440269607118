(function () {
    'use strict';

    angular.module('Yelon').factory('ChatFactory', ['socketFactory', '$auth', '$q', 'chatPrefix', ChatFactory]);

    function ChatFactory(socketFactory, $auth, $q, chatPrefix) {
        let service: any = this;
        let handlers = {};
        let clients = {};
        let conversations = {};

        service.ioSocket = io(chatPrefix, { autoConnect: false });
        service.isLoggedIn = false;
        service.clientId = null;

        // Initializing public functions
        service.init = init;
        service.on = on;
        service.off = off;
        service.assignConversation = assignConversation;
        service.openConversationWith = openConversationWith;
        service.joinConversation = joinConversation;
        service.takeOverConversation = takeOverConversation;
        service.giveBackConversation = giveBackConversation;
        service.sendMessage = sendMessage;
        service.closeConversation = closeConversation;
        service.rateConversation = rateConversation;
        service.getClientId = getClientId;
        service.getClients = getClients;
        service.getConversations = getConversations;
        service.getSessions = getSessions;
        service.setIsTyping = setIsTyping;
        service.disconnect = disconnect;

        service.socket = socketFactory({
            prefix: '',
            ioSocket: service.ioSocket
        });

        let sessions = {};

        return service;

        function init() {
            service.socket.removeAllListeners();

            //Setting up event handlers
            service.socket.on('connect', () => {
                trigger("connect");
                service.socket.on('token:validated', (data: any) => {
                    service.isLoggedIn = true;
                    service.clientId = data.id;

                    // Ask for notification permission in advance
                    if ("Notification" in window && Notification.permission !== 'granted' && Notification.permission !== 'denied') {
                        Notification.requestPermission();
                    }
                });
                service.socket.on('chat:clients', (data) => {
                    clients = data;
                    trigger("clients", data);
                });
                service.socket.on('chat:conversations', (data) => {
                    conversations = data;
                    trigger("conversations", data);
                });
                service.socket.on("chat:start", chatStarted);
                service.socket.on("chat:announcement", (data) => {
                    console.log("Announcement received: ", data);
                    trigger("announcement", data.message);
                });

                //let each session handle its own events
                service.socket.on("chat:handover", (data) => {
                    propagateSessionEvent("handover", data);
                });
                service.socket.on("chat:end", (data) => {
                    propagateSessionEvent("end", data);
                });
                service.socket.on("chat:join", (data) => {
                    propagateSessionEvent("join", data);
                });
                service.socket.on("chat:leave", (data) => {
                    propagateSessionEvent("leave", data);
                });
                service.socket.on("chat:message", (data) => {
                    propagateSessionEvent("message", data);
                });
                service.socket.on("chat:typing", (data) => {
                    propagateSessionEvent("typing", data);
                });
                service.socket.on("chat:archive", (data) => {
                    propagateSessionEvent("archive", data);
                });
                service.socket.emit('token:validate', $auth.getToken());
            });
            service.socket.on('disconnect', (data) => {
                sessions = {};
                clients = {};
                conversations = {};
                service.isLoggedIn = false;
                service.clientId = null;
                service.socket.removeAllListeners('chat:clients');
                service.socket.removeAllListeners('chat:conversations');
                service.socket.removeAllListeners('chat:start');
                service.socket.removeAllListeners('chat:announcement');
                service.socket.removeAllListeners('chat:handover');
                service.socket.removeAllListeners('chat:end');
                service.socket.removeAllListeners('chat:join');
                service.socket.removeAllListeners('chat:leave');
                service.socket.removeAllListeners('chat:message');
                service.socket.removeAllListeners('chat:typing');
                service.socket.removeAllListeners('chat:archive');
                service.socket.removeAllListeners('token:validated');
                trigger('disconnect');
            });

            service.ioSocket.open();
        }

        //Service event handling
        function on(type: string, handler: Function) {
            if (handlers.hasOwnProperty(type)) {
                handlers[type].push(handler);
            }
            else {
                handlers[type] = [handler];
            }
        }

        function off(type: string, handler: Function) {
            if (handlers.hasOwnProperty(type)) {
                if (typeof handler === "undefined") {
                    handlers[type] = [];
                }
                handlers[type] = handlers[type].filter(h => h !== handler);
            }
        }

        function trigger(type, data?: any) {
            if (handlers.hasOwnProperty(type)) {
                handlers[type].forEach(h => h(data));
            }
        }

        function sendMessage(sessionId, message) {
            message = message.replace(/<div>/g, "");
            message = message.replace(/<\/div>/g, "\n");

            service.socket.emit("chat:message", sessionId, message);
        }

        function assignConversation() {
            service.socket.emit('chat:assign');
        }

        function openConversationWith(user) {
            service.socket.emit('chat:create', user.id);
        }

        function joinConversation(sessionId: string) {
            service.socket.emit("chat:join", sessionId);
        }

        function takeOverConversation(sessionId: string) {
            service.socket.emit("chat:takeover", sessionId);
        }

        function giveBackConversation(sessionId: string) {
            service.socket.emit("chat:giveback", sessionId);
        }

        function closeConversation(sessionId: string) {
            service.socket.emit("chat:close", sessionId);
        }

        function rateConversation(sessionId: string, ratingData: any) {
            service.socket.emit("chat:evaluate", sessionId, ratingData.starred, ratingData.topics, ratingData.comment);
        }

        function getClientId(): angular.IHttpPromise<string> {
            let deferred = $q.defer();
            if (this.clientId !== null) {
                deferred.resolve(this.clientId);
            } else {
                service.socket.on('token:validated', (data) => {
                    deferred.resolve(data.id);
                });
            }
            return deferred.promise;
        }

        function getClients() {
            return clients;
        }

        function getSessions() {
            console.log(sessions);
            return sessions;
        }

        function getConversations() {
            return conversations;
        }

        //Private functions, mainly event handlers
        function chatStarted(data) {
            sessions[data.session_id] = new ChatSession(data, service.clientId);

            trigger("start", sessions[data.session_id]);
        }

        function setIsTyping(sessionId, isTyping: boolean) {
            service.socket.emit("chat:typing", sessionId, isTyping);
        }

        function propagateSessionEvent(event: ChatSessionEventTypes, data: any) {
            if (sessions.hasOwnProperty(data.session_id)) {
                sessions[data.session_id].propagatedEventReceived(event, data);
            }
        }

        function disconnect() {
            service.socket.disconnect();
        }
    }
})();
