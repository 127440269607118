(function () {
    'use strict';

    angular.module('Yelon').controller('AutoMessagesController', ['ApiFactory', '$timeout', '$scope', 'SidebarService', 'spinnerService', 'apiPrefix', 'Upload','$confirm', AutoMessagesController]);

    function AutoMessagesController(ApiFactory, $timeout, $scope, SidebarService, spinnerService, apiPrefix, Upload, $confirm) {
        let vm: any = this,
            autoTimeout = null,
            welcomeTimeout = null,
            endTimeout = null;

        vm.addAutoMsg = () => vm.auto_messages.push({});

        vm.removeAutoMsg = idx => vm.auto_messages.splice(idx, 1);

        vm.timerOptions = [];
        for (let i = 0; i <= 600; i += 10) {
            vm.timerOptions.push({
                name: i + " másodperc",
                value: i * 1000
            });
        }
        vm.errors = {};
        vm.picFile = false;
        vm.isUploadedPicFile = false;

        let _picFileId;

        var deleteImage = function(id, callback = null) {
            ApiFactory.deleteFunction('images/' + id).then(function () {
                ApiFactory.patchFunction('settings/closed_image', {
                    value: null
                }).then(function () {
                    delete vm.picFileUrl;
                    delete vm.picFile;
                    vm.isUploadedPicFile = false;

                    if (callback !== null) {
                        callback();
                    }
                });
            });
        };

        vm.upload = function () {
            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': vm.picFile,
                    'directory': ""
                }
            }).then(function (resp) {
                ApiFactory.patchFunction('settings/closed_image', {
                    value: resp.data.id
                }).then(function () {
                    vm.picFileUrl = resp.data.full_url;
                    vm.picFileId = resp.data.id;
                    if (_picFileId) {
                        deleteImage(_picFileId);
                    }
                });
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

         vm.deleteWithConfirm = function () {
            $confirm({
                    title: 'Megerősítés',
                    text: 'Biztosan törlöd?'
                })
                .then(function () {
                    deleteImage(_picFileId, () => {
                        _picFileId = undefined;
                    });
                });
        };

        SidebarService.loadPreset('chat-settings');
        SidebarService.enable();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.auto_messages = data.auto_messages === null ? [{}, {}, {}] : data.auto_messages;
            //vm.auto_messages = [{},{},{}];
            vm.welcome_message = data.welcome_message;
            vm.end_message = data.end_message;
            vm.closed_image = data.closed_image;

            if (vm.closed_image !== null) {
                vm.picFileId = vm.closed_image;
                _picFileId = vm.closed_image;

                ApiFactory.getFunction('images/' + _picFileId).then(function (data) {
                    vm.picFileUrl = data.full_url;
                    vm.isUploadedPicFile = true;
                });
            }

            //vm.watchInputs();
        }).finally(function () {
            spinnerService.decrementLoading();
        });

        $scope.$watch('autoMessagesCtrl.auto_messages', (newVal, oldVal) => {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                if (autoTimeout !== null) {
                    $timeout.cancel(autoTimeout);
                }
                autoTimeout = $timeout(() => {
                    vm.errors = {};
                    ApiFactory.patchFunction('settings/auto_messages', {
                        value: newVal
                    }).catch(data => {
                        Object.keys(data.data.errors).forEach(key => {
                            let res = key.match(/value.([0-9]+).([a-z]+)/i);
                            if (typeof vm.errors[res[1]] === "undefined") {
                                vm.errors[res[1]] = [];
                            }
                            vm.errors[res[1]].push(res[2]);
                        });
                    });
                }, 3000);
            }
        }, true);

        $scope.$watch('autoMessagesCtrl.welcome_message', (newVal, oldVal) => {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                console.log(oldVal, newVal);
                if (welcomeTimeout !== null) {
                    $timeout.cancel(welcomeTimeout);
                }
                welcomeTimeout = $timeout(() => {
                    ApiFactory.patchFunction('settings/welcome_message', {
                        value: newVal
                    });
                }, 3000);
            }
        }, true);

        $scope.$watch('autoMessagesCtrl.end_message', (newVal, oldVal) => {
            if (typeof oldVal !== 'undefined' && !angular.equals(newVal, oldVal)) {
                console.log(oldVal, newVal);
                if (endTimeout !== null) {
                    $timeout.cancel(endTimeout);
                }
                endTimeout = $timeout(() => {
                    ApiFactory.patchFunction('settings/end_message', {
                        value: newVal
                    });
                }, 3000);
            }
        }, true);
    }
})();
