/**
 * Created by fures on 2016. 08. 10..
 */
(function () {
    'use strict';

    angular.module('Yelon').controller('FAQController', ['$filter', 'ApiFactory', 'SidebarService', 'spinnerService','$confirm', FAQController]);

    function FAQController($filter, ApiFactory, SidebarService, spinnerService,$confirm) {
        let vm:any = this;

        vm.categories = [{
            id: 0,
            label: 'Válassz kategóriát'
        }];
        vm.categoryNames = {0: 'összes'};
        vm.originalFaqItems = [];
        vm.faqItems = [];
        vm.selectedCategory = 0;
        vm.selectedCategoryName = 'összes';

        SidebarService.loadPreset('faq');
        SidebarService.enable();

        //load categories
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqcategories').then(function (data) {
            angular.forEach(data, function(value, key){
                let item = {id: value.id , label: value.title};
                vm.categories.push(item);
                vm.categoryNames[value.id] = value.title;
            });
        }).finally (function () {
                spinnerService.decrementLoading();
            }
        );

        //load faqitems
        spinnerService.incrementLoading();
        ApiFactory.getFunction('faqitems').then(function (data) {
            vm.originalFaqItems = data;
            vm.faqItems = vm.originalFaqItems;
        }).finally (function () {
                spinnerService.decrementLoading();
            }
        );

        //update category name and faqitems list
        vm.filterItems = function () {
            if (vm.selectedCategory === 0) {
                vm.faqItems = vm.originalFaqItems;
                return;
            }
            vm.faqItems = $filter('filter')(vm.originalFaqItems, {faq_category_id: vm.selectedCategory}, true);
        };

        //delete item
        vm.deleteItem = function (faqItem) {
            $confirm({title: 'Megerősítés', text: 'Biztosan törli a "' + faqItem.question + '" elemet?'})
                .then(function () {
                    var delIndex = vm.originalFaqItems.indexOf(faqItem);
                    ApiFactory.deleteFunction('faqitems/' + faqItem.id).then(function (data) {
                        vm.originalFaqItems.splice(delIndex, 1);
                        vm.filterItems();
                    });
                });


        };

        vm.movedItem = function (index) {
            var movedFaqItem = new FaqItem();
            angular.copy(vm.faqItems[index], movedFaqItem);
            vm.faqItems.splice(index, 1);
            var newPosition = -1;

            angular.forEach(vm.faqItems, function (val, key) {
                if (angular.equals(val, movedFaqItem)) {
                    newPosition = key;
                }
            });

            movedFaqItem.order = newPosition + 1;
            ApiFactory.patchFunction('faqitems/' + movedFaqItem.id, movedFaqItem).then(function (data) {
                console.log('updated');
            });
        };
    }
})();
