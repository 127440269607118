(function () {
    'use strict';

    angular.module('Yelon').controller('PagesEditController', ['ApiFactory', 'SidebarService', '$timeout', 'spinnerService', 'Upload', 'apiPrefix', '$confirm', 'page', 'title', "$scope", PagesEditController]);

    function PagesEditController(ApiFactory, SidebarService, $timeout, spinnerService, Upload, apiPrefix, $confirm, page, title, $scope) {
        let vm: any = this;

        vm.picFile = false;
        vm.adultPicFile = false;
        vm.imageUrl = "";
        vm.adultImageUrl = "";
        vm.page = page;
        vm.title = title;
        vm.isUploadedPicFile = false;
        vm.isUploadedAdultFile = false;

        let timeouts = {};
        let _adultImageId;
        let _ImageId;

        SidebarService.loadPreset('pages');
        SidebarService.enable();

        spinnerService.incrementLoading();
        ApiFactory.getFunction('settings').then(function (data) {
            vm.p1 = data[page + '_p1'];
            vm.p2 = data[page + '_p2'];
            if (page !== "application") {
                vm.mobile = data[page + '_mobile'];
            }
            _ImageId = data[page + '_image'];
            _adultImageId = data['adult_' + page + '_image'];
            vm.imageId = data[page + '_image'];
            vm.adultImageId = data['adult_' + page + '_image'];
            vm.adult_p1 = data['adult_' + page + '_p1'];
            vm.adult_p2 = data['adult_' + page + '_p2'];
            if (vm.imageId !== null) {
                updateImageUrl(vm.imageId, false);
            }
            if (vm.adultImageId !== null) {
                updateImageUrl(vm.adultImageId, true);
            }
        }).finally(function () {
            spinnerService.decrementLoading();
        });

        //file upload
        function updateImageUrl(image_id: number, isAdult: boolean) {
            if (image_id) {
                ApiFactory.getFunction('images/' + image_id).then(function (data) {
                    if (isAdult) {
                        vm.adultImageUrl = data.full_url;
                        vm.isUploadedAdultFile = true;
                    } else {
                        vm.imageUrl = data.full_url;
                        vm.isUploadedPicFile = true;
                    }
                });
            }
        }

        // upload on file select or drop
        vm.upload = function (isAdult: boolean) {
            Upload.upload({
                url: apiPrefix + 'images',
                data: {
                    'file': isAdult ? vm.adultPicFile : vm.picFile,
                    'directory': ""
                }
            }).then(function (resp) {
                ApiFactory.patchFunction('settings/' + (isAdult ? 'adult_' : '') + page + '_image', {
                    value: resp.data.id
                }).then(function () {
                    if (isAdult) {
                        vm.adultImageUrl = resp.data.full_url;
                        vm.adultImageId = resp.data.id;
                        vm.isUploadedAdultFile = true;
                        //delete old image
                        if (_adultImageId) {
                            deleteImage(_adultImageId, false, isAdult, () => {
                                _adultImageId = resp.data.id;
                            });
                        }

                    } else {
                        vm.imageUrl = resp.data.full_url;
                        vm.imageId = resp.data.id;
                        vm.isUploadedPicFile = true;
                        //delete old image
                        if (_ImageId) {
                            deleteImage(_ImageId, false, isAdult, () => {
                                _ImageId = resp.data.id;
                            });
                        }
                    }
                });
            }, function (resp) {
                alert('Sikertelen feltöltés, kérlek próbáld újra vagy tölts fel kisebb képet!');
                console.log('Error status: ' + resp.status);
            }, function (evt) {
                var progressPercentage = 100.0 * evt.loaded / evt.total;
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        vm.deleteWithConfirm = function (image_id, clear = false, isAdult: boolean) {
            $confirm({
                    title: 'Megerősítés',
                    text: 'Biztosan törlöd?'
                })
                .then(function () {
                    deleteImage(image_id, clear, isAdult, () => {
                        if (isAdult) {
                            vm.adultPicFile = false;
                            _adultImageId = undefined;
                        } else {
                            vm.picFile = false;
                            _ImageId = undefined;
                        }
                    });
                });
        };

        $scope.$watch("pagesEditCtrl.picFile", (value) => {
            if (value !== false) {
                vm.isUploadedPicFile = false;
            }
        });

        $scope.$watch("pagesEditCtrl.adultPicFile", (value) => {
            if (value !== false) {
                vm.isUploadedAdultFile = false;
            }
        });

        function deleteImage(image_id, clear = false, isAdult: boolean, callback = null) {
            if (image_id) {
                ApiFactory.deleteFunction('images/' + image_id).then(function () {
                    if (clear) {
                        if (isAdult) {
                            vm.adultImageUrl = "";
                            ApiFactory.patchFunction('settings/adult_' + page + '_image', {
                                value: null
                            }).then(function () {

                            });
                        } else {
                            vm.imageUrl = "";
                            ApiFactory.patchFunction('settings/' + page + '_image', {
                                value: null
                            }).then(function () {

                            });
                        }
                    }

                    if (callback !== null) {
                        callback();
                    }
                });
            }
        }

        vm.inputChanged = function (name: string, isAdult: boolean = false) {
            let inName = isAdult ? 'adult_' + name : name;
            if (timeouts[inName]) {
                $timeout.cancel(timeouts[inName]);
            }
            timeouts[inName] = $timeout(function () {
                ApiFactory.patchFunction('settings/' + (isAdult ? 'adult_' : '') + page + '_' + name, {
                    value: vm[inName]
                }).then(function () {
                    console.log('Updated');
                });
            }, 2000);
        };
    }
})();
